import React from "react";

export default function MissionIcon(props) {
  return (
    <svg
      width="72"
      height="74"
      viewBox="0 0 72 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.4089 10.3819C40.3819 -6.43749 25.8785 1.21174 23.3548 14.0257C20.8311 26.8396 -2.61233 29.3981 0.282226 44.5333C3.17678 59.6685 34.7062 50.6819 34.7062 64.3982C34.7062 78.1144 71.1606 77.6415 71.1606 59.6685C71.1606 41.6954 74.436 27.2012 57.4089 10.3819Z"
        fill="white"
        fill-opacity="0.2"
      />
      <mask
        id="mask0_1805_6753"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="72"
        height="74"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M57.4089 10.3819C40.3819 -6.43749 25.8785 1.21174 23.3548 14.0257C20.8311 26.8396 -2.61233 29.3981 0.282226 44.5333C3.17678 59.6685 34.7062 50.6819 34.7062 64.3982C34.7062 78.1144 71.1606 77.6415 71.1606 59.6685C71.1606 41.6954 74.436 27.2012 57.4089 10.3819Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1805_6753)">
        <g opacity="0.2">
          <rect
            x="0.0351562"
            y="0.703125"
            width="71.892"
            height="73.7839"
            fill="#00AFEF"
          />
        </g>
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.3398 33.0696L26.7965 34.8962C28.1062 40.1333 32.1943 44.2195 37.4295 45.5292L39.2561 45.9859L50.5705 34.6716C56.613 28.629 60.3467 20.6593 61.1194 12.147L61.2144 11.1113L60.1787 11.2063C51.6665 11.9808 43.6967 15.7127 37.6523 21.7571L26.3398 33.0696Z"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.8555 15.0625C47.3651 16.8892 48.5177 19.0811 50.8832 21.4448C53.1702 23.7336 55.2965 24.8863 57.0902 25.4215"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.8483 37.4805L21.7109 50.6178"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9932 25.4164L28.6374 24.7716C24.7411 24.3021 20.8449 25.6484 18.0684 28.4249L9.54883 36.9462C9.54883 36.9462 19.8822 34.3615 26.3395 33.0701L33.9932 25.4164Z"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.9108 38.334L47.5575 43.6897C48.0251 47.586 46.6789 51.4823 43.9042 54.2588L35.3828 62.7783C35.3828 62.7783 37.9675 52.4449 39.259 45.9877L46.9108 38.334Z"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.7162 31.7792C44.2914 33.2058 41.9771 33.2058 40.5504 31.7792C39.1238 30.3526 39.1238 28.04 40.5504 26.6116C41.9771 25.1868 44.2914 25.1868 45.7162 26.6116C47.1428 28.04 47.1428 30.3526 45.7162 31.7792Z"
        stroke="#333333"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.2426 50.0459C33.2426 50.0459 27.7627 62.8325 9.49609 62.8325C9.49609 44.5659 22.2827 39.0859 22.2827 39.0859"
        stroke="#00AFEF"
        stroke-width="2.83784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
