import SectionHeader from "components/sectionHeader";
import React /* useState */ from "react";

export default function ServiceProcessPage() {
  const dets = JSON.parse(localStorage.getItem("details"));
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="w-[70%] mx-auto">
          <SectionHeader
            title="Services"
            subHeading={dets?.title}
            description={
              <>
                <p> {dets?.desc}</p>
              </>
            }
          />
          {/*   <button className="main_btn2 mt-[4%] mx-auto">
            Request a demo &nbsp; &nbsp;
            <Arrow2 />{" "}
          </button> */}
        </div>
        <div className="w-[80%] mx-auto mt-[4%] mb-[8%]">
          <img src={dets?.image} alt="" className="w-full" />
        </div>
        <div className="w-[50%] mx-auto mt-[4%] mb-[8%]">
          <div className="pl-[3%] py-[2%] border-l-2 border-l-secondary font-medium text-[#5c5c5c]">
            <div
              className="px-[40px]"
              dangerouslySetInnerHTML={{ __html: dets?.content }}
            />
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
}

//https://lottie.host/ea5fcd35-d245-4a57-9c6a-2520055d0762/EUP2FlyO9y.json
//https://lottie.host/d16fd47a-aa19-46dc-aceb-b125cbf858d5/4Fw1ErGxBW.json
//https://lottie.host/5849740a-4b1c-49f6-9dd2-66089f6b7b40/Wbb4ZTTsqz.json
//https://lottie.host/105214a7-fd4b-40d5-8375-c48441cdb786/bugkoIKm4x.lottie
