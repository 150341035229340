import React from "react";

export default function ReadArrowiIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83301 14.1673L14.1663 5.83398M14.1663 5.83398H5.83301M14.1663 5.83398V14.1673"
        stroke="#F58220"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
