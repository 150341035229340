/** @format */

import React from "react";

import SectionHeader from "components/sectionHeader";
import Main from "./components/Main";
import { useSearchParams } from "react-router-dom";
import SideTab from "components/SideTab";

export default function ServicesPage() {
  const tab = [
    { title: "Payment Processing", tag: "process" },
    { title: "Merchant Services", tag: "merchant" },
    { title: "Switching Services", tag: "switch" },
    { title: "UP Agency Network", tag: "agency" },
    { title: "Value Added Services", tag: "vas" },
    { title: "Card  Services", tag: "card" },
    { title: "Interbank Transfer Service", tag: "interbank transfer" },
    { title: "Payment Terminal Service Aggregator (PTSA)", tag: "PTSA" },
  ];

  const [param] = useSearchParams();
  const activeTab = param.get("activeTab");

  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="max-w-[66%] w-[90%] mx-auto">
          <SectionHeader
            title="Our Services"
            subHeading="Our cutting-edge financial solutions drive payment innovation to new frontiers"
            description={
              <>
                <p>
                  At Unified Payment Services Limited, we offer our clients
                  cost-efficient cutting-edge solutions that positively impact
                  on their bottom lines, empowering them to take their services
                  beyond boundaries.
                </p>
              </>
            }
          />
        </div>
        <div className="bg-bgPrimary w-full pt-[8%] my-[8%]">
          <div className="max-w-[66%] w-[90%] mx-auto md:grid grid-cols-5 ">
            <div className="col-span-1  md:block hidden">
              <SideTab tab={tab?.map((item) => item?.title)} />
            </div>
            <div className="col-span-4">
              {activeTab === "Payment Processing" && (
                <div>
                  <Main />
                </div>
              )}
              {activeTab === "Merchant Services" && (
                <div>
                  <Main from={1} to={2} />
                </div>
              )}
              {activeTab === "Switching Services" && (
                <div>
                  <Main from={2} to={3} />
                </div>
              )}
              {activeTab === "UP Agency Network" && (
                <div>
                  <Main from={3} to={4} />
                </div>
              )}
              {activeTab === "Value Added Services" && (
                <div>
                  <Main from={4} to={5} />
                </div>
              )}
              {activeTab === "Card  Services" && (
                <div>
                  <Main from={5} to={6} />
                </div>
              )}
              {activeTab === "Interbank Transfer Service" && (
                <div>
                  <Main from={6} to={7} />
                </div>
              )}
                            {activeTab === "Payment Terminal Service Aggregator (PTSA)" && (
                <div>
                  <Main from={7} to={8} />
                </div>
              )}

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
