import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SideTab({ tab }) {
  const router = useNavigate();

  const [param] = useSearchParams();

  const activeTab = param.get("activeTab");

  return (
    <div class="mb-[1.2vw] border-l flex flex-col justify-between sticky top-10">
      {tab.map((item) => {
        return (
          <div
            onClick={() => {
              router(window.location.pathname + `?activeTab=${item}`);
            }}
            class={`px-[6.5%] py-[6.5%] border-l-4 text-[20px] flex items-center ${
              activeTab === item
                ? "border-primary text-black"
                : "border-transparent text-[#667085]"
            } w-fit text-[16px] cursor-pointer`}
          >
            <span className="w-[100%]">{item}</span>
          </div>
        );
      })}
    </div>
  );
}
