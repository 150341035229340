import React, { useState } from "react";

export default function TextAreaInput({
  value,
  name,
  type,
  onChange,
  placeholder,
  defaultValue,
  label = "Label",
}) {
  const [readOnly, setReadOnly] = useState(true);

  return (
    <div>
      <label htmlFor={name} className="mb-3 block">
        {label}
      </label>
      <div className="w-full outline-none border-[2px] border-[#66708521] rounded-[8px] bg-white">
        <div>
          <textarea
            readOnly={readOnly}
            onFocus={() => setReadOnly(false)}
            onBlur={() => setReadOnly(true)}
            autocomplete="off"
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            required
            defaultValue={defaultValue}
            placeholder={placeholder}
            className="w-full h-[26vh] appearance-none rounded-[6px] outline-none border-none bg-none p-5 text-[16px] placeholder:text-[#667085]"
          ></textarea>
        </div>
      </div>
    </div>
  );
}
