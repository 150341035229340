import React from "react";

export default function PartnersIcon(props) {
  return (
    <svg
      width="87"
      height="93"
      viewBox="0 0 87 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.560547"
        y="6.16797"
        width="86.126"
        height="86.126"
        rx="25"
        fill="#F58634"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.5337 29.0071C38.9351 29.0071 37.5751 30.1179 37.1934 31.6152H50.0296C49.6479 30.1179 48.2879 29.0071 46.6893 29.0071H40.5337ZM53.6555 31.6152H58.3796C63.3901 31.6152 67.47 35.7447 67.47 40.816C67.47 40.816 67.3268 42.9629 67.2791 45.9525C67.2744 46.1892 67.1598 46.421 66.9713 46.5611C65.8237 47.4087 64.7739 48.109 64.6785 48.1573C60.7178 50.8137 56.1154 52.6828 51.2123 53.6126C50.8926 53.6754 50.5777 53.5087 50.4154 53.2238C49.0411 50.8427 46.4739 49.2923 43.6108 49.2923C40.7667 49.2923 38.1756 50.8258 36.7608 53.2093C36.5962 53.4894 36.286 53.6512 35.9687 53.5909C31.1061 52.6587 26.5037 50.792 22.5669 48.1815L20.2764 46.5876C20.0856 46.4669 19.9663 46.2495 19.9663 46.0081C19.8947 44.7765 19.7754 40.816 19.7754 40.816C19.7754 35.7447 23.8553 31.6152 28.8658 31.6152H33.566C34.0194 28.1136 36.954 25.3848 40.5329 25.3848H46.6886C50.2675 25.3848 53.2022 28.1136 53.6555 31.6152ZM66.6585 51.1761L66.563 51.2244C61.7435 54.4604 55.9457 56.6097 49.8616 57.5032C49.0026 57.6239 48.1437 57.0685 47.9051 56.1991C47.3802 54.2189 45.6862 52.9148 43.6582 52.9148H43.6343H43.5866C41.5586 52.9148 39.8646 54.2189 39.3396 56.1991C39.1011 57.0685 38.2421 57.6239 37.3832 57.5032C31.2991 56.6097 25.5013 54.4604 20.6817 51.2244C20.6579 51.2003 20.4193 51.0554 20.2284 51.1761C20.0137 51.2969 20.0137 51.5867 20.0137 51.5867L20.1807 63.9027C20.1807 68.974 24.2368 73.0793 29.2472 73.0793H57.9737C62.9841 73.0793 67.0402 68.974 67.0402 63.9027L67.2311 51.5867C67.2311 51.5867 67.2311 51.2969 67.0164 51.1761C66.8971 51.1037 66.7539 51.1278 66.6585 51.1761ZM45.3992 61.2946C45.3992 62.3088 44.6118 63.1058 43.6098 63.1058C42.6315 63.1058 41.8203 62.3088 41.8203 61.2946V58.1793C41.8203 57.1892 42.6315 56.3682 43.6098 56.3682C44.6118 56.3682 45.3992 57.1892 45.3992 58.1793V61.2946Z"
        fill="#F58634"
      />
    </svg>
  );
}
