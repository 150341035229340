import React from "react";

export default function LockIcon(props) {
  return (
    <>
      <svg
        width="15"
        height="19"
        viewBox="0 0 15 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clip-path="url(#clip0_1957_21444)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.02164 6.49779H0.400391V18.4856H14.3804V6.49779H3.02164ZM1.89825 7.99626H12.8825V16.9871H1.89825V7.99626ZM7.3834 0.503913C9.57027 0.499917 11.3777 2.30707 11.3847 4.53979V6.49779H3.3961V4.55278C3.39411 2.32006 5.19653 0.511905 7.3834 0.503913ZM7.3864 2.00238C7.05206 2.00198 6.72095 2.06776 6.41214 2.19594C6.10333 2.32411 5.82292 2.51215 5.58707 2.74921C5.35121 2.98628 5.16458 3.26768 5.03791 3.57722C4.91124 3.88676 4.84705 4.21831 4.84903 4.55278V6.49779H9.93176V4.54279C9.93242 4.20849 9.86699 3.87736 9.73923 3.56845C9.61147 3.25954 9.42391 2.97896 9.18735 2.74285C8.95078 2.50674 8.66987 2.31977 8.36079 2.19269C8.05171 2.06561 7.72056 2.00093 7.3864 2.00238Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1957_21444">
            <rect
              width="13.98"
              height="17.99"
              fill="white"
              transform="translate(0.400391 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
