import React from "react";
import styled from "styled-components";

export default function GeneralModal({
  children,
  modalState,
  maxWidth,
  closeModal,
  heading,
}) {
  return (
    <Modal>
      <div className={`body ${modalState ? "body_in" : "body_out"}`}>
        <div
          className={`child ${modalState ? "child_in" : "child_out"}`}
          style={{
            maxWidth: maxWidth ? maxWidth : "450px",
            width: "90%",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {heading ? (
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{heading}</h3>

              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  closeModal();
                }}
                style={{
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                <path
                  d="M15.9974 2.6665C8.65073 2.6665 2.66406 8.65317 2.66406 15.9998C2.66406 23.3465 8.65073 29.3332 15.9974 29.3332C23.3441 29.3332 29.3307 23.3465 29.3307 15.9998C29.3307 8.65317 23.3441 2.6665 15.9974 2.6665ZM20.4774 19.0665C20.8641 19.4532 20.8641 20.0932 20.4774 20.4798C20.2774 20.6798 20.0241 20.7732 19.7707 20.7732C19.5174 20.7732 19.2641 20.6798 19.0641 20.4798L15.9974 17.4132L12.9307 20.4798C12.7307 20.6798 12.4774 20.7732 12.2241 20.7732C11.9707 20.7732 11.7174 20.6798 11.5174 20.4798C11.1307 20.0932 11.1307 19.4532 11.5174 19.0665L14.5841 15.9998L11.5174 12.9332C11.1307 12.5465 11.1307 11.9065 11.5174 11.5198C11.9041 11.1332 12.5441 11.1332 12.9307 11.5198L15.9974 14.5865L19.0641 11.5198C19.4507 11.1332 20.0907 11.1332 20.4774 11.5198C20.8641 11.9065 20.8641 12.5465 20.4774 12.9332L17.4107 15.9998L20.4774 19.0665Z"
                  fill="#CBCBCB"
                />
              </svg>
            </div>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                closeModal();
              }}
              style={{
                width: "40px",
                cursor: "pointer",
                position: "absolute",
                top: "24px",
                right: "20px",
              }}
            >
              <path
                d="M15.9974 2.6665C8.65073 2.6665 2.66406 8.65317 2.66406 15.9998C2.66406 23.3465 8.65073 29.3332 15.9974 29.3332C23.3441 29.3332 29.3307 23.3465 29.3307 15.9998C29.3307 8.65317 23.3441 2.6665 15.9974 2.6665ZM20.4774 19.0665C20.8641 19.4532 20.8641 20.0932 20.4774 20.4798C20.2774 20.6798 20.0241 20.7732 19.7707 20.7732C19.5174 20.7732 19.2641 20.6798 19.0641 20.4798L15.9974 17.4132L12.9307 20.4798C12.7307 20.6798 12.4774 20.7732 12.2241 20.7732C11.9707 20.7732 11.7174 20.6798 11.5174 20.4798C11.1307 20.0932 11.1307 19.4532 11.5174 19.0665L14.5841 15.9998L11.5174 12.9332C11.1307 12.5465 11.1307 11.9065 11.5174 11.5198C11.9041 11.1332 12.5441 11.1332 12.9307 11.5198L15.9974 14.5865L19.0641 11.5198C19.4507 11.1332 20.0907 11.1332 20.4774 11.5198C20.8641 11.9065 20.8641 12.5465 20.4774 12.9332L17.4107 15.9998L20.4774 19.0665Z"
                fill="#CBCBCB"
              />
            </svg>
          )}

          {children}
        </div>
      </div>
    </Modal>
  );
}

const Modal = styled.div`
  transition: all 0.2s;

  .body {
    background-color: #0000005d;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    display: grid;
    place-items: center;
    transition: all 0.2s;
    padding: 40px 0px !important;
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
    .child {
      border-radius: 30px;
      padding: 60px;
      position: relative;
      background-color: white;
      transition: all 0.2s;
    }
    .child_in {
      transform: translateY(0);
    }
    .child_out {
      transform: translateY(200px);
    }
  }
  .body_in {
    pointer-events: all;
    opacity: 1;
  }
  .body_out {
    pointer-events: none;
    opacity: 0;
  }
`;
