import React from "react";

export default function CultureIcon(props) {
  return (
    <svg
      width="91"
      height="92"
      viewBox="0 0 91 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.435547"
        y="0.808594"
        width="90.3127"
        height="90.3127"
        rx="26.2153"
        fill="#00AFEF"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.8415 30.5456C54.6626 30.5568 55.4857 30.5681 56.3117 30.5766C65.1861 30.5766 71.6565 36.9034 71.6565 45.6406V56.8632C71.6565 65.6003 65.1861 71.9272 56.3117 71.9272C52.7568 72.0025 49.2019 72.0276 45.6214 72.0276C42.041 72.0276 38.4349 72.0025 34.88 71.9272C26.0056 71.9272 19.5352 65.6003 19.5352 56.8632V45.6406C19.5352 36.9034 26.0056 30.5766 34.9056 30.5766C38.2559 30.5264 41.6829 30.4761 45.1611 30.4761V30.0493C45.1611 28.317 43.7033 26.911 41.9642 26.911H39.4323C36.5424 26.911 34.1895 24.6012 34.1895 21.7892C34.1895 20.7599 35.059 19.9062 36.1076 19.9062C37.1817 19.9062 38.0257 20.7599 38.0257 21.7892C38.0257 22.5424 38.6651 23.145 39.4323 23.145H41.9642C45.826 23.1701 48.9717 26.2582 48.9973 30.0242V30.5013C50.6095 30.5013 52.2218 30.5234 53.8415 30.5456ZM42.6044 53.1203H39.919V55.7816C39.919 56.811 39.0495 57.6646 38.0009 57.6646C36.9268 57.6646 36.0828 56.811 36.0828 55.7816V53.1203H33.3719C32.3233 53.1203 31.4538 52.2918 31.4538 51.2373C31.4538 50.208 32.3233 49.3543 33.3719 49.3543H36.0828V46.7181C36.0828 45.6888 36.9268 44.8351 38.0009 44.8351C39.0495 44.8351 39.919 45.6888 39.919 46.7181V49.3543H42.6044C43.6529 49.3543 44.5225 50.208 44.5225 51.2373C44.5225 52.2918 43.6529 53.1203 42.6044 53.1203ZM53.4708 48.8764H53.7266C54.7752 48.8764 55.6447 48.0479 55.6447 46.9934C55.6447 45.9641 54.7752 45.1104 53.7266 45.1104H53.4708C52.3967 45.1104 51.5527 45.9641 51.5527 46.9934C51.5527 48.0479 52.3967 48.8764 53.4708 48.8764ZM57.8436 57.5153H58.0994C59.1479 57.5153 60.0175 56.6868 60.0175 55.6323C60.0175 54.6029 59.1479 53.7493 58.0994 53.7493H57.8436C56.7669 53.7493 55.9255 54.6029 55.9255 55.6323C55.9255 56.6868 56.7669 57.5153 57.8436 57.5153Z"
        fill="#00AFEF"
      />
    </svg>
  );
}
