import React from "react";

export default function ReadArrowIcon2(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.33398 8.00065H12.6673M12.6673 8.00065L8.00065 3.33398M12.6673 8.00065L8.00065 12.6673"
        stroke="#F58220"
        stroke-width="1.3333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
