import React from "react";

export default function CodeIcon(props) {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.875" width="52" height="52" rx="13" fill="#00AFEF" />
      <path
        d="M18.7057 19.2656L10.8828 24.9392L18.7057 30.6127"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.418 19.2656L43.2408 24.9392L35.418 30.6127"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.018 15.4844L25.1055 34.3968"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
