import React from "react";

export default function HistoryIcon1(props) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#F58634" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 26.8361C16 20.8515 20.7998 16 26.7208 16C29.5641 16 32.2909 17.1417 34.3015 19.1738C36.312 21.206 37.4415 23.9622 37.4415 26.8361C37.4415 32.8207 32.6417 37.6722 26.7208 37.6722C20.7998 37.6722 16 32.8207 16 26.8361ZM37.2667 35.563L40.4596 38.1404H40.515C41.161 38.7933 41.161 39.8519 40.515 40.5048C39.8691 41.1577 38.8217 41.1577 38.1758 40.5048L35.5261 37.468C35.2756 37.2157 35.1348 36.8729 35.1348 36.5155C35.1348 36.158 35.2756 35.8153 35.5261 35.563C36.0092 35.0832 36.7836 35.0832 37.2667 35.563Z"
        fill="white"
      />
      <rect
        x="4"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke="#F58634"
        stroke-opacity="0.34"
        stroke-width="8"
      />
    </svg>
  );
}
