import React from "react";

export default function InsuranceIcon(props) {
  return (
    <svg
      width="91"
      height="91"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.556641"
        y="0.537109"
        width="90.3127"
        height="90.3127"
        rx="26.2153"
        fill="#00AFEF"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.9487 73.4834C45.2591 73.6456 45.6062 73.728 45.9533 73.7253C46.3004 73.7225 46.6447 73.6373 46.9579 73.4724L56.9589 68.1262C59.795 66.6144 62.0158 64.9239 63.7485 62.9559C67.5158 58.6679 69.573 53.1952 69.5364 47.5522L69.4178 28.938C69.4065 26.794 67.9984 24.8809 65.9158 24.1854L47.3106 17.9431C46.1903 17.5638 44.9628 17.5721 43.8622 17.9624L25.3276 24.4273C23.2563 25.1502 21.8735 27.0771 21.8848 29.2238L22.0034 47.8243C22.04 53.4756 24.1678 58.9235 27.9972 63.1675C29.7468 65.1081 31.9874 66.7738 34.8545 68.2609L44.9487 73.4834ZM42.3008 51.6056C42.7185 52.007 43.2603 52.2049 43.8021 52.1994C44.3439 52.1966 44.8829 51.9932 45.2949 51.5864L56.2244 40.8115C57.0456 40.0006 57.0371 38.6977 56.2074 37.8979C55.375 37.098 54.0345 37.1035 53.2133 37.9144L43.7711 47.2215L39.905 43.5052C39.0725 42.7054 37.7349 42.7136 36.9109 43.5245C36.0897 44.3353 36.0982 45.6382 36.9307 46.4381L42.3008 51.6056Z"
        fill="#00AFEF"
      />
    </svg>
  );
}
