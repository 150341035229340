import React from "react";

export default function Play2(props) {
  return (
    <svg
      width="145"
      height="145"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_1805_3992)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M72.5 145C112.541 145 145 112.541 145 72.5C145 32.4594 112.541 0 72.5 0C32.4594 0 0 32.4594 0 72.5C0 112.541 32.4594 145 72.5 145ZM61.1719 99.0932L101.953 76.299C104.974 74.6106 104.974 70.3894 101.953 68.701L61.1719 45.9068C58.151 44.2183 54.375 46.3289 54.375 49.7058L54.375 95.2942C54.375 98.6711 58.151 100.782 61.1719 99.0932Z"
          fill="white"
          fill-opacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1805_3992"
          x="-16"
          y="-16"
          width="177"
          height="177"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1805_3992"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1805_3992"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
