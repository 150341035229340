

import One from "assets/upImg/aboutUs/Overview/1.jpg";
import Two from "assets/upImg/aboutUs/Overview/2.jpg";
import Three from "assets/upImg/aboutUs/Overview/3.jpg";

import Hope from "assets/upImg/aboutUs/Subsidiary/Hope.jpg";
import Up from "assets/upImg/aboutUs/Subsidiary/cropped-new-upd-logo1.png";
//import Payarena from "assets/upImg/aboutUs/Subsidiary/payarena.jpeg";
//import PayAttitude from "assets/upImg/aboutUs/Subsidiary/tm30.png";
import PayAttitude2 from "assets/upImg/aboutUs/Subsidiary/payattitude.png";

export const overview = [
    {
        title: 'Every great story begins with collaboration',
        desc: 'UP is owned by a consortium of leading Nigerian banks. Incorporated as SmartCard Nigeria Plc, the Company was later known as ValuCard Nigeria Plc. Following the decommissioning of our domestic card scheme and the diversification and transformation into a scheme-neutral and financial and technology services group of companies, the name of the company became Unified Payment Services Limited otherwise known as UP® or Unified Payments®. The trademarks and brands of the UP Group include UP®, Payattitude, Payarena, Payarena Mall, Payarena Verification, Payarena VAS, Hope PSBank, UP Digital and TM30.',
        reverse: false,
        image: One
    },
    {
        title: 'We are on a mission to enhance commerce in Africa',
        desc: 'At UP®, we PRIDE ourselves in our core values of Professionalism, Respect, Innovation, Dependability, and Execution. In demonstration of our cross-enterprise alliances and capabilities, companies in the UP® group provide diverse services to different businesses that offer similar or the same services as our company and her subsidiaries or associates.',
        reverse: true,
       image: Two

    },
    {
        title: 'Pioneering Roles',
        content: `<p class="s3">UP&reg; prides itself in Leadership &amp; Innovations in Payments &amp; Financial Technology and has contributed significantly to the growth of electronic payments &amp; financial technology in Nigeria. These contributions and exceptional value-innovations include but not limited to:</p>
        <div class="s5"><span class="s4">&bull; </span>Pioneered the issuance and acceptance of EMV Chip+PIN cards in Nigeria leading to reduction of ATM fraud in Nigeria by over 95%.</div>
        <div class="s5"><span class="s4">&bull; </span>Enabled Nigerian banks to issue payment cards to Naira account holders to be used for the first time-ever globally.</div>
        <div class="s5"><span class="s4">&bull; </span>Enabled Nigerian banks for first-ever acceptance of foreign cards at their ATMs</div>
        <div class="s5"><span class="s4">&bull; </span>Enabled Nigerian Merchants the use of Nigerian Naira as transaction currency when cards issued under global card schemes are used at merchant locations in Nigeria. Hitherto, the only acceptable transaction currency was United States Dollars.</div>
        <div class="s5"><span class="s4">&bull; </span>First Nigerian Principal Member of a global payment scheme and the only Nigerian non-bank Principal Member.</div>
        <div class="s5"><span class="s4">&bull; </span>Joint Acquiring company for Nigerian banks.</div>
        <div class="s5"><span class="s4">&bull; </span>First EMV Third Party Processor in Nigeria.</div>
        <div class="s5"><span class="s4">&bull; </span>First Acquirer and Processor to deploy Near-Field-Communication (NFC) technology for remote/distant and proximity/contactless transactions in Nigeria.</div>
        <div class="s5"><span class="s4">&bull; </span>First Settlement Agent to enable Nigerian merchants for Hourly Settlement Service (UP-HSS).</div>
        <div class="s5"><span class="s4">&bull; </span>First service provider to enable Payment and Withdrawal on POS, WEB and ATM with just phone number.</div>
        <div class="s5"><span class="s4">&bull; </span>Pioneer in the use of telephone number as digital money account number.</div>
        <div class="s5"><span class="s4">&bull; </span>Pioneered multibank mobile application and USSD (Unstructured Supplementary Service Data)</div>
        <div class="s5"><span class="s4">&bull; </span>First entity to obtain a Payment Service Bank license</div>`,
      /*   desc: `UP® prides itself in Leadership & Innovations in Payments & Financial Technology and has contributed significantly to the growth of electronic payments & financial technology in Nigeria. These contributions and exceptional value-innovations include but not limited to:
        • Pioneered the issuance and acceptance of EMV Chip+PIN cards in Nigeria leading to reduction of ATM fraud in Nigeria by over 95%.
        • Enabled Nigerian banks to issue payment cards to Naira account holders to be used for the first time-ever globally.
        • Enabled Nigerian banks for first-ever acceptance of foreign cards at their ATMs
        • Enabled Nigerian Merchants the use of Nigerian Naira as transaction currency when cards issued under global card schemes are used at merchant locations in Nigeria. Hitherto, the only acceptable transaction currency was United States Dollars.
        • First Nigerian Principal Member of a global payment scheme and the only Nigerian non-bank Principal Member.
        • Joint Acquiring company for Nigerian banks.
        • First EMV Third Party Processor in Nigeria.
        • First Acquirer and Processor to deploy Near-Field-Communication (NFC) technology for remote/distant and proximity/contactless transactions in Nigeria.
        • First Settlement Agent to enable Nigerian merchants for Hourly Settlement Service (UP-HSS).
        • First service provider to enable Payment and Withdrawal on POS, WEB and ATM with just phone number.
        • Pioneer in the use of telephone number as digital money account number.
        • Pioneered multibank mobile application and USSD (Unstructured Supplementary Service Data)
        • First entity to obtain a Payment Service Bank license`, */
        reverse: false,
        image: Three
    },
   
    
]
export const subsidiaryList = [
    {
        title: 'Payattitude',
        desc: `Payattitude is a Payment Scheme with innovative technologies for payments and financial transactions with focus on mobile and digital payments. Payattitude is owned by a consortium of leading Nigerian banks.
        Payattitude owns the patent for the innovative Technology and Process that enables phone number to be used for different transactions available across all channels ATM, POS and WEB. These transactions are approved or authorized from ONLY the personal devices of the holder/customer of the Payattitude bank/partner.`,
        reverse: false,
        link: 'https://www.payattitude.com/',
        image: PayAttitude2
    },
    {
        title: 'Hope PSBank',
        desc: `Hope Payment Service Bank Limited (Hope PSBank) is Nigeria’s premier Digital-first Bank. Incorporated in September 2019, Hope PSBank leverages digital technologies to deliver inclusive financial services.
        With Hope PSBank, every mobile telephone user can easily enjoy instant digital financial and payment services with his or her phone number as account number and also a means of payment across all channels – person-to-person on personal devices, web, merchants’ points of sale, automated teller machines and agency outlets.`,
        reverse: true,
        link: 'https://hopepsbank.com/',
       image: Hope

    },
   
    {
        title: 'UP Digital',
        desc: `UP Digital Ltd. stands at the forefront of the financial technology industry, leveraging over 25 years of experience, expertise, and robust risk management capabilities from Unified Payments Group.
        At UP Digital, we offer a wide selection of payment and communication API solutions, enabling businesses and corporate bodies to transact and create exceptional customer engagement experiences without the hassles of working directly with mobile operators.`,
        reverse: false,
        link: 'https://www.updigital-ng.com/',
       image: Up

    },
  
    
]
export const values = [
    {
        title: 'Professionalism',
        desc: 'We deliver high quality services that meet the needs of our customers in line with global best practices using the best skills.'
    },
    {
        title: 'Respect to the individual',
        desc: 'We recognize and treat our stakeholders as individuals on the basis of fairness, equity and care.'
    },
    {
        title: 'Innovative & Customer centric',
        desc: 'We recognize the customer as the main purpose of our existence; therefore we constantly strive to ensure we exceed their expectations at all times.'
    },
    {
        title: 'Dependability',
        desc: 'We act with integrity. We create an atmosphere of trust and can be relied upon to deliver on our promises.'
    },
    {
        title: 'Execution Through Teamwork',
        desc: 'We get things done working together.'
    },
]