import React from "react";

export default function RemoteIcon(props) {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.712891"
        y="0.800781"
        width="90.3127"
        height="90.3127"
        rx="26.2153"
        fill="#F58634"
        fill-opacity="0.2"
      />
      <path
        d="M24.7963 52.8562C25.4514 52.8562 26.0797 52.5903 26.5429 52.117C27.0062 51.6437 27.2664 51.0017 27.2664 50.3324V29.3443C27.2664 28.8978 27.44 28.4697 27.749 28.154C28.058 27.8383 28.477 27.6609 28.914 27.6609H62.6682C63.1047 27.6616 63.5231 27.8392 63.8316 28.1549C64.14 28.4705 64.3133 28.8983 64.3133 29.3443V50.3324C64.3133 51.0017 64.5735 51.6437 65.0367 52.117C65.5 52.5903 66.1283 52.8562 66.7834 52.8562C67.4385 52.8562 68.0668 52.5903 68.53 52.117C68.9933 51.6437 69.2535 51.0017 69.2535 50.3324V29.3443C69.2522 27.56 68.558 25.8491 67.3234 24.5871C66.0888 23.3252 64.4145 22.6153 62.6682 22.6133H28.914C27.1674 22.6153 25.4929 23.3251 24.2579 24.587C23.0228 25.8488 22.3281 27.5597 22.3262 29.3443V50.3324C22.3262 51.0017 22.5864 51.6437 23.0497 52.117C23.5129 52.5903 24.1412 52.8562 24.7963 52.8562Z"
        fill="#F58634"
      />
      <path
        d="M75.2512 57.1682C75.1356 57.0172 74.9879 56.8951 74.8193 56.8111C74.6507 56.7272 74.4655 56.6835 74.2779 56.6836H54.517C54.1894 56.6836 53.8753 56.8165 53.6437 57.0532C53.412 57.2899 53.2819 57.6108 53.2819 57.9455C53.2819 59.7374 50.2387 61.7312 45.8716 61.7312C41.5044 61.7312 38.4612 59.7374 38.4612 57.9455C38.4612 57.6108 38.3311 57.2899 38.0995 57.0532C37.8678 56.8165 37.5537 56.6836 37.2261 56.6836H17.4652C17.2776 56.6836 17.0925 56.7274 16.9239 56.8114C16.7553 56.8955 16.6076 57.0177 16.4922 57.1688C16.3767 57.3198 16.2964 57.4958 16.2574 57.6833C16.2184 57.8708 16.2218 58.0649 16.2672 58.2509C17.0359 61.4094 18.8178 64.2139 21.3285 66.217C23.8392 68.2201 26.9342 69.3064 30.1196 69.3027H61.6235C64.8089 69.306 67.9036 68.2195 70.4142 66.2164C72.9248 64.2134 74.7068 61.4092 75.4759 58.2509C75.5215 58.0648 75.5249 57.8706 75.4859 57.683C75.447 57.4954 75.3667 57.3193 75.2512 57.1682Z"
        fill="#F58634"
      />
    </svg>
  );
}
