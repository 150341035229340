import React, { useState } from "react";
import GeneralModal from "components/GeneralModal";

//import Adekunle from "assets/upImg/aboutUs/Leadership/Board/Adekunle-Sonola-scaled.jpg";
// import Agada from "assets/upImg/aboutUs/Leadership/Board/Agada Apochi.jpg";
import Agada from "assets/upImg/aboutUs/Leadership/Board/Agada Apochi.jpg";


import Adesola from "assets/upImg/aboutUs/Leadership/Board/Dr.-Adesola-Adeduntan.jpg";
import Nneka from "assets/upImg/aboutUs/Leadership/Board/How-Nneka-Onyeali-Ikpe-Rose-to-the-Top-of-Nigerias-Male-Dominated.jpg";
import Oliver from "assets/upImg/aboutUs/Leadership/Board/Oliver-Alawuba-Investors-King.jpg";
import Shehu from "assets/upImg/aboutUs/Leadership/Board/Shehu Abubakar.jpg";
import Victor from "assets/upImg/aboutUs/Leadership/Board/victor-bm (1).jpg";

import Ejembi from "assets/upImg/aboutUs/Leadership/Management/Emmanuel Ejembi.jpg";
// import Dan from "assets/upImg/aboutUs/Leadership/Management/Ochanya Dan-Ugo.jpg";
import Dan from "assets/upImg/aboutUs/Leadership/Management/Mrs. Ochanya Dan-Ugo.jpg";

// import Titi from "assets/upImg/aboutUs/Leadership/Management/Titilayo Olubiyi.jpg";
import Titi from "assets/upImg/aboutUs/Leadership/Management/Mrs. Titilayo Olubiyi.jpg";

// import Okolo from "assets/upImg/aboutUs/Leadership/Management/Vivian Okolo.jpg";
import Okolo from "assets/upImg/aboutUs/Leadership/Management/Mrs. Vivian Okolo.jpg";


export default function LeaderShip() {
  const board = [
    /*   {
      name: "Herbert Wigwe",
      role: "FCA -Chairman",
      desc: `<p class="s4"><span class="s5">Mr. Herbert Wigwe, the Chairman of Unified Payments is also the Group Managing Director of Access Bank Plc.</span></p>
      <br/>  <p class="s4"><span class="s5">Mr. Herbert Wigwe started his professional career with Coopers and Lybrand Associates, an international firm of Chartered Accountants. He spent over 10 years at Guaranty Trust Bank where he managed several portfolios including financial institutions, Corporates and Multinationals. He left Guaranty Trust as an Executive Director to co-lead the transformation of Access Bank Plc in March 2002 as Deputy Managing Director.</span></p>
      <br/>  <p class="s4"><span class="s5">Herbert is an Alumnus of Harvard Business School Executive Management Programme. He holds a Masters degree in Banking and International Finance from the University College of North Wales; a Masters degree in Financial Economics from the University of London and a B.Sc. degree in Accounting from the University of Nigeria, Nsukka. He is also a Fellow of the Institute of Chartered Accountants of Nigeria (ICAN).&nbsp;</span></p>`,
    }, */
    {
      name: "Victor Etuokwu",
      role: "Alternate Chairman",
      img: Victor,
      desc: `
    <p class="s4"><span class="s5">Victor Etuokwu is an Executive Director of Access Bank Plc and represents the bank on the board of Unified Payment as Alternate Chairman.</span></p>
    <br/>   <p class="s4"><span class="s5">He has about 20 years banking experience cutting across banking operations and information technology, internal control, general administration, retail banking, business acquisition and integration.</span></p>
    <br/>  <p class="s4"><span class="s5">He joined Access Bank in July 2003 from Citibank Nigeria and was promoted to General Manager in February 2008. He has served the Bank in different capacities, leading several successful and critically important projects across the Bank. The latest of such projects was the acquisition of controlling equity interest in Intercontinental Bank Plc and the subsequent merger of both banks.</span></p>
    <br/>  <p class="s4"><span class="s5">Mr. Etuokwu holds a Bachelor of Science Degree and Masters of Business Administration degree from the University of Ibadan and University of Benin respectively. He is a Senior Honorary member of the Chartered Institute of Bankers of Nigeria.</span></p>
    `,
    },
    {
      name: "Agada B. Apochi ",
      role: "Managing Director/CEO",
      img: Agada,
      desc: `<p class="s4"><span class="s5">Agada Apochi is an Organizational Psychologist &amp; Change Leadership Specialist and also a Practitioner-Scholar in diffusion of innovation and digital financial services. He has over 30 years of professional experience in banking and financial technology. Prior to his appointment as Managing Director &amp; Chief Executive Officer, he was an Executive Director. He also worked as inhouse solicitor in different banks before joining the services of UP.</span></p>
      <br/> <p class="s4"><span class="s5">Agada is the Founder/Director of Hope PSBank as well as the payment scheme and financial technology company, Payattitude Global Limited and the telecom VAS provider, UP Digital Limited. He is also the Chairman of the Board of Directors of TM30, a software company.</span></p>
    <br/> <p class="s4"><span class="s5">Agada attained Doctor of Business Administration (DBA) with specialization in diffusion of innovation and digital financial services as well as Master Degree in Applied Business Research from SBS Swiss Business School in Switzerland, Master Degree (MA) in Organizational Psychology with specialization in Change Leadership from Columbia University in New York, Master Degree in Law (LL.M.) from Rivers State University, Barrister at Law (BL) from the Nigerian Law School and Bachelor Degree in Law (LL.B.) from the University of Jos. He is also is an Alumnus of Lagos Business School and Cornell University. He has attended different executive learning programs including programs at Harvard Business School, Insead Business School and Bank Card Business School in Cambridge. Agada holds several academic honours and awards including best graduating student. He was called to the Nigerian Bar in 1992 and has since worked and gained experience in company law &amp; practice, banking and financial services, electronic transactions, retail business, administration, strategy, execution and change leadership.</span></p>
    <br/> <p class="s4"><span class="s5">Agada is currently the President of Lagos Business School Alumni Association and a Life Member of the Governing Council of the association. Agada is an Organizational Psychologist, Innovator and Entrepreneur. He is a Fellow, Chartered Institute of Bankers of Nigeria, Fellow, International Academy of Cards &amp; Payments, Fellow, Institute of Credit Administration, Certified International Cards &amp; Payments Professional (CICPP), Certified Associate of Capability Maturity Model Institute, Chartered Mediator &amp; Conciliator, Barrister &amp; Solicitor. He has to his credit, different professional and technical papers. Agada is married with children.</span></p>`,
    },
    {
      name: "Oliver Alawuba",
      role: "Director",
      img: Oliver,
      desc: `
    <p class="s4"><span class="s5">Oliver Alawuba is the Group Managing Director/CEO of United Bank for Africa (UBA), one of Africa�s leading financial services group, and representative of UBA on the Board of UP. He is a Nigerian banker and corporate business leader.</span></p>
    <br/> <p class="s4"><span class="s5">Oliver holds a B.Sc degree in Food Science and Technology from Abia State University, Uturu, M.Sc. in Food Technology from the University of Ibadan and MBA in Banking &amp; Finance from Olabisi Onabanjo University, Ago Iwoye, Ogun State. He is an alumnus of the Senior Executive Programme (SEP) of London Business School and the Advanced Management Programme (AMP) of INSEAD Business School in France.</span></p>
    <br/> <p class="s4"><span class="s5">Alawuba�s career in financial services spans over 25 years after his short foray into academia at Abia State University, Uturu. He started his banking career with Diamond Bank Plc (Now Access Bank Plc) where he worked from 1993 to 1997, before joining Standard Trust Bank (Now United Bank for Africa Plc) as a pioneer staff in 1997, rising through the ranks in the UBA Group.</span></p>
    <br/> <p class="s4"><span class="s5">Alawuba has served as Country CEO for UBA Ghana, Regional CEO, UBA Anglophone Subsidiaries and the Executive Director, East Bank, Nigeria. In 2021, Alawuba was appointed as Group Deputy Managing Director/CEO, UBA Africa covering Subsidiaries in 19 African Countries. As DMD/CEO, UBA Africa, UBA won the Banker�s award for African Bank of the Year 2021 in 13 countries, including Nigeria.</span></p>
    <br/> <p class="s4"><span class="s5">In August 2022, Oliver Alawuba was appointed the Group Managing Director/CEO of the United Bank for Africa (UBA) Plc, following the retirement of Mr. Kennedy Uzoka. Alawuba is an Honorary Senior Member of the Chartered Institute of Bankers of Nigeria, a Fellow of the Nigeria Institute of Management (FNIM) and a Member of the Nigeria Institute of Directors (M.IoD) Oliver Alawuba is married to Dr. Nkeiruka Oly-Alawuba.</span></p>
    `,
    },
    {
      name: "Nneka Onyeali-Ikpe",
      role: "Director",
      img: Nneka,
      desc: `<p class="s4"><span class="s5">Mrs. Nneka Onyeali-Ikpe is the Managing Director/CEO of Fidelity Bank Plc and represents the Bank on the Board of Directors of UP. She assumed office as Managing Director/CEO of </span><span class="s5">Fidelity Bank on January 1, 2021, and has been an integral part of the transformation team at the Bank in the last six years. She was formerly Executive Director, Lagos and South West, overseeing the Bank&rsquo;s business in the seven States that make up the South West Directorate of the Bank. She led the transformation of the Directorate to profitability and sustained its impressive year-on-year growth, across key performance metrics, including contributing over 28% of the Bank&rsquo;s PBT, Deposits and Loans prior to her appointment as MD/CEO.</span></p>
      <br/> <p class="s4"><span class="s5">Nneka has over 30 years of experience across various banks including Standard Chartered Bank Plc, Zenith Bank Plc and Citizens International Bank Limited, where she held management and leadership positions in Legal, Treasury, Investment Banking, Retail/Commercial Banking and Corporate Banking, in addition to serving as an Executive Director on the Board of Enterprise Bank Plc.</span></p>
      <br/> <p class="s4"><span class="s5">Nneka has been involved in the structuring of complex transactions in various sectors including Oil &amp; Gas; Manufacturing, Aviation, Real Estate and Export. As an Executive Director at Enterprise Bank Plc, she received formal commendation from the Asset Management Corporation of Nigeria (AMCON) as a member of the management team that successfully turned around Enterprise Bank Plc.</span></p>
    <br/>  <p class="s4"><span class="s5">Nneka holds Bachelor of Laws (LLB) and Master of Laws (LLM) degrees from the University of Nigeria, Nsukka and Kings College, London, respectively. She has attended executive training programs at Harvard Business School, The Wharton School University of Pennsylvania, INSEAD School of Business, Chicago Booth School of Business, London Business School and IMD amongst others. She is currently undergoing a Diploma programme in Organizational Leadership at Said Business School, Oxford University, UK. She is an Honorary Senior Member (HCIB) of The Chartered Institute of Bankers of Nigeria.</span></p>`,
    },
    {
      name: "Dr. Adesola Kazeem Adeduntan",
      role: "FCA –Director",
      img: Adesola,
      desc: `<p class="s4"><span class="s5">Dr. Adesola (&ldquo;Sola&rdquo;) Kazeem Adeduntan (FCA) is the Managing Director/Chief Executive Officer of First Bank of Nigeria Limited. He represents the bank on the Board of UP.</span></p>
      <br/> <p class="s4"><span class="s5">Before working with First Bank, Sola was a Director and the pioneer Chief Financial Officer/Business Manager of Africa Finance Corporation. He has served as a Senior Vice-President&nbsp;&nbsp; Chief Financial Officer at Citibank Nigeria Limited, a Senior Manager in the Financial Services Group of KPMG Professional Services and a Manager at Arthur Andersen </span><span class="s5">Nigeria. Sola also had a brief stint at the defunct Afribank Nigeria Plc (now Mainstreet Bank) as a graduate trainee where he worked mainly in banking operations.</span></p>
      <br/> <p class="s4"><span class="s5">Over the course of his sterling career, he has garnered diverse expertise in Treasury and Financial Management, Risk Management, Accounting and Internal Controls, Corporate Governance, Corporate Strategy Development and Implementation, Corporate Finance, Business Performance Management, Financial Advisory, Investors Regulators and Rating Agencies Relationship Management, Deployment and Management of Information Technology and Compliance.</span></p>
      <br/> <p class="s4"><span class="s5">Sola attended the University of Ibadan, where he obtained a Doctor of Veterinary Medicine (DVM) degree. He also holds a Master&rsquo;s Degree in Business Administration (MBA) from Cranfield University Business School, United Kingdom which he attended as a distinguished &ldquo;British Chevening Scholar.&rdquo; In addition to his MBA, he has attended executive/leadership programmes at Harvard, Cambridge, Oxford and INSEAD.</span></p>
      <br/> <p class="s4"><span class="s5">Sola is a member of Sigma Educational Foundation, which is focused on enhancing the quality of tertiary education system in Nigeria and a member of the prestigious Metropolitan Club. He is happily married with children.</span></p>`,
    },
    /*    {
      name: "Adekunle Sonola",
      role: "Director",
      desc: `<p class="s4"><span class="s5">Mr. Adekunle Sonola is the Managing Director/CEO of Polaris Bank Ltd and representative of the Bank on the Board of Directors of Unified Payments.</span></p>
      <br/><p class="s4"><span class="s5">He has more than 33 Years of experience in the African Financial Services Sector, most recently as Executive Director Commercial Banking at Union Bank Plc. Prior to which he was the Pioneer Regional Managing Director of Guaranty Trust Bank, East Africa and the Director of Investment Banking at Standard Bank in South Africa.</span></p>
    <br/>  <p class="s4"><span class="s5">Prior to his appointment on the Board of Skye Bank, Innocent was an Executive Director in Keystone Bank Limited, where he recorded outstanding contributions and achievements resulting in the successful turn-around, repositioning and eventual divestment of the bank by the Assets Management Corporation of Nigeria (AMCON).</span></p>
    <br/>   <p class="s4"><span class="s5">Adekunle has also served on the Boards of First Bank of Nigeria Plc., where he chaired the Board�s Risk Management Committee, and Airtel Nigeria Plc.</span></p>
    <br/> <p class="s4"><span class="s5">Innocent is blessed with a lovely wife and four wonderful children.</span></p>`,
    }, */
    {
      name: "Shehu Abubakar",
      role: "Independent Director",
      img: Shehu,
      desc: `<p class="s4"><span class="s5">Mr. Abubakar serves as an Independent Director on the Board of UP.</span></p>
      <br/> <p class="s4"><span class="s5">He has over 30 years of experience in the banking industry during which time he held strategic positions in different Banks. He began his career in Co-operative &amp; Commerce Bank Plc where he served as an intern under the National Youth Service Corps scheme and worked at different times in International Merchant Bank, FSB International Bank, Fidelity Bank and Keystone Bank where he retired as an Executive Director. His banking experience covers areas such as corporate banking, public sector, corporate finance, leasing and treasury. His board experience includes being Director of a number of companies including Global Bank of Liberia, KBL Health Insurance and Cement Company of Northern Nigeria.</span></p>
    <br/> <p class="s4"><span class="s5">In the course of his career, Mr. Abubakar has attended many courses within and outside Nigeria including the Lagos Business School, Harvard Business School, Columbia Business School and Wharton Business School among others and has a wide range of experience in Strategy, Leadership &amp; Executive Management, Customer Relations and Management and Corporate Finance, among others.</span></p>`,
    },
  ];
  const management = [
    {
      name: "Agada B. Apochi",
      role: "Managing Director/Chief Executive Officer",
      img: Agada,
      desc: `<p class="s4"><span class="s5">Agada Apochi is an Organizational Psychologist &amp; Change Leadership Specialist and also a Practitioner-Scholar in diffusion of innovation and digital financial services. He has over 30 years of professional experience in banking and financial technology. Prior to his appointment as Managing Director &amp; Chief Executive Officer, he was an Executive Director. He also worked as inhouse solicitor in different banks before joining the services of UP.</span></p>
      <br/>   <p class="s4"><span class="s5">Agada is the Founder/Director of Hope PSBank as well as the payment scheme and financial technology company, Payattitude Global Limited and the telecom VAS provider, UP Digital Limited. He is also the Chairman of the Board of Directors of TM30, a software company.</span></p>
      <br/>   <p class="s4"><span class="s5">Agada attained Doctor of Business Administration (DBA) with specialization in diffusion of innovation and digital financial services as well as Master Degree in Applied Business Research from SBS Swiss Business School in Switzerland, Master Degree (MA) in Organizational Psychology with specialization in Change Leadership from Columbia </span><span class="s5">University in New York, Master Degree in Law (LL.M.) from Rivers State University, Barrister at Law (BL) from the Nigerian Law School and Bachelor Degree in Law (LL.B.) from the University of Jos. He is also is an Alumnus of Lagos Business School and Cornell University. He has attended different executive learning programs including programs at Harvard Business School, Insead Business School and Bank Card Business School in Cambridge. Agada holds several academic honours and awards including best graduating student. He was called to the Nigerian Bar in 1992 and has since worked and gained experience in company law &amp; practice, banking and financial services, electronic transactions, retail business, administration, strategy, execution and change leadership.</span></p>
      <br/>  <p class="s4"><span class="s5">Agada is currently the President of Lagos Business School Alumni Association and a Life Member of the Governing Council of the association. Agada is an Organizational Psychologist, Innovator and Entrepreneur. He is a Fellow, Chartered Institute of Bankers of Nigeria, Fellow, International Academy of Cards &amp; Payments, Fellow, Institute of Credit Administration, Certified International Cards &amp; Payments Professional (CICPP), Certified Associate of Capability Maturity Model Institute, Chartered Mediator &amp; Conciliator, Barrister &amp; Solicitor. He has to his credit, different professional and technical papers. Agada is married with children.</span></p>`,
    },
    {
      name: "Titilayo Olubiyi",
      role: "Director, Financial Institutions",
      img: Titi,
      desc: `<p class="s4"><span class="s5">Titilayo is the Director, Financial Institutions at Unified Payment. She has over two decades’ hands-on experience in Marketing, Sales, Channel Management and General Management. Titilayo was Group Head, Business Development in Unified Payments' Market & Sales Directorate, and the pioneering Group Head for Payattitude. She also has an excellent track record working with UACN, MTN, Nokia West Africa, and Samsung Electronics West Africa, with several performance awards.
</span></p>
      <br/> <p class="s4"><span class="s5">She has an MBA in Marketing, and BA Hons English from Obafemi Awolowo University. She is an Affiliate member of Chartered Institute of Marketing (UK) and a Member of Nigeria Institute of Marketing. She has attended several notable trainings within and outside the country including Cornell University, USA, IESE Business School, Barcelona, and Lagos Business School, Nigeria.</span></p>`,
    },
    {
      name: "Ochanya Dan-Ugo",
      role: "Director /Group Chief Risk Officer",
      img: Dan,
      desc: `<p class="s4"><span class="s5">Ochanya Dan-Ugo is a distinguished professional serving as the Director/Group Chief Risk Officer of Unified Payment. With a career spanning over decades, she grew the risk management department to a directorate level with her prowess. Before joining the company in 2005, Ochanya served as a Chief Superintendent of Narcotics and a Principal Staff Officer in Records Management (Intelligence) at the National Drug Law Enforcement Agency where she garnered her intelligence and investigative skills.
 </span><span class="s5">Ochanya is a Certified Risk and Compliance Management Professional (CRCMP), a Certified Cyber Governance Risk & Compliance Professional, Certified Information Systems Risk & Compliance Professional and a Chartered Security Professional. She also served as a member of the Visa Risk Executive Council for the Sub-Saharan Africa for four years.
</span></p>
      <br/> <p class="s4"><span class="s5">She is a Harvard trained Corporate Director, trained in INSEAD for Strategic Decision Making and holds a Global Competent Boards Designation (GCB.D) with specialty in ESG. She has also participated in various notable training programs, including those at the American Bankers Association, School of Bank Card Management, Emory University in Atlanta, USA, Cornell University, IESE Business School, Spain and Lagos Business School in Nigeria.
</span></p>
      <br/> <p class="s4"><span class="s5">Ochanya holds a Special Executive Management Certificate (SEMP) from the Metropolitan School of Business and Management, UK, a Master’s Degree in Humanitarian and Refugee Studies from the University of Lagos, a Post Graduate Diploma and Bachelor of Arts Degree from the University of Jos.
</span></p>
`,
    },
    {
      name: "Emmanuel Ejembi",
      role: "Director/Group Chief Technology Officer",
      img: Ejembi,
      desc: `  <p class="s4"><span class="s5">Emmanuel Ejembi is the Director/Group Chief Technology Officer. He was the Group Head of Information Technology and Operations for UP. He is a seasoned technology professional with deep implementation and technology management experience. A Certified Expert in IT Infrastructure Library (ITIL), and Expert Oracle Database Administrator with vast exposure to Information Technology (IT) infrastructure, Databases, Data Center Management, Electronic Payment Systems and Solutions and IT Services Management. Emmanuel joined the services of Unified Payments in 2005 and worked in several roles &ndash; as Systems Administrator, Database Administrator, Data Center Manager, Head of Department &ndash;Issuing, Group Head Information Technology and Operations. Prior to joining the services of Unified Payments, Emmanuel worked with First Foundation Medical Engineering, and Bentel Networks Ltd as an Engineer.</span></p>
      <br/>  <p class="s4"><span class="s5">He holds a B.Eng. degree in Electrical/Electronic Engineering from the Federal University of Agriculture, Makurdi - Benue State, Master of Business Administration (MBA) from Obafemi Awolowo University, Ile-Ife and M.Sc. in Information Systems Management from Roehampton University, London. He is also an alumnus of Cornel University, USA and the famous Lagos Business School. Emmanuel is member of the Nigeria Society of Engineers (NSE).</span></p>`,
    },
    {
      name: "Vivian Uchenna Okolo",
      role: "Director, Corporate Services",
      img: Okolo,
      desc: `<p class="s4"><span class="s5">Vivian joined the UP</span> <span class="s5">in 1999 and is currently Director, Corporate Services. </span><span class="s5">S</span><span class="s5">he has over twenty years work experience and has served in several capacities as Group Head, Financial Institutions, Group Head IT, Head of Training, Head of Acquiring Department with direct responsibility for growing merchant acceptance. Under Vivian, Unified Payment Services Limited merchant network grew significantly while income targets were surpassed. She also had direct responsibility for Unified Payment Services Limited successful application for </span><span class="s5">Payment Terminal Service Provider (PTSP) License from the Central Bank of Nigeria (CBN) in 2011.</span></p>
   <br/> <p class="s4"><span class="s5">As Group Head, IT Vivian led the IT &amp; Infrastructure, Software, and the Bespoke Processing Services operations of the company. She brings to the role, her experiences in IT, marketing function, interaction with external customers, as well as internal customers. Prior to UP, Vivian had worked with Inlaks Computers Limited as software applications specialist.</span></p>
   <br/>  <p class="s4"><span class="s5">She graduated from the Enugu State University of Science &amp; Technology with a B.Eng. in Computer Science and Engineering. She also holds an MBA in Marketing from ESUT Business School. Vivian has attended several management courses in and outside Nigeria including Senior Management Programme and Advanced Management Programme in Lagos Business School, AMP-CEP Global programme in IESE Business School, Barcelona, Spain, and Cornell University, USA. She also holds a certificate in FinTech from the Harvard Business School.</span></p>`,
    },
  ];
  const [modal, setModal] = useState();
  const [detail, setDetails] = useState();
  return (
    <>
      <GeneralModal
        modalState={modal}
        closeModal={() => {
          setModal(false);
          setDetails();
        }}
        maxWidth={1000}
      >
        <div className="flex items-center">
          <img
            className="w-[160px] h-[160px] object-cover rounded-full border"
            src={detail?.img}
            alt=""
          />
          &nbsp; &nbsp;&nbsp;
          <div>
            <h2 className="text-3xl font-bold">{detail?.name}</h2>
            <br></br>
            <h4 className="font-semibold">{detail?.role}</h4>
          </div>
        </div>
        <p className="my-10 lg:text-[16px] text-[14px] md:leading-[190%] text-[#7A7D9C]">
          <div dangerouslySetInnerHTML={{ __html: detail?.desc }} />
        </p>
      </GeneralModal>
      <div className="w-full pb-[10%] ">
        <div className="md:text-[30px] text-[18px] text-center font-medium">
          Board of Directors
        </div>

        <div className="grid md:grid-cols-3  gap-[3%] mt-[5%]">
          {board?.map((item) => {
            return (
              <div
                onClick={() => {
                  setDetails(item);
                  setModal(true);
                }}
                className="bg-white cursor-pointer rounded-2xl text-center overflow-hidden shadow-sm border"
              >
                <img
                  src={item?.img}
                  alt=""
                  srcset=""
                  className="w-full h-[60%] object-cover"
                />
                <div className="  p-[8%] pb-[10px]">
                  <div className="text-[#5D5A88]">
                    <div className="md:text-[24px] text-[16px] font-medium">
                      {item?.name}
                    </div>
                    <div className="md:text-[16px] text-[14px] my-[4%] uppercase">
                      {item?.role}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="md:text-[30px] text-[18px] text-center font-medium my-[8%]">
          Management{" "}
        </div>
        <div className="grid md:grid-cols-5 gap-[2%]">
          {management?.map((item) => {
            return (
              <div
                onClick={() => {
                  setDetails(item);
                  setModal(true);
                }}
                className="text-center cursor-pointer"
              >
                <img
                  src={item?.img}
                  alt=""
                  className="w-[90px] h-[90px] mx-auto object-cover rounded-full border"
                />
                <div>
                  <div className="md:text-[22px] text-[16px] my-[8%] font-medium text-[#5D5A88]">
                    {item?.name}
                  </div>
                  <div className="md:text-[16px] text-[14px] text-[#8D8BA7]">
                    {item?.role}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
