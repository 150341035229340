import React from "react";

export default function PhoneOutIcon(props) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.865234"
        y="0.865234"
        width="59.7316"
        height="59.7316"
        rx="12.4441"
        fill="#F58220"
      />
      <path
        d="M41.2297 35.4663L37.5421 35.0453C37.1085 34.9944 36.669 35.0424 36.2566 35.1857C35.8443 35.329 35.4697 35.5639 35.1612 35.8728L32.4898 38.5441C28.3684 36.4481 25.0185 33.0981 22.9224 28.9767L25.6083 26.2909C26.2325 25.6666 26.5374 24.7955 26.4358 23.9099L26.0148 20.2513C25.9325 19.5431 25.5926 18.8899 25.0598 18.416C24.5271 17.9422 23.8387 17.6808 23.1257 17.6816H20.614C18.9735 17.6816 17.6088 19.0463 17.7104 20.6869C18.4799 33.0853 28.3957 42.9867 40.7797 43.7561C42.4202 43.8578 43.7849 42.4931 43.7849 40.8525V38.3409C43.7994 36.8746 42.6961 35.6405 41.2297 35.4663Z"
        fill="white"
      />
    </svg>
  );
}
