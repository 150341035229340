import React from "react";
import ReadArrowiIcon from "assets/icons/readArrow";

export default function Article({ date, title, desc, onClick, Image }) {
  return (
    <div className="bg-white rounded-2xl overflow-hidden shadow-sm">
      <img src={Image} alt="" srcset="" className="w-full" />
      <div className="flex flex-col justify-between items-start p-[6%] ">
        <div>
          <div className="text-[#98A2B3] md:text-[16px] text-[10px] font-normal">
            {date}
          </div>
          <div className="md:text-[18px] text-[14px] my-[4%] font-medium">
            {title}
          </div>
          <div className="text-[#98A2B3] md:text-[14px] text-[12px] font-normal">
            {desc}
          </div>
        </div>
        <div onClick={onClick}>
          <div className="flex cursor-pointer items-center text-primary mt-[40%] md:text-[14px] text-[10px]">
            Read post &nbsp; <ReadArrowiIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
