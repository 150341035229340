import React from "react";

export default function BillingIcon(props) {
  return (
    <svg
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.689453"
        y="0.193359"
        width="86.126"
        height="86.126"
        rx="25"
        fill="#FFE5E9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.2093 24.0527C59.5914 24.0527 62.845 25.1836 65.2385 27.2128C67.6213 29.2266 68.959 31.9389 68.9736 34.7809H18.5314C18.5614 28.8495 24.2474 24.0527 31.2932 24.0527H56.2093ZM18.5312 40.2607V51.6848C18.5312 57.638 24.254 62.4603 31.2932 62.4603H56.2093C63.2485 62.4603 68.9737 57.638 68.9737 51.6848V34.8282C68.9737 34.8214 68.9737 34.8145 68.9737 34.8077V40.2607H18.5312Z"
        fill="#FF5F85"
      />
    </svg>
  );
}
