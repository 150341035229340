import SectionHeader from "components/sectionHeader";
import React /* useState */ from "react";

export default function PrivacyPolicy() {
  const dets = `   <div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;">

</div>
<div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;">
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>1. INTRODUCTION</strong>
    </strong>
    <br />
    <br />
    Unified Payment Services Limited understands that your privacy is
    important and that you care about how your personal data is used
    and shared online. We respect the privacy of everyone who visits
    our website and uses our online services. We are committed to
    respecting, securing and protecting your personal data. We are
    also committed to being transparent about what we collect from you
    and how we use it. This privacy policy covers information
    practices, including how we use, share and secure the personal
    information you provide and your legal rights.
    <br />
    <br />
    <strong>
      <strong>Our contact details</strong>
    </strong>
    <br />
    Address: 3, Idowu Taylor Street, Victoria Island, Lagos
    <br />
    Email:helpdesk@up-ng.com
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>2.0 YOUR RIGHTS</strong>
    </strong>
    <br />
    Under the Nigerian Data Protection Regulation (NDPR), you have
    several rights about your personal data. This policy has been
    designed to uphold your rights where we act as a data
    processor/controller:
  </p>
  <ul>
    <li>
      <strong>
        <strong>Right of access</strong>
      </strong>
      &ndash; you have the right to request a copy of the information
      that we hold about you.
    </li>
    <li>
      <strong>
        <strong>Right of rectification</strong>
      </strong> 
      &ndash; you have a right to correct data that we hold about you
      that is inaccurate or incomplete.
    </li>
    <li>
      <strong>
        <strong>Right to be forgotten</strong>
      </strong> 
      &ndash; in certain circumstances you can ask for the data we
      hold about to be erased from our record.
    </li>
    <li>
      <strong>
        <strong>Right to restriction of processing</strong>
      </strong> 
      &ndash; where certain conditions apply to have a right to
      restrict the processing.
    </li>
    <li>
      <strong>
        <strong>Right of portability</strong>
      </strong> 
      &ndash; you have the right to have the data we hold about you
      transferred to another organization.
    </li>
    <li>
      <strong>
        <strong>Right to object</strong>
      </strong> 
      &ndash; you have the right to object to certain types of
      processing such as direct marketing.
    </li>
    <li>
      <strong>
        <strong>Right of portability</strong>
      </strong> 
      &ndash; you have the right to have the data we hold about you
      transferred to another organization.
    </li>
    <li>
      <strong>
        <strong>
          Right to object to automated processing, including profiling
        </strong>
      </strong> 
      &ndash; you also have the right not to be subject to automated
      processing or profiling.
    </li>
    <li>
      <strong>
        <strong>Right to judicial review</strong>
      </strong> 
      &ndash; if we refuse your request under rights of access, we
      will provide you with a reason to why. You have the right to
      complain as detailed below.
    </li>
    <li>
      <strong>
        <strong>Right to complain</strong>
      </strong> 
      - If you wish to make a complaint about how your personal data
      is being processed or how your complaint has been handled, you
      have the right to lodge a complaint directly to our in-house
      data officer or with the supervisory authority.
    </li>
  </ul>
  If we are unable to help, or you are not satisfied with our
  response, you also have the right to lodge a complaint with the
  National Information Technology Development Agency (NITDA).
  <br />
  <br />
  <strong>
    <strong>NITDA can be contacted:</strong>
  </strong>
  <br />
  By post &ndash; No 28, Port Harcourt Crescent, Off Gimbiya Street,
  P.M.B 564, Area 11 Garki, Abuja, Nigeria.
  <br />
  By telephone &ndash; 08168401851
  <br />
  Via website &ndash; nitda.gov.ng.
  <p style="text-align: justify;font-size: 14px;">
    <br />
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>3.0 DATA COLLECTION</strong>
    </strong>
    <br />
    By expressing an interest in our services, visiting our websites
    or registering to use our service, we may collect personal
    information from you:
  </p>
  <ul>
    <li>
      Contact information such as name, company name, address, phone
      number and email address.
    </li>
    <li>
      Financial information such as billing name, billing address,
      PAN, CVV
    </li>
    <li>
      System information, such as IP Address, browser type and
      version, and operating system relating to information gathering
      tools such as cookies. Click here to read our cookie policy.
    </li>
    <li>
      Other information such as the number of people within your
      organization using our service.
    </li>
  </ul>
  <p style="text-align: justify;font-size: 14px;">
    <br />
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>4.0 DATA USAGE</strong>
    </strong>
    <br />
    The data we collect is used to educate and inform customers about
    our product and to improve our service. We may also collect
    personal information about our prospective and existing customers,
    employees, vendors, and service providers. The information
    collected is used to provide services to our customers. These may
    include but not limited to any of the following:
  </p>
  <ul>
    <li>Processing service requests</li>
    <li>Negotiating contracts</li>
    <li>Handling orders</li>
    <li>Promotional offers and marketing</li>
    <li>Providing customer support</li>
    <li>Managing customer relationships</li>
    <li>Developing and improving our services</li>
    <li>
      Compliance with governmental, legislative and regulatory bodies,
      and investigating complaints about the use of our services
    </li>
  </ul>
  We process personal information for certain business purposes, which
  include some or all the following:
  <ul>
    <li>
      Where the processing enables us ensure efficient transactional
      purposes
    </li>
    <li>
      Where the processing enables us to enhance, modify, personalize
      or otherwise improve our services/communications for the benefit
      of our customers
    </li>
    <li>
      To better understand how people interact with our websites
    </li>
  </ul>
  We ensure that we only process data in the interests of our company
  in conducting and managing our business to enable us to give
  customers the best service/products and secure experiences. This is
  known as &ldquo;Legitimate Interests&rdquo;.
  <br />
  <br />
  We will only ever send you communications that we feel are relevant
  to you. We may process your information to protect you against fraud
  when transacting on our website, and to ensure our websites and
  systems are secure.
  <br />
  <br />
  When processing your personal information for our legitimate
  interests, we consider and balance any potential impact on you
  &ndash; both positive and negative and your rights under data
  protection laws.
  <br />
  <br />
  Our legitimate business interests do not automatically override your
  interests. As such, we will not use your personal data for
  activities where our interests are overridden by the impact on you,
  unless we have your consent or are otherwise required or permitted
  to by law.
  <br />
  <br />
  If you have any objection to our data processing by legitimate
  interests, please see &lsquo;your rights&rsquo; section at the start
  of this policy.
  <p style="text-align: justify;font-size: 14px;">
    <br />
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>5.0 STORING AND SHARING YOUR INFORMATION</strong>
    </strong>
    <br />
    <strong>
      <strong>5.1 SUB-PROCESSORS</strong>
    </strong>
    <br />
    <br />
    Who is a sub-processor?
    <br />
    <br />A sub-processor is a third-party company engaged by us to
    assist with data processing.
    <br />
    We set a high standard in selecting sub-processors to ensure that
    they are secure, maintain strict privacy policies and adhere to
    confidentiality practices in processing data.
    <br />
    <br />
    We may share your data with these companies to provide services on
    both our and your behalf. These service providers, under
    contractual obligations, will use your personal information only
    as necessary to provide the requested services to us. We will not
    sell, rent, or trade any information with third parties for
    promotional or marketing purposes.
    <br />
    <br />
    The use of our service requires the sharing of data with
    sub-processors which are subject to rigorous privacy statements.
    To use our service, you must agree with our use of sub-processors.
    <br />
    <br />
    <strong>
      <strong>5.2 BILLING</strong>
    </strong>
    <br />
    We use a third-party service provider to process orders. These
    third-party services are not permitted to store, retain, or use
    any billing information provided by us.
    <br />
    <br />
    <strong>
      <strong>5.3 DISCLOSURE</strong>
    </strong>
    <br />
    If required by law or if disclosure is necessary to protect our
    rights and/or to comply with judicial proceedings, court order, or
    legal process, we reserve the right to disclose any relevant
    information.
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>6.0 COMMUNICATION</strong>
    </strong>
    <br />
    When you provide information through one of our web forms or other
    avenues, we might provide you with further information about our
    services. You can manage your subscription preferences at any time
    and unsubscribe from this information.
    <br />
    <br />
    Should you request information from us, we will use the data you
    provide to process this request.
    <br />
    <br />
    For example, by completing our &lsquo;Access request form&rsquo;,
    we will get a better understanding of your requirements.
    <br />
    <br />
    We may use information you provided to contact you to further
    discuss your interest in our services and to send you information
    regarding us and our affiliates. We will only process the
    information you provide on the grounds of legitimately meeting
    your requirements.
    <br />
    <br />
    To consistently provide an expected level of service, we may also
    use information collected to update, expand and analyze our
    records to create a more befitting service for you.
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>7.0 KEEPING YOUR DATA UP-TO-DATE</strong>
    </strong>
    <br />
    We may retain your information for a period consistent with the
    original reason it was collected. Upon entering into an agreement
    with us, we reserve the right to store and use your information.
    Upon termination of the agreement, we would keep your information
    for required legal reasons.
    <br />
    <br />
    As a customer, you have the right to request to review, correct,
    delete or otherwise modify any of the personal information you may
    have previously provided to us, at any time. As a user of our
    product, you may generally update information wherever necessary.
    To update your billing information or terminate your agreement
    with us, you can contact us.
    <br />
    <br />
    For other requests: erasure, access, portability, rectification,
    and objection, your requests will be addressed within thirty (30)
    days.
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>8.0 YOUR DATA</strong>
    </strong>
    <br />
    Our customers submit data and information, whether electronically
    or by other means, to us for processing purposes. We will not
    review, share, alter or distribute this data unless otherwise
    requested by law. We only access your data only for the purposes
    of continuing to provide our services or to prevent or address any
    service or technical problems. As stated above, the information we
    hold about you can be accessed upon request.
  </p>
  <p style="text-align: justify;font-size: 14px;">
    <strong>
      <strong>9.0 SECURITY</strong>
    </strong>
    <br />
    As part of delivering optimum service to our customers, we take
    adequate security measures to maintain your data in a secure and
    organized manner. Only authorized employees from our organization
    can access, alter, disclose and destroy personal data, when
    required.
    <br />
    <br />
    In addition, these employees only act within the scope of their
    authority and are trained to prevent any errors or omissions.
    <br />
    <br />
    We also carry out ongoing security monitoring exercises to ensure
    that our systems are updated and adopt appropriate industry data
    collection, storage, and processing practices.
    <br />
    <br />
    Physical security measures are in place to protect against
    unauthorized access, alteration, disclosure of personal
    information, username, password, transaction information and data
    stored in your user account. Access to your name and address is
    restricted to our employees who need to know such information in
    connection with delivering our services to you and are bound by
    legal confidential obligations.
    <br />
    <br />
    We reserve the right to change this privacy policy.
  </p>
</div>`;
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="max-w-[66%] w-[90%] mx-auto">
          <SectionHeader
            title="Privacy Policy"
            subHeading="Please read through our terms and conditions"
          />
        </div>
        <div className="max-w-[66%] w-[90%] mx-auto mt-[4%] mb-[8%]">
          {/*   <PrivacyPolicyBody /> */}

          <div dangerouslySetInnerHTML={{ __html: dets }} />
        </div>
      </div>
    </>
  );
}

//https://lottie.host/ea5fcd35-d245-4a57-9c6a-2520055d0762/EUP2FlyO9y.json
//https://lottie.host/d16fd47a-aa19-46dc-aceb-b125cbf858d5/4Fw1ErGxBW.json
//https://lottie.host/5849740a-4b1c-49f6-9dd2-66089f6b7b40/Wbb4ZTTsqz.json
//https://lottie.host/105214a7-fd4b-40d5-8375-c48441cdb786/bugkoIKm4x.lottie
