import Arrow2 from "assets/icons/arrow2";
import React, { useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import "assets/css/home.css";
import Hero from "assets/images/hero.png";
import webvisual1 from "assets/images/web visual 1.jpg"
import webvisual2 from "assets/images/web visual 2.jpg"
import webvisual3 from "assets/images/web visual 3.jpg"


import SectionHeader from "components/sectionHeader";
import TabHome from "./components/Tab";
import TabComponent from "./components/tabComponent";
import illus1 from "assets/images/illus1.png";
import illus2 from "assets/images/illus2.png";
import illus3 from "assets/images/illus3.png";
import Play2 from "assets/icons/play2";
import Values from "assets/images/values.png";
import { articles, values } from "./utils/data";
import Article from "components/aritcle";
import BillersIcon from "assets/icons/billersIcon";
import ActiveBusiness from "assets/icons/activeBusiness";
import CardActiveIcon from "assets/icons/cardActive";

import Code from "assets/icons/code";
import Manage from "assets/icons/management";
import Biro from "assets/icons/biro";
// import MainVid from "assets/video/main.mp4";
import MainVid from "assets/video/update.mp4";

//import HeroGif from "../../assets/gifs/hero.gif";

import Top from "assets/images/topPartners.png";
import Bottom from "assets/images/bottomPartners.png";
import { Link, useNavigate } from "react-router-dom";

import Payment from "assets/images/landPayment.png";
import Merchant from "assets/images/landMerchant.png";
import Switch from "assets/images/landSwitch.png";
import { SwiperSlide,Swiper } from "swiper/react";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";

//import GeneralModal from "components/GeneralModal";

export default function HomePage() {
  const [active, setActive] = useState({
    icon: Code,
    btn: "main_btn",
    title: "Payment Processing",
    title2: `Payment <br/>  Processing`,
    img: Payment,
    desc: "Our expert understanding of business needs & dynamics is increasingly distinguishing us as the processor of choice in sub-Saharan Africa. Explore our integrated payment systems.",
  });
  const tab = [
    {
      icon: Code,
      title: `Payment Processing`,
      title2: `Payment <br/>  Processing`,
      img: Payment,
      desc: "Our expert understanding of business needs & dynamics is increasingly distinguishing us as the processor of choice in sub-Saharan Africa. Explore our integrated payment systems.",
      btn: "main_btn",
    },
    {
      icon: Manage,
      title: `Merchant Services`,
      title2: `Merchant <br/> Services`,
      img: Merchant,
      desc: "As a Principal Member and Licensed Acquirer of all schemes- American Express, Mastercard, PayAttitude, UnionPay and Visa, explore our catalogue of merchant-focused services designed to help you scale.",
      btn: "main_btn2",
    },
    {
      icon: Biro,

      title: `Switching Services`,
      title2: `Switching <br/> Services`,
      img: Switch,
      desc: "Given our network of links with banks in Nigeria, UP is also able to provide switching services between Issuers and Acquirers in Nigeria, thereby significantly reducing connectivity costs between Issuers and Acquirers as well as increasing the throughput, through a significant reduction in potential points of failure.",
      btn: "main_btn3",
    },
  ];

  const [modal, setModal] = useState();
  const navigate = useNavigate();

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    vidRef.current.pause();
  };

  return (
    <>
      <div className="max-w-[66%] w-[90%] pt-[6%] mx-auto grid md:grid-cols-2 grid-cols-1">
        <div>
          <div className=" md:text-[2.9vw] text-[22px] font-semibold">
            <div> Payment innovation</div>
            <span className="text-primary font-semibold">
              {" "}
              for Africa’s{" "}
            </span>{" "}
            future
          </div>
          <div className="md:text-[1vw] text-[12px] md:my-6 my-2">
            Powering Africa with simplified payment
            <br /> innovation for businesses and individuals; our payment
            systems <br /> fuel commerce, drive growth, and ignite individual
            success.
          </div>

          <Link to="/services?activeTab=Payment%20Processing">
            <button className="main_btn">
              Discover More &nbsp; &nbsp;
              <Arrow2 />{" "}
            </button>
          </Link>
        </div>
         <div className="md:block hidden transform translate-y-[-7%] ">
          <Swiper
            slidesPerView={1}
            modules={[Navigation, Scrollbar, A11y, Pagination, Autoplay]}
            autoplay={{
              delay:  60000,
              disableOnInteraction: false,
            }}
            navigation
            pagination={{ clickable: true }}
            onSwiper={(swiper) => swiper}
            className="md:w-[155%] w-[100%]"
            style={{
              "--swiper-navigation-size": "0px",
            }}
          >
            <SwiperSlide>
              <img className="w-[70%] h-[70%]" src={webvisual1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-[50%] h-[50%]" src={webvisual2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-[70%] h-[70%]" src={webvisual3} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="md:block hidden transform translate-y-[-14%]">
          {" "}
          <img className="w-[120%] h-[120%]" src={Hero} alt="" />
        </div> */}
      </div>
      <div className="w-[100%] py-[6%] bg-bgPrimary">
        <div id="who" className="max-w-[56%] w-[90%] mx-auto">

          <SectionHeader
            title="Who We Are"
            subHeading="We are Africa’s payment technology warehouse"
            description="We are the first and only non-bank entity that is a principal member and licensed acquirer of all card schemes, transforming the payments landscape with cutting edge solutions that positively impact institutions and industries"
          />
        </div>
      </div>
      <div className="w-[100%] bg-bgPrimary">
        <div className="max-w-[56%] w-[90%] mx-auto">
          <TabHome active={active} setActive={setActive} tab={tab} />
          {/*  <TabHome
              active={active}
              setActive={setActive}
              tab={tab?.slice(3, 7)}
            /> */}
          <TabComponent
            btn={active?.btn}
            title={active?.title}
            desc={active?.desc}
            img={active?.img}
            icon={active}
          />
        </div>
      </div>
      <div className="w-[100%] py-[6%] bg-bgPrimary">
        <div className="max-w-[66%] w-[90%] bg-noise bg-no-repeat bg-cover text-center rounded-3xl mx-auto h-fit pb-[8%]">
          <div className="text-center w-100% text-white py-[13%] px-[15%] grid place-items-center">
            <div className={`text-[3vw] font-medium`}>
              Watch how far we have come{" "}
            </div>
            <div className="text-[1.4vw] my-[2.4%] font-normal">
              Since 1997, we have remained committed to our vision of
              transforming Nigeria’s payment landscape with simplified
              innovation and agile execution.
            </div>
            <div>
              <Link to="/about?activeTab=Overview">
                <button className="main_btn_invert">
                  Learn More &nbsp; &nbsp;
                  <Arrow2 fill="#00AFEF" />{" "}
                </button>
              </Link>
            </div>
          </div>
          <div className="relative h-[90vh]">
            <img
              className="w-[50%] h-[80%] hover:translate-y-[-5%] transition-all absolute object-cover top-0 rounded-xl left-[50%] transform translate-x-[-50%]"
              src={illus1}
              alt=""
              srcset=""
            />
            <img
              className="w-[60%] h-[80%] hover:translate-y-[-5%] transition-all absolute object-cover top-20 rounded-xl left-[50%] transform translate-x-[-50%]"
              src={illus2}
              alt=""
              srcset=""
            />
            <div className="absolute top-40 w-[70%] h-[80%] left-[50%] transform translate-x-[-50%]">
              <div
                className={`w-[100%] h-[100%] rounded-xl overflow-hidden bg-[#000000] ${
                  modal ? "" : "hidden fixed pointer-events-none"
                }`}
              >
                <video
                  src={MainVid}
                  className="w-[100%] h-[100%] "
                  ref={vidRef}
                  controls={false}
                  onClick={() => {
                    setModal(!modal);
                    handlePauseVideo();
                  }}
                />
              </div>
              {modal ? (
                ""
              ) : (
                <>
                  <img
                    className="w-[100%] h-[100%]   object-cover rounded-xl "
                    src={illus3}
                    alt=""
                    srcset=""
                  />
                </>
              )}
              {modal ? (
                ""
              ) : (
                <Play2
                  onClick={() => {
                    setModal(true);
                    handlePlayVideo();
                  }}
                  className="absolute cursor-pointer hover:scale-150 transition-all top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] pb-[2%] pt-[6%] bg-pride bg-no-repeat bg-cover">
        <div className="max-w-[66%] w-[90%] text-center rounded-3xl mx-auto h-fit pb-[8%]">
          <div className="md:text-[3.4vw] text-[22px] font-medium text-white ">
            Our Core Values - <span className="text-secondary">P.R.I.D.E</span>
          </div>
          <div className="md:text-[1.4vw text-[12px] text-[#ffffff] font-normal my-[2vw]">
            Our success is built firmly on our commitment to our core values.{" "}
          </div>
          <img
            src={Values}
            className="w-[90%] md:block hidden mx-auto pointer-events-none select-none"
            alt=""
          />
          <div className="grid md:grid-cols-5 gap-4 mt-10 md:mt-0 w-full">
            {values.map((item, index) => {
              return (
                <div
                  className={`transform ${
                    (index === 0 && "md:translate-x-[2%]") ||
                    (index === 1 && "md:translate-x-[-2%]") ||
                    (index === 2 && "md:translate-x-[-2%]") ||
                    (index === 3 && "md:translate-x-[5%]") ||
                    (index === 4 && "md:translate-x-[-2%]")
                  } w-full`}
                >
                  <div className="md:text-[0.9vw] text-[14px] text-[#ffffff] font-normal my-[1vw]">
                    {item.title}{" "}
                  </div>
                  <div className="mdtext-[0.9vw] text-[14px] text-[#efefef] font-normal">
                    {item.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-[100%] py-[6%] bg-bgPrimary ">
        <div className="max-w-[66%] w-[90%] rounded-3xl mx-auto h-fit">
          <div
            className={`my-[1.6%] text-center md:text-[2.6vw] text-[18px] font-medium`}
          >
            Stay informed, explore our latest articles and resources
          </div>
          <div className="grid md:grid-cols-3 gap-10 mt-[6%]">
            {articles?.slice(0, 3).map((item) => {
              return (
                <Article
                  title={item.title}
                  desc={item.desc}
                  link={item?.link}
                  Image={item?.image}
                  onClick={() => {
                    localStorage.setItem("content", JSON.stringify(item));
                    navigate(`/news-and-insight/blog`);
                  }}
                />
              );
            })}
          </div>
          <Link to="/news-and-insight">
            <button
              className="main_btn2 mx-auto mt-[6%]"
              to="/news-and-insight"
            >
              Browse articles
            </button>
          </Link>
        </div>
      </div>
      <div className="w-[100%] py-[6%] bg-[#005388]">
        <div className="max-w-[66%] w-[90%] rounded-3xl mx-auto grid md:grid-cols-2 md:gap-20 gap-10">
          <div
            className={` md:text-[2.4vw] text-[16px] text-white font-medium`}
          >
            Pushing the boundaries of innovation to deliver payment solutions
            that enable commerce across Africa
          </div>
          <div>
            <div className="text-white md:text-[1.4vw] text-[14px] mb-[6%]">
              Bespoke payment solutions for your modern lifestyle, business
              collections, disbursements and payment processing
            </div>
            <button className="main_btn2">Learn more</button>
          </div>
        </div>

        <div className="max-w-[66%] w-[90%] rounded-3xl mx-auto grid md:grid-cols-4 md:gap-[10%] gap-[10px] mt-[4%]">
          <div>
            <BillersIcon />
            <div
              className={` md:text-[1.8vw] text-[14px] text-white font-medium `}
            >
              7,000+
            </div>
            <div
              className={` md:text-[0.9vw] text-[12px] text-white font-medium`}
            >
              Billers aggregated on the Payarena platform{" "}
            </div>
          </div>
          <div>
            <BillersIcon />
            <div
              className={` md:text-[1.8vw] text-[14px] text-white font-medium`}
            >
              41,560+
            </div>
            <div
              className={` md:text-[0.9vw] text-[12px] text-white font-medium`}
            >
              UP Agents enabled across Nigeria{" "}
            </div>
          </div>
          <div>
            <ActiveBusiness />
            <div
              className={` md:text-[1.8vw] text-[14px] text-white font-medium`}
            >
              300,000+
            </div>
            <div
              className={` md:text-[0.9vw] text-[12px] text-white font-medium`}
            >
              Active businesses transacting daily{" "}
            </div>
          </div>
          <div>
            <CardActiveIcon />
            <div
              className={` md:text-[1.8vw] text-[14px] text-white font-medium `}
            >
              25,000,000+
            </div>
            <div
              className={` md:text-[0.9vw] text-[12px] text-white font-medium`}
            >
              Cards activated on the payattitude network{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] py-[6%] bg-bgPrimary ">
        <div className={`my-[16px] text-center text-[36px] font-medium`}>
          Trusted by the brands you trust{" "}
        </div>
        <br />
        <br />

        <Marquee autoFill={true} loop={0} speed={100}>
          <img className="md:w-[100%] w-[50%]" src={Top} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Top} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Top} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Top} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Top} alt="" />
        </Marquee>
        <br />
        <Marquee autoFill={true} loop={0} direction="right" speed={100}>
          <img className="md:w-[100%] w-[50%]" src={Bottom} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Bottom} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Bottom} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Bottom} alt="" />
          <img className="md:w-[100%] w-[50%]" src={Bottom} alt="" />
        </Marquee>
      </div>
      {/* hi */}
    </>
  );
}
