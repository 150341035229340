import React from "react";

export default function CareerIcon(props) {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.720703"
        y="0.847656"
        width="90.3127"
        height="90.3127"
        rx="26.2153"
        fill="#F58634"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.8873 48.6529C49.184 48.6529 55.033 42.6205 55.033 35.095C55.033 27.5695 49.184 21.5371 41.8873 21.5371C34.5906 21.5371 28.7416 27.5695 28.7416 35.095C28.7416 42.6205 34.5906 48.6529 41.8873 48.6529ZM41.8905 52.9959C31.1735 52.9959 22.0234 54.7405 22.0234 61.7109C22.0234 68.6786 31.1178 70.4861 41.8905 70.4861C52.6049 70.4861 61.7576 68.7415 61.7576 61.7711C61.7576 54.8007 52.6632 52.9959 41.8905 52.9959ZM69.4372 40.6506H72.6241C73.9359 40.6506 75.0054 41.7549 75.0054 43.1093C75.0054 44.4637 73.9359 45.5681 72.6241 45.5681H69.4372V48.7433C69.4372 50.0977 68.3704 51.2021 67.0559 51.2021C65.7441 51.2021 64.6745 50.0977 64.6745 48.7433V45.5681H61.4931C60.1786 45.5681 59.1118 44.4637 59.1118 43.1093C59.1118 41.7549 60.1786 40.6506 61.4931 40.6506H64.6745V37.4782C64.6745 36.1238 65.7441 35.0194 67.0559 35.0194C68.3704 35.0194 69.4372 36.1238 69.4372 37.4782V40.6506Z"
        fill="#F58634"
      />
    </svg>
  );
}
