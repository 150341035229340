import React from "react";

import SectionHeader from "components/sectionHeader";
import SideTab from "components/SideTab";
import Overview from "./components/Overview";
import { useSearchParams } from "react-router-dom";
import LeaderShip from "./components/LeaderShip";
import Gallery from "./components/Gallery";
import Subsidiary from "./components/Subsidiary";

export default function AboutPage() {
  const tab = ["Overview", "Leadership", "Subsidiaries", "Photo Gallery"];

  const [param] = useSearchParams();
  const activeTab = param.get("activeTab");

  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="max-w-[66%] w-[90%] mx-auto">
          <SectionHeader
            title="About Us"
            subHeading="Leading the way with innovation excellence"
            description={
              <>
                <p>
                  <span className="font-semibold">
                    Unified Payment Services Limited
                  </span>{" "}
                  is Nigeria’s premier Payments & Financial Technology company
                  that operates as a shared infrastructure for the banking
                  community in Nigeria and Payments Service Provider within and
                  outside Nigeria, with a vision to be the most preferred
                  e-payment service provider in Africa.
                </p>
              </>
            }
          />
        </div>
        <div className="bg-bgPrimary w-full pt-[8%] my-[8%]">
          <div className="max-w-[66%] w-[90%] mx-auto grid md:grid-cols-5">
            <div className="col-span-1 md:block hidden">
              <SideTab tab={tab} />
            </div>
            <div className="col-span-4">
              {activeTab === "Overview" && (
                <div>
                  <Overview />
                </div>
              )}
              {activeTab === "Leadership" && (
                <div>
                  <LeaderShip />
                </div>
              )}
              {activeTab === "Subsidiaries" && (
                <div>
                  <Subsidiary />
                </div>
              )}
              {activeTab === "Photo Gallery" && (
                <div>
                  <Gallery />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
