import ArrowIcon from "assets/icons/arrow";
import LockIcon from "assets/icons/lock";
//import SearchIcon from "assets/icons/search";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/icons/logo.webp";
import styled from "styled-components";

export default function TopNavBar() {
  const [mobileNav, setMobileNav] = useState(false);

  return (
    <>
      <div className="bg-bgPrimary md:flex w-full items-center hidden">
        <div className="w-full">
          <div className="flex w-[100%] text-white font-normal">
            <div className="bg-secondary relative before:absolute before:left-0 before:top-0 before:transform before:skew-x-[30deg] before:bg-secondary before:h-full before:w-[10%] w-[100%] p-[1.4%] transform skew-x-[-30deg]">
              <div className="transform skew-x-[30deg] text-[0.8vw] font-normal flex justify-between items-center pr-[5%] w-[60%] ml-auto">
                <Link to="/locate">
                  <div>Locate</div>
                </Link>
                <Link to="/">
                  <div>Media</div>
                </Link>
                <Link to="/contact">
                  <div>Contact us</div>
                </Link>
                <a
                  href="https://sandbox.up-ng.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>Sandbox</div>
                </a>
              </div>
            </div>
            <Link
              to="/privacy"
              className="bg-primary block w-[18%] p-[1.4%] relative text-center transform skew-x-[-30deg]"
            >
              <div className="transform skew-x-[30deg] text-[0.8vw] font-normal px-[8%] flex justify-between items-center">
                <LockIcon width="11%" height="11%" />
                <div>Privacy Policy</div>
                <ArrowIcon width="6%" height="6%" />
              </div>
            </Link>
          </div>
          <div className="py-[2.7%] font-medium flex w-[100%]">
            {" "}
            <div className="transform text-[0.9vw] flex justify-between items-center pr-[5%] w-[76%] ml-auto">
              <Link to="/">
                <div>Home</div>
              </Link>
              <Link to="/about?activeTab=Overview">
                <div>About Us</div>
              </Link>
              <Link to="/services?activeTab=Payment%20Processing">
                <div>Services</div>
              </Link>
              <Link to="/careers">
                <div>Careers</div>
              </Link>
              <Link to="/news-and-insight">
                <div>Blog (News & Insights)</div>
              </Link>
              <Link to="/contact">
                <div>Contact Us</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="px-[10%]">
          <img className="w-[6vw]" src={logo} alt="" srcset="" />
        </div>
      </div>

      <div className="w-[80%] mx-auto py-4 md:hidden flex justify-between items-center">
        <img className="md:w-[6vw] w-10" src={logo} alt="" srcset="" />
        <svg
          onClick={() => {
            setMobileNav(true);
          }}
          className="cursor-pointer"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 26H27.75M12 20H27.75M12 14H27.75"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      {mobileNav && (
        <MobileNav className="md:hidden block">
          <div style={{ padding: "25px" }}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setMobileNav(false);
              }}
              style={{
                width: "40px",
                cursor: "pointer",
                position: "absolute",
                top: "24px",
                right: "20px",
              }}
            >
              <path
                d="M15.9974 2.6665C8.65073 2.6665 2.66406 8.65317 2.66406 15.9998C2.66406 23.3465 8.65073 29.3332 15.9974 29.3332C23.3441 29.3332 29.3307 23.3465 29.3307 15.9998C29.3307 8.65317 23.3441 2.6665 15.9974 2.6665ZM20.4774 19.0665C20.8641 19.4532 20.8641 20.0932 20.4774 20.4798C20.2774 20.6798 20.0241 20.7732 19.7707 20.7732C19.5174 20.7732 19.2641 20.6798 19.0641 20.4798L15.9974 17.4132L12.9307 20.4798C12.7307 20.6798 12.4774 20.7732 12.2241 20.7732C11.9707 20.7732 11.7174 20.6798 11.5174 20.4798C11.1307 20.0932 11.1307 19.4532 11.5174 19.0665L14.5841 15.9998L11.5174 12.9332C11.1307 12.5465 11.1307 11.9065 11.5174 11.5198C11.9041 11.1332 12.5441 11.1332 12.9307 11.5198L15.9974 14.5865L19.0641 11.5198C19.4507 11.1332 20.0907 11.1332 20.4774 11.5198C20.8641 11.9065 20.8641 12.5465 20.4774 12.9332L17.4107 15.9998L20.4774 19.0665Z"
                fill="#CBCBCB"
              />
            </svg>
            <Link to="/">
              <div>Home</div>
            </Link>
            <Link to="/about?activeTab=Overview">
              <div>About Us</div>
            </Link>
            <Link to="/services?activeTab=Payment%20Processing">
              <div>Services</div>
            </Link>
            <Link to="/careers">
              <div>Careers</div>
            </Link>
            <Link to="/news-and-insight">
              <div>Blog (News & Insights)</div>
            </Link>
            <Link to="/contact">
              <div>Contact Us</div>
            </Link>
            <a
              href="https://sandbox.up-ng.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div>Sandbox</div>
            </a>
          </div>
        </MobileNav>
      )}
    </>
  );
}

const MobileNav = styled.div`
  transition: all 0.2s;
  background-color: white;
  width: 100%;
  position: fixed;
  overflow: hidden;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 900;

  div {
    margin: 16px 0;
  }
`;
