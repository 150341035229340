import AppInput from "components/AppInput";
import Article from "components/aritcle";
import ArticleBig from "components/aritcleBig";
import SectionHeader from "components/sectionHeader";
import React, { useState } from "react";
//import TabHome from "./components/Tab";
import { articles, articlesBig } from "./utils/data";
import { useNavigate } from "react-router-dom";

export default function NewsPage() {
  //const [active, setActive] = useState("All");
  const [email, setEmail] = useState();

  const [res, setRes] = useState();
  const [color, setColor] = useState();

  const [loading, setLoading] = useState();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    Message: `${email}`,
    address: "helpdesk@up-ng.com",
    Subject: `Newsletter Subscription`,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const sendMail = () => {
    setRes("");
    setColor("");
    setLoading(true);
    fetch(`http://89.38.135.41:3001/api/v1/payarena`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setRes("Email Sent");
        setColor("green");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRes("An error occurred, try again");
        setColor("red");
        setLoading(false);
      });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="w-[40%] mx-auto">
          <SectionHeader
            title="Our Blog"
            subHeading="News and Insights"
            description={
              <>
                <p>
                  Subscribe to learn about new product features, the latest in
                  payment solutions, and updates.
                </p>
              </>
            }
          />
          <div className="md:flex mt-[4%]">
            <AppInput
              placeholder="Enter your email"
              onChange={(e) => {
                setEmail(e.target.value);
                setRes("");
                setColor("");
              }}
            />
            &nbsp; &nbsp;
            <button
              onClick={() => {
                sendMail();
              }}
              className="form_btn w-full md:w-fit text-center"
            >
              {loading ? "sending..." : "Subscribe"}
            </button>
          </div>
          <div
            style={{
              color: color,
            }}
          >
            {res}
          </div>

          <div className="mt-[20%]">
            <SectionHeader subHeading="Trending topics" />
          </div>
        </div>
        <div className="lg:max-w-[66%] w-[90%] mx-auto mt-[6%]">
          <div className="grid md:grid-cols-2 md:gap-[6%] gap-6">
            <div className="col-span-1">
              <ArticleBig
                title={articlesBig.title}
                desc={articlesBig.desc}
                link={articlesBig.link}
                Image={articlesBig?.image}
                onClick={() => {
                  localStorage.setItem("content", JSON.stringify(articlesBig));
                  navigate(`/news-and-insight/blog`);
                }}
              />
            </div>
            <div className="col-span-1">
              {articles?.slice(0, 3)?.map((item) => {
                return (
                  <div
                    onClick={() => {
                      localStorage.setItem("content", JSON.stringify(item));
                      navigate(`/news-and-insight/blog`);
                    }}
                  >
                    <div className="flex lg:flex-row flex-col mb-4 items-center">
                      <img
                        src={item?.image}
                        alt=""
                        className="rounded-lg w-[50%] lg:mr-[4%] lg:mb-0 mb-4"
                      />
                      <div>
                        <div className="text-[#98A2B3] lg:text-[16px] text-[18px] font-normal">
                          {item?.title}
                        </div>
                        <div className="lg:text-[16px] text-[14px] md:my-[6%] my-[10px] font-medium">
                          {item?.desc}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] w-[90%] mx-auto mt-[6%]">
          <div className="text-[30px] text-center font-medium my-[8%]">
            Latest Posts
          </div>
          {/*           <TabHome active={active} setActive={setActive} />
           */}{" "}
          <div className="grid lg:grid-cols-2 gap-10 lg:mt-[6%] mt-[10px]">
            {articles.map((item) => {
              return (
                <Article
                  title={item.title}
                  desc={item.desc}
                  link={item?.link}
                  Image={item?.image}
                  onClick={() => {
                    localStorage.setItem("content", JSON.stringify(item));
                    navigate(`/news-and-insight/blog`);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
