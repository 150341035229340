import React from "react";
import { subsidiaryList } from "../utils/data";
import ReadArrowiIcon from "assets/icons/readArrow";

export default function Subsidiary() {
  return (
    <div>
      <div className="md:text-[30px] text-[18px] text-center font-medium mb-20">
        Subsidiaries{" "}
      </div>
      {subsidiaryList.map((item) => {
        return (
          <div className="relative">
            <div
              className={`flex justify-between items-center ${
                item.reverse && "md:flex-row-reverse flex-col"
              } md:flex-row flex-col  items-start mb-40`}
            >
              <div className="md:w-[45%] w-full">
                <h3 className="  lg:text-[26px] font-semibold mb-4">
                  {item.title}
                </h3>

                <p className="my-10 lg:text-[14px] text-[14px] md:leading-[190%] text-[#7A7D9C]">
                  {item.desc}
                </p>
                <a href={item?.link} target="_blank" rel="noreferrer">
                  <div className="flex items-center text-primary md:text-[14px] text-[10px]">
                    Know all about it &nbsp; <ReadArrowiIcon />
                  </div>
                </a>
              </div>
              <div className="md:w-[50%] w-full mt-0">
                <img className="w-[100%] rounded-lg" src={item.image} alt="" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
