import React from "react";

export default function SectionHeader({
  title,
  subHeading,
  description,
  weight = "medium",
}) {
  return (
    <div className="text-center w-100%">
      {title && (
        <div className="text-primary md:text-[18px] text-[10px] font-medium">
          {title}
        </div>
      )}
      <div className={`my-[1.6%] md:text-[37px] text-[18px] font-${weight}`}>
        {subHeading}
      </div>
      {description && (
        <div className="md:text-[20px] text-[1.2vw] text-mainGray font-normal">
          {description}
        </div>
      )}
    </div>
  );
}
