import React from "react";

export default function TabHome({ active, setActive, tab }) {
  return (
    <div class="mb-[1.2vw] border-b grid grid-cols-3 gap-4 md:w-[100%] w-[90%] mx-auto">
      {tab.map((item) => {
        return (
          <div
            onClick={() => {
              setActive(item);
            }}
            class={`pb-3 border-b-8 text-[20px] flex items-center ${
              active?.title === item.title
                ? "border-primary text-black"
                : "border-transparent text-[#667085]"
            }  cursor-pointer`}
          >
            <item.icon className="mr-[4%] w-10 h-10" />
            <span
              dangerouslySetInnerHTML={{ __html: item?.title }}
              className="md:w-fit w-[320px] md:text-[22px] text-[1.5vw]"
            ></span>
          </div>
        );
      })}
    </div>
  );
}
