import React from "react";
import ReadArrowiIcon from "assets/icons/readArrow";

export default function ArticleBig({ date, title, desc, onClick, Image }) {
  return (
    <div className="bg-white rounded-2xl overflow-hidden shadow-sm h-full">
      <img src={Image} alt="" srcset="" className="w-full" />
      <div className="flex flex-col justify-between items-start md:p-[6%] p-[10px]">
        <div>
          <div className="text-[#98A2B3] text-[0.8vw] font-normal">{date}</div>
          <div className="md:text-[30px] text-[24px] md:my-[4%] my-[6px] font-medium">
            {title}
          </div>
          <div className="text-[#98A2B3] md:text-[20px] text-[20px] font-normal">
            {desc}
          </div>
        </div>
        <div onClick={onClick} className="cursor-pointer">
          <div className="flex items-center text-primary md:mt-[40%] mt-[10px]  text-[16px]">
            Read post &nbsp; <ReadArrowiIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
