import React from "react";

export default function CircleImage(props) {
  return (
    <svg
      width="870"
      height="869"
      viewBox="0 0 870 869"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.736328 434.459C0.736328 194.519 195.246 0.00976562 435.186 0.00976562C675.125 0.00976562 869.635 194.519 869.635 434.459C869.635 674.399 675.125 868.908 435.186 868.908C195.246 868.908 0.736328 674.399 0.736328 434.459Z"
        fill="#F3F5FF"
      />
    </svg>
  );
}
