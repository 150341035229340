import React from "react";

export default function CardActiveIcon(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.8995 25.586C36.0571 25.5778 39.2102 25.8252 42.328 26.3258C40.8442 20.1214 38.3005 15.4326 35.3329 13.7432C34.5359 13.6669 33.7241 13.6309 32.9037 13.6309C32.0834 13.6309 31.2737 13.6733 30.4745 13.7432C27.5069 15.439 24.9633 20.1235 23.4795 26.3258C26.5944 25.8258 29.7447 25.5784 32.8995 25.586Z"
        fill="white"
      />
      <path
        d="M32.8993 51.7827C29.741 51.7909 26.5872 51.5435 23.4688 51.043C24.9525 57.2474 27.4962 61.9362 30.4638 63.6256C31.2608 63.7019 32.0727 63.7379 32.893 63.7379C33.7133 63.7379 34.523 63.6955 35.3222 63.6256C38.2898 61.9298 40.8334 57.2452 42.3172 51.043C39.203 51.543 36.0534 51.7904 32.8993 51.7827Z"
        fill="white"
      />
      <path
        d="M42.8408 28.744C36.2652 27.5697 29.5335 27.5697 22.9579 28.744C21.7836 35.3189 21.7836 42.0499 22.9579 48.6248C26.2383 49.2181 29.5657 49.5132 32.8993 49.5066C36.2329 49.5131 39.5603 49.218 42.8408 48.6248C43.4323 45.3449 43.7267 42.0183 43.7204 38.6855C43.7268 35.3519 43.4324 32.0246 42.8408 28.744Z"
        fill="white"
      />
      <path
        d="M57.8463 41.1134C57.9226 40.3163 57.9586 39.5024 57.9586 38.6842C57.9586 37.866 57.9163 37.0541 57.8463 36.2529C56.1505 33.2853 51.466 30.7416 45.2637 29.2578C45.7596 32.376 46.0034 35.529 45.9929 38.6863C45.9992 41.8439 45.7511 44.997 45.251 48.1148C51.466 46.6246 56.1505 44.0809 57.8463 41.1134Z"
        fill="white"
      />
      <path
        d="M20.5418 48.1076C19.5512 41.8581 19.5512 35.4917 20.5418 29.2422C14.331 30.726 9.6486 33.2696 7.95919 36.2372C7.88076 37.0342 7.84473 37.8482 7.84473 38.6685C7.84473 39.4889 7.88712 40.2986 7.96343 41.0977C9.6486 44.0802 14.331 46.6238 20.5418 48.1076Z"
        fill="white"
      />
      <path
        d="M44.7907 50.5742C43.9966 55.0075 42.2048 59.2022 39.5508 62.841C43.7316 61.6806 47.5411 59.4581 50.6088 56.3897C53.6765 53.3212 55.8981 49.5112 57.0575 45.3301C53.419 47.9856 49.2243 49.7788 44.7907 50.5742Z"
        fill="white"
      />
      <path
        d="M21.008 50.5759C16.5741 49.7837 12.379 47.9917 8.74121 45.3359C9.90161 49.5168 12.1241 53.3263 15.1925 56.394C18.261 59.4617 22.071 61.6832 26.2521 62.8426C23.5957 59.2047 21.8024 55.0098 21.008 50.5759Z"
        fill="white"
      />
      <path
        d="M21.008 26.7941C21.8011 22.3605 23.593 18.1657 26.2479 14.5273C22.067 15.6877 18.2576 17.9102 15.1899 20.9787C12.1221 24.0471 9.9006 27.8571 8.74121 32.0383C12.3791 29.3819 16.574 27.5885 21.008 26.7941Z"
        fill="white"
      />
      <path
        d="M44.7912 26.7941C49.2248 27.5872 53.4197 29.3792 57.058 32.034C55.8974 27.8529 53.6746 24.0433 50.6058 20.9755C47.5369 17.9078 43.7265 15.6864 39.5449 14.5273C42.2027 18.1647 43.9968 22.3598 44.7912 26.7941Z"
        fill="white"
      />
      <path
        d="M25.502 70.3982H63.9408C65.0555 70.3965 66.124 69.9528 66.9119 69.1644C67.6999 68.3761 68.1431 67.3073 68.1442 66.1927V41.3242C68.1431 40.2098 67.6999 39.1413 66.9118 38.3532C66.1238 37.5652 65.0553 37.122 63.9408 37.1208H63.1268L55.2712 23.3957C54.9066 22.7573 54.3797 22.2265 53.7439 21.8573C53.1081 21.488 52.386 21.2933 51.6507 21.293H51.5151C50.8296 21.3161 50.1608 21.5099 49.5692 21.8568L16.5992 41.0487C15.641 41.6109 14.9433 42.5283 14.6576 43.6019C14.3718 44.6754 14.5211 45.8184 15.073 46.7825L21.2986 57.6566V66.1927C21.2997 67.3073 21.7429 68.3761 22.5308 69.1644C23.3188 69.9528 24.3873 70.3965 25.502 70.3982ZM61.2551 37.1187H44.4967L57.0645 29.8015L61.2551 37.1187ZM54.8261 25.8843L56.2569 28.3855L41.2684 37.1145H35.5452L54.8261 25.8843ZM50.3874 23.2558C50.674 23.0867 50.9916 22.9767 51.3215 22.9323C51.6513 22.8879 51.9867 22.9099 52.3078 22.9972C52.6334 23.0837 52.9384 23.2344 53.2048 23.4404C53.4713 23.6464 53.6939 23.9037 53.8595 24.197L54.0163 24.4704L53.745 24.6336L32.3105 37.1187H26.5873L50.3874 23.2558ZM22.9244 41.3242C22.925 40.6406 23.1966 39.9851 23.6799 39.5015C24.1631 39.0179 24.8183 38.7457 25.502 38.7445H63.9408C64.6245 38.7457 65.2797 39.0179 65.7629 39.5015C66.2461 39.9851 66.5178 40.6406 66.5184 41.3242V66.1927C66.5178 66.8763 66.2461 67.5318 65.7629 68.0154C65.2797 68.499 64.6245 68.7712 63.9408 68.7724H25.502C24.8183 68.7712 24.1631 68.499 23.6799 68.0154C23.1966 67.5318 22.925 66.8763 22.9244 66.1927V41.3242ZM16.4805 45.9643C16.1461 45.3733 16.057 44.6748 16.2322 44.0188C16.4074 43.3628 16.8329 42.8017 17.4174 42.4562L21.4449 40.1096L21.3431 40.8558C21.3172 41.0107 21.3024 41.1672 21.2986 41.3242V43.5287L16.6352 46.242L16.4805 45.9643ZM17.445 47.66L21.2986 45.4174V48.7432L18.8779 50.1549L17.445 47.66ZM19.6834 51.573L21.2986 50.6319V54.3923L19.6834 51.573Z"
        fill="#F58634"
      />
      <path
        d="M44.7214 61.9902H28.8935C28.6851 62.0009 28.4888 62.0912 28.345 62.2425C28.2012 62.3937 28.1211 62.5945 28.1211 62.8031C28.1211 63.0118 28.2012 63.2126 28.345 63.3638C28.4888 63.5151 28.6851 63.6054 28.8935 63.6161H44.7214C44.9298 63.6054 45.1262 63.5151 45.27 63.3638C45.4137 63.2126 45.4939 63.0118 45.4939 62.8031C45.4939 62.5945 45.4137 62.3937 45.27 62.2425C45.1262 62.0912 44.9298 62.0009 44.7214 61.9902Z"
        fill="#F58634"
      />
      <path
        d="M62.7305 60.2812H54.5421C53.6219 60.2812 52.876 61.0272 52.876 61.9473C52.876 62.8675 53.6219 63.6134 54.5421 63.6134H62.7305C63.6507 63.6134 64.3966 62.8675 64.3966 61.9473C64.3966 61.0272 63.6507 60.2812 62.7305 60.2812Z"
        fill="#F58634"
      />
    </svg>
  );
}
