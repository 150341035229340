import SectionHeader from "components/sectionHeader";
import React /* useState */ from "react";

export default function LocatePage() {
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="max-w-[66%] w-[90%] mx-auto">
          <SectionHeader
            title="Locate Us"
            subHeading="Discover our whereabouts: Navigate seamlessly to our doorstep with pinpoint precision."
            description="Your guide to finding our exact location with ease, ensuring you arrive hassle-free to experience what we have to offer"
          />

          <div className="md:h-[700px] h-420px mt-16 rounded-lg overflow-hidden">
            <iframe
              title="map"
              width="100%"
              height="100%"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=03, Idowu Taylor street, Victoria Island, Lagos, Nigeria., Lagos&t=&z=14&ie=UTF8&iwloc=&output=embed"
            ></iframe>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

//https://lottie.host/ea5fcd35-d245-4a57-9c6a-2520055d0762/EUP2FlyO9y.json
//https://lottie.host/d16fd47a-aa19-46dc-aceb-b125cbf858d5/4Fw1ErGxBW.json
//https://lottie.host/5849740a-4b1c-49f6-9dd2-66089f6b7b40/Wbb4ZTTsqz.json
//https://lottie.host/105214a7-fd4b-40d5-8375-c48441cdb786/bugkoIKm4x.lottie
