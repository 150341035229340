import React from "react";

export default function FlexibleIcon(props) {
  return (
    <svg
      width="91"
      height="92"
      viewBox="0 0 91 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0664062"
        y="0.800781"
        width="90.3127"
        height="90.3127"
        rx="26.2153"
        fill="#00AFEF"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.2244 72.5503C30.5437 72.5503 18.6289 60.6621 18.6289 45.9548C18.6289 31.2741 30.5437 19.3594 45.2244 19.3594C59.9317 19.3594 71.8198 31.2741 71.8198 45.9548C71.8198 60.6621 59.9317 72.5503 45.2244 72.5503ZM53.7119 55.8221C54.0311 56.0083 54.3768 56.1147 54.7492 56.1147C55.4141 56.1147 56.0789 55.7689 56.4513 55.1306C57.0098 54.1998 56.7172 52.9764 55.7598 52.3913L46.2918 46.7531V34.466C46.2918 33.3489 45.3876 32.4713 44.2972 32.4713C43.2067 32.4713 42.3025 33.3489 42.3025 34.466V47.8967C42.3025 48.5882 42.6748 49.2264 43.2865 49.5988L53.7119 55.8221Z"
        fill="#00AFEF"
      />
    </svg>
  );
}
