import React, { useState } from "react";

export default function AppInput({
  name,
  type,
  onChange,
  placeholder,
  defaultValue,
}) {
  const [readOnly, setReadOnly] = useState(true);

  return (
    <div className="w-full outline-none border-[2px] border-[#66708521] rounded-[8px] bg-white">
      <div>
        <input
          readOnly={readOnly}
          onFocus={() => setReadOnly(false)}
          onBlur={() => setReadOnly(true)}
          autocomplete="off"
          type={type}
          name={name}
          onChange={onChange}
          required
          defaultValue={defaultValue}
          placeholder={placeholder}
          className="w-full h-full appearance-none outline-none border-none bg-none md:p-[16px] py-[8px] px-[12px] md:text-[22px] text-[12px] md:rounded-[20px] rounded-md placeholder:text-[#667085]"
        />
      </div>
    </div>
  );
}
