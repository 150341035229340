import React from "react";

export default function Arrow2({ fill = "white" }) {
  return (
    <>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="md:block hidden"
      >
        <path
          d="M11.2374 6.60925L6.32343 11.5232M1 6.60925H11.2374H1ZM11.2374 6.60925L6.32343 1.69531L11.2374 6.60925Z"
          stroke={fill}
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        width="8"
        height="8"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="md:hidden visible"
      >
        <path
          d="M11.2374 6.60925L6.32343 11.5232M1 6.60925H11.2374H1ZM11.2374 6.60925L6.32343 1.69531L11.2374 6.60925Z"
          stroke={fill}
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
