import React, { useEffect } from "react";
import { Toaster, toast } from "sonner";
import DownloadAndroidIcon from "assets/icons/downloadAndroid";
import DownloadAppleIcon from "assets/icons/downloadApple";

// Custom hook to detect screen size
function useMediaQuery(query) {
  const [matches, setMatches] = React.useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export default function DownloadComponent({
  appName = "Payarena",
  appleLink,
  androidLink,
}) {
  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  const handleClick = (e, link) => {
    if (isLargeScreen) {
      e.preventDefault();
      toast.error("Visit Up-ng.com on a mobile browser to download the mobile app");
    } else {
      window.open(link, "_blank", "noreferrer");
    }
  };

  return (
    <div className="text-[#797979] grid place-items-center font-medium w-[100%] h-full md:px-[12%] md:p-0 p-[24px] rounded-2xl bg-[#BCBEC033] border-r-4 border-l-4 border-bgPrimary">
      <div>
        <div className="md:text-[18px] text-[14px] text-primary200 font-semibold">
          Download
        </div>
        <div className="text-black md:text-[20px] text-[16px] font-semibold mt-[3%]">
          Download {appName}
        </div>
        <div className="text-black md:text-[20px] text-[16px] font-semibold mb-[3%]">
          Mobile today
        </div>
        <div className="md:text-[18px] text-[14px] md:mb-[3%] mb-4">
          Start using the best and latest tools from us on your mobile.
        </div>

        <div className="flex justify-between">
          <a
            className="max-w-[66%] w-[90%]"
            href={appleLink}
            onClick={(e) => handleClick(e, appleLink)}
          >
            <DownloadAppleIcon width="100%" />
          </a>
          &nbsp; &nbsp;
          <a
            className="max-w-[66%] w-[90%]"
            href={androidLink}
            onClick={(e) => handleClick(e, androidLink)}
          >
            <DownloadAndroidIcon width="100%" />
          </a>
        </div>
      </div>
    </div>
  );
}

