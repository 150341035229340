import React from "react";

export default function LocationIcon(props) {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12.4164 17.0002H10.5831V15.1668H12.4164V17.0002ZM8.74972 14.2502V17.9168C8.74972 18.421 9.16222 18.8335 9.66639 18.8335H13.3331C13.8372 18.8335 14.2497 18.421 14.2497 17.9168V14.2502C14.2497 13.746 13.8372 13.3335 13.3331 13.3335H9.66639C9.16222 13.3335 8.74972 13.746 8.74972 14.2502ZM17.9164 9.02516V5.08349C17.9164 4.57932 17.5039 4.16682 16.9997 4.16682H16.0831C15.5789 4.16682 15.1664 4.57932 15.1664 5.08349V6.55016L12.1139 3.80016C11.7656 3.48849 11.2339 3.48849 10.8856 3.80016L3.22222 10.7027C2.91056 10.9777 3.10306 11.5002 3.52472 11.5002H4.72556C4.95472 11.5002 5.17472 11.4177 5.33972 11.2618L11.4997 5.71599L17.6506 11.2618C17.8247 11.4177 18.0447 11.5002 18.2647 11.5002H19.4656C19.8872 11.5002 20.0889 10.9777 19.7681 10.7027L17.9164 9.02516Z"
          fill="#E66500"
        />
      </svg>
    </>
  );
}
