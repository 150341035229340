import SectionHeader from "components/sectionHeader";
import React, { useEffect } from "react";
import Arrow2 from "assets/icons/arrow2";
import RemoteIcon from "./icons/remote";
import FlexibleIcon from "./icons/flexible";
import UnlimitedIcon from "./icons/unlimited";
import CultureIcon from "./icons/culture";
import CareerIcon from "./icons/career";
import InsuranceIcon from "./icons/insurance";
/* import { Link } from "react-router-dom";
import ReadArrowIcon2 from "assets/icons/readArrow2"; */
import CircleImage from "assets/images/circle";
import ImageC from "assets/images/career.png";

export default function CareerPage() {
  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/jsx";
    script.innerHTML = document.getElementById("SH_Embed").innerHTML =
      window.SH_Embed.pull({
        key: "eyJpdiI6IithNGZFMWM2azRmeHlFU2Nqd0tiYlE9PSIsInZhbHVlIjoidmVrRkwzWmdLNldkaTRGa080WjZmMDJhZ2E1MHBpVW1DRWRCZ3dBTDNjcmZoYWk5Y2V0L2RhN1g0dXlPNWZWVUlFdjBIVGVFRFZJRHd0SVUycmwzenc9PSIsIm1hYyI6IjZlN2Y3NWVjZmQxMzhiMTBhZDUzM2Y1MjU1NjcyYjNiMDU1ZDg4MzhkYTY2ZmMyMTYzZDRkMzJlMWY5MmU0NTciLCJ0YWciOiIifQ==",
        base_url: "https://up-ng.seamlesshiring.com/",
      });

    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="max-w-[66%] w-[90%] mx-auto">
          <SectionHeader
            title="Careers"
            subHeading="We are UPturning the norm and UPscaling the industry. Join us!"
            description={
              <>
                <p>
                  {" "}
                  We understand that people drive everything, so we invest in
                  our people and empower them to deliver cutting-edge technology
                  and professional services that power the industry and enable
                  commerce. If you are excited by innovation, eager to
                  contribute to change, and committed to excellence, you would
                  fit perfectly here. Find Open Roles
                </p>
              </>
            }
          />
          <a href="#roles">
            <button className="main_btn2 mt-[4%] mx-auto">
              See open roles &nbsp; &nbsp;
              <Arrow2 />{" "}
            </button>
          </a>
        </div>
        <div className="max-w-[50%] w-[90%] mx-auto mt-[4%] mb-[8%]">
          <img src={ImageC} alt="" className="w-[90%]" />
        </div>
        <div className="w-[40%] mx-auto">
          <SectionHeader subHeading="Why Unified Payments?" />
        </div>
        <div className="w-[100%] relative mt-[4%] py-[0.1%] bg-bgPrimary">
          <CircleImage className="absolute w-[70%] h-[70%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-[0]" />
          <div className="max-w-[66%] w-[90%] relative z-10 mx-auto my-[8%] grid md:grid-cols-3 md:gap-[6%] gap-10">
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <FlexibleIcon />

              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Pace setter organization
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px]  text-[#7A7D9C]">
                  Join the organization at the forefront of advancement in the
                  payment technology industry.
                </div>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <RemoteIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Competitive Benefits
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px] text-[#7A7D9C]">
                  Regardless of the times, enjoy renumeration and benefits that
                  keep you motivated.
                </div>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <UnlimitedIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Learning & Development
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px] text-[#7A7D9C]">
                  Grow your competencies and improve your skills with training
                  opportunities that are tailored to you.
                </div>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <InsuranceIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Medical Insurance
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px] text-[#7A7D9C]">
                  Enjoy responsive and comprehensive media coverage for you and
                  your dependents.
                </div>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <CareerIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Career growth
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px] text-[#7A7D9C]">
                  Turn your aspirations to a reality in a fast-paced
                  organization that rewards efforts with growth.
                </div>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-10 bg-white rounded-2xl border">
              <CultureIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[20px]">
                  Great Culture
                </div>
                <div className="mt-[8%] md:text-[18px] text-[14px] md:leading-[30px] text-[#7A7D9C]">
                  Work with amazing people in an atmosphere that is
                  collaborative and productive.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="roles" className="w-[100%] py-[3%] bg-bgPrimary">
          <div className="max-w-[66%] w-[90%] mx-auto">
            <div className={`my-[1.6%] md:text-[30px] text-[22px] font-medium`}>
              All open roles
            </div>

            {/*   <div className="md:p-[2%] p-4 rounded-xl border mt-[4%]">
              <div className="md:text-[22px] text-[12px] text-[#101828]">
                Product Designer
              </div>
              <div className="text-[#667085] md:text-[18px] text-[10px] mt-[1%] mb-[3%]">
                We're looking for a mid-level designer to join our team
              </div>

              <div className="flex justify-between">
                <div className="text-[#667085] md:text-[12px] text-[8px]">
                  Remote - $80k to $100k
                </div>
                <div>
                  {" "}
                  <Link to="/careers">
                    <div className="flex items-center text-primary md:text-[14px] text-[10px] mt-[5%]">
                      More Details &nbsp; <ReadArrowIcon2 />
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            <div id="SH_Embed"></div>
          </div>
        </div>
      </div>
    </>
  );
}

//https://lottie.host/ea5fcd35-d245-4a57-9c6a-2520055d0762/EUP2FlyO9y.json
//https://lottie.host/d16fd47a-aa19-46dc-aceb-b125cbf858d5/4Fw1ErGxBW.json
//https://lottie.host/5849740a-4b1c-49f6-9dd2-66089f6b7b40/Wbb4ZTTsqz.json
//https://lottie.host/105214a7-fd4b-40d5-8375-c48441cdb786/bugkoIKm4x.lottie
