import React from "react";

export default function MailIcon(props) {
  return (
    <>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.16536 3.66602H18.832C19.8404 3.66602 20.6654 4.49102 20.6654 5.49935V16.4993C20.6654 17.5077 19.8404 18.3327 18.832 18.3327H4.16536C3.15703 18.3327 2.33203 17.5077 2.33203 16.4993V5.49935C2.33203 4.49102 3.15703 3.66602 4.16536 3.66602Z"
          stroke="#E66500"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6654 5.5L11.4987 11.9167L2.33203 5.5"
          stroke="#E66500"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
