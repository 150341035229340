import BottomNavBar from "components/bottomNavBar";
import ContactBoard from "components/contactBoard";
import TopNavBar from "components/topNavBar";
import React from "react";

export default function LandingLayout({ children }) {
  return (
    <div>
      <TopNavBar />
      <div>{children}</div>
      <ContactBoard />
      <BottomNavBar />
    </div>
  );
}
