import React from "react";
import { Link } from "react-router-dom";

export default function ContactBoard() {
  return (
    <div className="max-w-[66%] w-[90%] mx-auto bg-secondary100 grid place-items-center text-white md:py-[6%] p-6 rounded-xl text-center">
      <div>
        <div className="md:text-[30px] text-[18px] font-medium">
          Ready to innovate? Connect with us!{" "}
        </div>
        <div className="md:text-[18px] text-[16px] md:my-[3%] my-7 font-medium">
          <div>
            Let’s explore existing opportunities or develop new solutions to
            <br /> meet your business needs.{" "}
          </div>
        </div>
      </div>
      <Link to="/contact">
        <div className="flex items-center">
          <button className="contact_button">Request A Call Back</button>
        </div>
      </Link>
    </div>
  );
}
