import React from "react";

export default function ArrowIcon(props) {
  return (
    <>
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.55818 0.5L0.380859 1.67868L4.1974 5.49442L0.380859 9.31016L1.55818 10.4888L6.55103 5.49442L1.55818 0.5Z"
          fill="white"
        />
      </svg>
    </>
  );
}
