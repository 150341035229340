import React from "react";

export default function SupportIcon(props) {
  return (
    <svg
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.400391"
        width="86.126"
        height="86.126"
        rx="25"
        fill="#00AFEF"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4258 43.0991C19.4258 30.4363 29.5456 19.0254 43.5114 19.0254C57.1648 19.0254 67.501 30.2197 67.501 43.0269C67.501 57.8803 55.386 67.1006 43.4634 67.1006C39.5212 67.1006 35.1464 66.0413 31.6369 63.971C30.411 63.2247 29.3773 62.671 28.0553 63.1043L23.1997 64.5488C21.9738 64.9339 20.868 63.971 21.2286 62.671L22.8391 57.2785C23.1035 56.5322 23.0555 55.7378 22.6709 55.1119C20.6036 51.3082 19.4258 47.1435 19.4258 43.0991ZM40.3379 43.0992C40.3379 44.8084 41.708 46.1806 43.4147 46.2047C45.1214 46.2047 46.4915 44.8084 46.4915 43.1232C46.4915 41.414 45.1214 40.0418 43.4147 40.0418C41.7321 40.0177 40.3379 41.414 40.3379 43.0992ZM51.42 43.1232C51.42 44.8084 52.7901 46.2046 54.4968 46.2046C56.2035 46.2046 57.5736 44.8084 57.5736 43.1232C57.5736 41.414 56.2035 40.0418 54.4968 40.0418C52.7901 40.0418 51.42 41.414 51.42 43.1232ZM32.3327 46.2046C30.65 46.2046 29.2559 44.8083 29.2559 43.1232C29.2559 41.4139 30.626 40.0417 32.3327 40.0417C34.0393 40.0417 35.4095 41.4139 35.4095 43.1232C35.4095 44.8083 34.0393 46.1805 32.3327 46.2046Z"
        fill="#00AFEF"
      />
    </svg>
  );
}
