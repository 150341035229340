import React from "react";

export default function PCIIcon(props) {
  return (
    <>
      <svg
        width="25"
        height="28"
        viewBox="0 0 25 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M24.1739 4.27879C24.1256 3.93067 23.8762 3.64431 23.5391 3.5502L12.7354 0.532294C12.5814 0.489235 12.4187 0.489235 12.2646 0.532294L1.46085 3.5502C1.12376 3.64431 0.874401 3.93055 0.826145 4.27879C0.763482 4.73138 -0.666044 15.4251 3.00059 20.7449C6.6629 26.0583 12.0656 27.42 12.2937 27.4754C12.3616 27.4919 12.4307 27.5 12.5 27.5C12.5693 27.5 12.6384 27.4918 12.7063 27.4754C12.9345 27.42 18.3372 26.0583 21.9994 20.7449C25.666 15.4252 24.2365 4.7315 24.1739 4.27879ZM19.467 10.5219L12.0978 17.9239C11.9263 18.0962 11.7014 18.1824 11.4767 18.1824C11.2519 18.1824 11.027 18.0963 10.8555 17.9239L6.29922 13.3473C6.13442 13.1819 6.04189 12.9574 6.04189 12.7234C6.04189 12.4894 6.13454 12.265 6.29922 12.0995L7.2039 11.1908C7.54697 10.8464 8.1032 10.8462 8.44614 11.1908L11.4767 14.2349L17.3201 8.36529C17.4848 8.19976 17.7083 8.10681 17.9412 8.10681C18.1742 8.10681 18.3977 8.19976 18.5623 8.36529L19.467 9.274C19.8101 9.61859 19.8101 10.1773 19.467 10.5219Z"
          fill="#188D18"
        />
      </svg>
    </>
  );
}
