import React from "react";

export default function ManagementIcon(props) {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.875" width="52" height="52" rx="13" fill="#F58634" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.3991 10.3355C27.6546 10.1061 27.9926 9.9859 28.3386 10.0013C35.6519 10.2202 41.7639 15.5293 42.8716 22.625C42.8787 22.6674 42.8787 22.7106 42.8716 22.7529C42.8955 23.0887 42.782 23.4199 42.5563 23.6733C42.3306 23.9268 42.0113 24.0816 41.669 24.1034L29.3818 24.9137C28.9755 24.9498 28.5725 24.8161 28.2719 24.5455C27.9712 24.2749 27.8008 23.8924 27.8025 23.4922L26.9766 11.4229V11.2239C26.9916 10.8844 27.1436 10.5648 27.3991 10.3355ZM26.5544 28.0701L37.2043 27.3877L37.2767 27.4162C37.734 27.4237 38.1696 27.6091 38.4875 27.9317C38.8055 28.2543 38.9797 28.6876 38.972 29.1363C38.5523 35.2516 34.0688 40.3612 27.9674 41.6776C21.866 42.9939 15.6113 40.2011 12.6154 34.8226C11.7345 33.2735 11.1779 31.5674 10.9781 29.8044C10.9007 29.282 10.8667 28.7543 10.8767 28.2265C10.8969 21.7237 15.5266 16.1109 22.0047 14.7356C22.7889 14.5889 23.5752 14.9804 23.9173 15.6881C24.0025 15.8165 24.0707 15.9551 24.1202 16.1004C24.2416 17.9749 24.3676 19.8316 24.493 21.6806C24.5921 23.1408 24.6908 24.5961 24.7867 26.0515C24.7817 26.3943 24.8356 26.7355 24.9461 27.0608C25.2061 27.7008 25.8532 28.1069 26.5544 28.0701Z"
        fill="white"
      />
    </svg>
  );
}
