import React from "react";

export default function MailOutIcon(props) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.865234"
        y="0.865234"
        width="59.7316"
        height="59.7316"
        rx="12.4441"
        fill="#F58220"
      />
      <path
        d="M20.7759 40.6859C20.0915 40.6859 19.5058 40.4424 19.0188 39.9555C18.531 39.4677 18.2871 38.8815 18.2871 38.1971V23.2642C18.2871 22.5798 18.531 21.9941 19.0188 21.5071C19.5058 21.0193 20.0915 20.7754 20.7759 20.7754H40.6865C41.3709 20.7754 41.957 21.0193 42.4448 21.5071C42.9318 21.9941 43.1753 22.5798 43.1753 23.2642V38.1971C43.1753 38.8815 42.9318 39.4677 42.4448 39.9555C41.957 40.4424 41.3709 40.6859 40.6865 40.6859H20.7759ZM30.7312 31.7573C30.8349 31.7573 30.9436 31.7415 31.0572 31.71C31.1717 31.6793 31.2808 31.6329 31.3845 31.5706L40.1887 26.0641C40.3546 25.9604 40.4791 25.831 40.562 25.6759C40.645 25.5199 40.6865 25.3486 40.6865 25.1619C40.6865 24.7471 40.5102 24.436 40.1576 24.2286C39.805 24.0212 39.4421 24.0316 39.0687 24.2597L30.7312 29.4862L22.3937 24.2597C22.0203 24.0316 21.6574 24.0262 21.3048 24.2436C20.9522 24.4617 20.7759 24.7679 20.7759 25.1619C20.7759 25.3693 20.8174 25.5506 20.9004 25.7057C20.9833 25.8617 21.1078 25.9812 21.2737 26.0641L30.0779 31.5706C30.1816 31.6329 30.2907 31.6793 30.4052 31.71C30.5188 31.7415 30.6275 31.7573 30.7312 31.7573Z"
        fill="white"
      />
    </svg>
  );
}
