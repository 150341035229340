import React from "react";

import CheckIcon from "assets/icons/check";
import ReadArrowIcon2 from "assets/icons/readArrow2";
import { Link } from "react-router-dom";
export default function ServiceComponent({
  title,
  desc,
  list,
  link,
  linkText,
  image,
  tag,
  item,
}) {
  return (
    <div className="mb-[10%]" id={tag}>
      <div className="bg-[#F2F4F7] rounded-lg w-full px-[15%] py-[6%]">
        <img src={image} alt="" className="w-full" />
      </div>
      <div className="w-[70%]">
        <div className="md:text-[26px] text-[14px]  font-medium text-mainGray mt-[4%]">
          {title}
        </div>
        <div className="md:text-[16px] text-[10px]  font-medium text-mainGray mt-[4%]">
          {desc}
        </div>
        {list.map((item) => {
          return (
            <div className="flex items-center text-mainGray mt-[4%]">
              <CheckIcon width="3%" height="3%" />
              <div className="md:text-[12px] text-[8px]  font-medium ml-[1%]">
                {item}
              </div>
            </div>
          );
        })}

        <Link
          to={link}
          onClick={() => {
            localStorage.setItem("details", JSON.stringify(item));
          }}
        >
          <div className="flex items-center text-primary md:text-[16px] text-[8px]  mt-[5%]">
            {linkText} &nbsp; <ReadArrowIcon2 />
          </div>
        </Link>
      </div>
    </div>
  );
}
