import React from "react";
import logo from "assets/icons/logo2.webp";
import Payment from "assets/icons/payments.png";
import PCIIcon from "assets/icons/pci";
import MailIcon from "assets/icons/mail";
import PhoneIcon from "assets/icons/phone";
import LocationIcon from "assets/icons/location";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Scrollbar,
  A11y,
  Pagination,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DownloadComponent from "./downloadComponent";

export default function BottomNavBar() {
  const apps = [
    {
      name: "Payarena",
      androidLink:
        "https://play.google.com/store/search?q=payarena+mobile+app&c=apps",
      appleLink: "https://apps.apple.com/us/app/payarena/id1637449009",
    },
    {
      name: "Payattitude",
      androidLink:
        "https://play.google.com/store/search?q=payattitude+app&c=apps",
      appleLink:
        "https://apps.apple.com/us/app/payattitude-digital/id1343904100",
    },
    {
      name: "Hope PSBank",
      androidLink: "https://play.google.com/store/search?q=hope+psbank&c=apps",
      appleLink: "https://hopepsbank.com/Login",
    },
  ];
  return (
    <div className="bg-bgPrimary">
      <div className="max-w-[66%] w-[90%] mx-auto md:flex  justify-between border-y py-[5%]">
        <div className="text-[#797979] font-medium md:w-[22%]">
          <img src={logo} alt="" className="md:w-[16%] w-20" />
          <div className=" py-[10%] md:text-[16px] text-[13px]">
            At UP® we are committed to digital accessibility for individuals and
            corporate organisations.
          </div>
          <img src={Payment} alt="" className="w-[100%] md:block hidden" />
          <div className="flex items-center pt-[6%]">
            <PCIIcon width="6%" height="6%" className="mr-2" />
            <span className="md:text-[15px] text-[14px]">
              PCI-DSS Compliant
            </span>
          </div>
          <a href="mailto:info@up-ng.com">
            <div className="flex items-center pt-[6%]">
              <MailIcon width="20px" height="20px" className="mr-2" />
              <span className="md:text-[15px] text-[14px]">info@up-ng.com</span>
            </div>
          </a>

          <a href="tel:+234 1 2703010">
            <div className="flex items-start pt-[6%]">
              <PhoneIcon width="20px" height="20px" className="mr-2" />
              <span className="md:text-[15px] text-[14px]">
              0201-2778930
              <br></br>0201-2272872
              <br></br>0700-UNIFIED
               <br></br>14 Fax: +234 1 2703011
              </span>
            </div>
          </a>
          <Link to="/locate">
            <div className="flex items-start pt-[6%]">
              <LocationIcon width="40px" height="40px" className="mr-2" />
              <span className="md:text-[15px] text-[14px]">
                03, Idowu Taylor street, Victoria Island, Lagos, Nigeria.
              </span>
            </div>
          </Link>
        </div>
        <div className="text-[#797979] font-medium w-[22%] md:mx-[4%] md:mt-0 mt-[14px]">
          <div className="text-black  md:text-[18px] text-[14px] font-medium md:pb-[6%] pb-[4px] border-b">
            Pages
          </div>
          <Link to="/" className="w-fit">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Home
            </div>
          </Link>
          <Link to="/about?activeTab=Overview">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              About Us
            </div>
          </Link>
          <a href={`${window.location.origin}/#who`}>
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              What We Are
            </div>
          </a>

          <Link to="/careers">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Career
            </div>
          </Link>
          <Link to="/news-and-insight">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Blog
            </div>
          </Link>
          <Link to="/news-and-insight">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              News & Insight
            </div>
          </Link>
          <Link to="/contact">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Contact Us
            </div>
          </Link>
        </div>

        <div className="text-[#797979] font-medium w-[22%] mr-[3%]">
          <div className="text-black  md:text-[18px] text-[14px] font-medium md:pb-[6%] pb-[4px]  border-b">
            Utility Pages
          </div>
          <Link to="/contact">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              FAQs
            </div>
          </Link>
          <Link to="/services?activeTab=Payment%20Processing">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Service
            </div>
          </Link>
          <a href="https://sandbox.up-ng.com/" target="_blank" rel="noreferrer">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Sandbox
            </div>
          </a>

          <Link to="/about?activeTab=Subsidiaries">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Payattitude
            </div>
          </Link>
          <Link to="/about?activeTab=Subsidiaries">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Payarena
            </div>
          </Link>
          <Link to="/about?activeTab=Subsidiaries">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Hope PS Bank
            </div>
          </Link>
          <Link to="/about?activeTab=Leadership">
            <div className="md:py-[5%] py-[4px] text-[12px] md:text-[16px] hover:text-primary200">
              Management
            </div>
          </Link>
        </div>
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Scrollbar, A11y, Pagination, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation
          pagination={{ clickable: true }}
          onSwiper={(swiper) => swiper}
          className="md:w-[34%] md:mt-0 mt-20"
          style={{
            "--swiper-navigation-size": "0px",
          }}
        >
          {apps?.map((item) => {
            return (
              <SwiperSlide>
                <DownloadComponent
                  androidLink={item?.androidLink}
                  appleLink={item?.appleLink}
                  appName={item?.name}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
