import React from "react";
import AppInput from "./AppInput";

export default function SubscribeBoard() {
  return (
    <div className="w-[100%] mx-auto py-[6%] bg-bgPrimary mt-[10%]">
      <div className="max-w-[66%] w-[90%] mx-auto bg-white grid lg:grid-cols-2 p-[4%] gap-[8%] items-start rounded-xl">
        <div>
          <div className="lg:text-[24px] text-[12px] font-medium ">
            Subscribe to our weekly newsletter{" "}
          </div>
          <div className="lg:text-[18px] text-[16px] text-mainGray font-normal">
            Stay in the loop with everything you need to know.{" "}
          </div>
        </div>
        <div className="md:flex mt-[4%]">
          <AppInput placeholder="Enter your email" />
          &nbsp; &nbsp;
          <button className="form_btn w-full md:w-fit text-center">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
}
