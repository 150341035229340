import Arrow2 from "assets/icons/arrow2";
import React from "react";
import { Link } from "react-router-dom";

export default function TabComponent({ icon, title, desc, btn, img }) {
  return (
    <div className="flex justify-between items-center md:flex-row flex-col px-[5%] py-[2%] bg-white shadow-lg rounded-xl mt-[60px]">
      <div className="flex justify-between flex-col  py-[6%] md:w-[50%] w-full  items-start">
        <icon.icon width="70px" height="70px" />
        <div className="md:text-[23px] text-[18px] mt-[4%] font-medium">
          {title}
        </div>
        <div className="my-[20px] md:text-[1vw] text-[12px] leading-relaxed text-[#667085]">
          {desc}
        </div>
        <Link to={`/services?activeTab=${title}`}>
          <button className={btn}>
            Learn More &nbsp; &nbsp;
            <Arrow2 />{" "}
          </button>
        </Link>
      </div>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <img className="w-[50%]" src={img} alt="" />
    </div>
  );
}
