import SectionHeader from "components/sectionHeader";
import React /* useState */ from "react";
//import Arrow2 from "assets/icons/arrow2";
//import Process from "assets/images/solution.png";

export default function BlogPage() {
  const blog = JSON.parse(localStorage.getItem("content"));
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="w-[70%] mx-auto">
          <SectionHeader
            title="Blog"
            subHeading={blog?.title}
            description={
              <>
                <p>{blog?.desc}</p>
              </>
            }
          />
          {/*  <button className="main_btn2 mt-[4%] mx-auto">
            Request a demo &nbsp; &nbsp;
            <Arrow2 />{" "}
          </button> */}
        </div>

        <div className="w-[50%] mx-auto mt-[2%] mb-[2%] text-[18px] text-[#676767]">
          <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
        </div>
      </div>
    </>
  );
}

//https://lottie.host/ea5fcd35-d245-4a57-9c6a-2520055d0762/EUP2FlyO9y.json
//https://lottie.host/d16fd47a-aa19-46dc-aceb-b125cbf858d5/4Fw1ErGxBW.json
//https://lottie.host/5849740a-4b1c-49f6-9dd2-66089f6b7b40/Wbb4ZTTsqz.json
//https://lottie.host/105214a7-fd4b-40d5-8375-c48441cdb786/bugkoIKm4x.lottie
