import React from "react";
import ServiceComponent from "./service";
import { services } from "../utils/data";

export default function Main({ from = 0, to = 1 }) {
  return (
    <div>
      {services?.slice(from, to).map((item) => {
        return (
          <ServiceComponent
            item={item}
            desc={item.desc}
            title={item.title}
            list={item.list}
            link={item.link}
            linkText={item.linkText}
            image={item.image}
            tag={item.tag}
          />
        );
      })}
    </div>
  );
}
