import React from "react";

export default function ArrowContactIcon(props) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2374 5.94128L6.32343 10.8552M1 5.94128H11.2374H1ZM11.2374 5.94128L6.32343 1.02734L11.2374 5.94128Z"
        stroke="#00AFEF"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
