import React from "react";
import { overview, values } from "../utils/data";

//import PioneerIcon from "assets/icons/pioneer";
import VisionIcon from "assets/icons/vision";
import MissionIcon from "assets/icons/mission";
import Values from "assets/images/valuesAbout.png";
//import ValuesHistory from "assets/images/valuesHistory.png";
import HistoryIcon1 from "assets/icons/historyIcon1";
import HistoryIcon2 from "assets/icons/historyIcon2";
import Four from "assets/upImg/aboutUs/Overview/4.jpg";

export default function Overview() {
  return (
    <div>
      {overview.map((item) => {
        return (
          <div className="relative">
            <div
              className={`flex  justify-between ${
                item.reverse && "md:flex-row-reverse flex-col"
              } md:flex-row flex-col  items-start mb-40`}
            >
              <div className="md:w-[45%] w-full">
                <h3 className="  lg:text-[26px] font-semibold mb-4">
                  {item.title}
                </h3>

                {item?.desc && (
                  <p className="my-10 lg:text-[16px] text-[14px] md:leading-[190%] text-[#7A7D9C]">
                    {item.desc}
                  </p>
                )}
                {item?.content && (
                  <p
                    className="my-10 lg:text-[16px] text-[14px] md:leading-[190%] text-[#7A7D9C]"
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                  />
                )}
              </div>
              <div className="md:w-[50%] w-full mt-0">
                <img className="w-[100%] rounded-md" src={item.image} alt="" />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-between items-start gap-5">
        <div className="bg-[#EFF9FF] p-[4%] w-full rounded-xl">
          <VisionIcon width="28%" height="28%" />
          <div className="md:text-[18px] text-[18px] mt-[8%] uppercase font-medium ">
            Our Vision
          </div>
          <div className="mt-[4%] text-[#0080C9]  md:text-[14px] text-[16px]">
            To be the most preferred e-payment service provide in Africa.
          </div>
        </div>
        <div className="bg-[#EFF9FF] p-[4%] w-full rounded-xl">
          <MissionIcon width="28%" height="28%" />
          <div className="md:text-[18px] text-[18px] mt-[8%] uppercase font-medium">
            OUR MISSION{" "}
          </div>
          <div className="mt-[4%] text-[#0080C9]  md:text-[14px] text-[16px]">
            To be Africa’s dominant and World class e-payment service provider
            by leveraging on our people, process and technology to enhance
            commerce and provide ultimate value to our stakeholders.
          </div>
        </div>
      </div>
      <div className="w-full text-center h-fit py-[10%]">
        <div className="md:text-[30px] text-[22px] font-semibold">
          Our Core Values - <span className="text-primary">P.R.I.D.E</span>
        </div>
        <div className="md:text-[18px] text-[16px] text-[#667085] font-normal my-[0.8vw]">
          Our success is built firmly on our commitment to our core values.{" "}
        </div>
        <img
          src={Values}
          className="w-[90%] mx-auto pointer-events-none select-none md:block hidden"
          alt=""
        />
        <div className="grid md:grid-cols-5 gap-4 w-full mt-10 md:mt-0">
          {values.map((item, index) => {
            return (
              <div
                className={`transform ${
                  (index === 0 && "md:translate-x-[2%]") ||
                  (index === 1 && "md:translate-x-[-2%]") ||
                  (index === 2 && "md:translate-x-[-2%]") ||
                  (index === 3 && "md:translate-x-[5%]") ||
                  (index === 4 && "md:translate-x-[-2%]")
                } w-full`}
              >
                <div className="md:text-[0.9vw] text-[14px] text-[#1D2939] font-medium my-[1vw]">
                  {item.title}{" "}
                </div>
                <div className="md:text-[0.9vw] text-[14px] text-[#667085] font-normal">
                  {item.desc}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full pt-[10%]">
        <div className="md:text-[30px] text-[18px]  text-center font-medium">
          Our History: How it all started
        </div>
        <div className="relative">
          <div
            className={`flex justify-between md;:flex-row flex-col items-center my-[8%]`}
          >
            <div className="md:w-[70%] w-full">
              <div className="flex items-start">
                <HistoryIcon1
                  width="30%"
                  height="30%"
                  className="mr-[2%] transform -translate-y-[20%]"
                />
                <div>
                  <h3 className=" md:text-[20px] text-[14px] font-semibold mb-[2%]">
                    1997 - 2000{" "}
                  </h3>

                  <p className="md:text-[20px] text-[10px] text-[#7A7D9C]">
                    Incorporated as Smartcard Nigeria Plc. We rolled out the
                    first electronic purse card solution in Nigeria, the first
                    by any Card Scheme in Nigeria. Re-branded as ValuCard
                    Nigeria Plc.{" "}
                  </p>
                </div>
              </div>

              <div className="flex items-start my-[10%]">
                <HistoryIcon2
                  width="84%"
                  height="84%"
                  className="mr-[2%] transform -translate-y-[20%]"
                />
                <div>
                  <h3 className=" md:text-[20px] text-[14px] font-semibold mb-[2%]">
                    2001 - 2008{" "}
                  </h3>

                  <p className="md:text-[20px] text-[10px]  text-[#7A7D9C]">
                    Became a Principal Member of Visa International (now Visa
                    Inc.). Was certified by Visa as being EMV-compliant and
                    commenced Visa acquiring services same year. Was certified
                    as being Payment Card Industry-Data Security Standard
                    (PCI-DSS) Compliant. Decommissioned the ValuCard e-purse
                    product. Successfully transformed from being a card scheme
                    to being a global Payment Card Industry service provider.
                    Launched the first global Internet-based money remittance
                    solution in Africa – V-Money Transfer.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <HistoryIcon1
                  width="46%"
                  height="46%"
                  className="mr-[2%] transform -translate-y-[20%]"
                />
                <div>
                  <h3 className=" md:text-[20px] text-[14px] font-semibold mb-[2%]">
                    2012 - 2017{" "}
                  </h3>

                  <p className="md:text-[20px] text-[10px]  text-[#7A7D9C]">
                    Licensed as a Payment Terminal Service Provider (PTSP) by
                    the Central Bank of Nigeria. Re-branded as Unified Payment
                    Services Limited. Launched PayAttitude Tag NFC solution.
                    Licensed as a Payment Solution Service Provider (PSSP) by
                    the Central Bank of Nigeria.
                  </p>
                </div>
              </div>

              <div className="flex items-start my-[10%]">
                <HistoryIcon2
                  width="26%"
                  height="26%"
                  className="mr-[2%] transform -translate-y-[20%]"
                />
                <div>
                  <h3 className=" md:text-[20px] text-[14px] font-semibold mb-[2%]">
                    2018 - Till date{" "}
                  </h3>

                  <p className="md:text-[20px] text-[10px]  text-[#7A7D9C]">
                    First to enable Hourly Settlement Service to Nigerian
                    merchants. First Acquirer of American Express in Nigeria.
                    Licensed as an Agency Network provider.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-[45%] w-full mt-0">
              <img
                src={Four}
                className="w-[100%] rounded-md pointer-events-none select-none"
                alt=""
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
