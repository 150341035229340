import "./App.css";
import { Route, Routes } from "react-router-dom";
import LandingLayout from "layouts/LandingLayout";
import OtherLayout from "layouts/Otherlayout";
import DefaultLayout from "layouts/DefaultLayout";
import HomePage from "pages/HomePage";
import AboutPage from "pages/AboutPage";
import ServicesPage from "pages/ServicesPage";
import NewsPage from "pages/NewsPage";
import CareerPage from "pages/CareersPage";
import ContactPage from "pages/ContactPage";
import ServiceProcessPage from "pages/ServicesPage/pages/Process";
//import HomePage2 from "pages/HomePage2";
import HomeLayout from "layouts/HomeLayout";
import ScrollToTop from "pages/HomePage2/utils/ScrollToTop";
import PrivacyPolicy from "pages";
import LocatePage from "pages/Locate.jsx";
import BlogPage from "pages/NewsPage/pages/Blog";
import { Toaster } from "sonner";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* OTP PAGES */}
        {/*  <Route
        path="/"
        element={
          <LandingLayout>
            <HomePage />
          </LandingLayout>
        }
      /> */}
        <Route
          path="/"
          element={
            <HomeLayout>
              <HomePage />
            </HomeLayout>
          }
        />
        <Route
          path="/about"
          element={
            <LandingLayout>
              <AboutPage />
            </LandingLayout>
          }
        />
        <Route
          path="/services"
          element={
            <LandingLayout>
              <ServicesPage />
            </LandingLayout>
          }
        />
        <Route
          path="/services/:id"
          element={
            <LandingLayout>
              <ServiceProcessPage />
            </LandingLayout>
          }
        />
        <Route
          path="/news-and-insight"
          element={
            <OtherLayout>
              <NewsPage />
            </OtherLayout>
          }
        />{" "}
        <Route
          path="/news-and-insight/:id"
          element={
            <OtherLayout>
              <BlogPage />
            </OtherLayout>
          }
        />{" "}
        <Route
          path="/careers"
          element={
            <LandingLayout>
              <CareerPage />
            </LandingLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <DefaultLayout>
              <ContactPage />
            </DefaultLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <DefaultLayout>
              <PrivacyPolicy />
            </DefaultLayout>
          }
        />
        <Route
          path="/locate"
          element={
            <LandingLayout>
              <LocatePage />
            </LandingLayout>
          }
        />
      </Routes>
      <Toaster
        expand={false}
        position="top-right"
        duration="2000"
        toastOptions={{
          style: { background: "#009BFF", color: "#ffffff" },
        }}
      /> 

    </>
  );
}

export default App;
