export const values = [
    {
        title: 'Professionalism',
        desc: 'We deliver high quality services that meet the needs of our customers in line with global best practices using the best skills.'
    },
    {
        title: 'Respect to the individual',
        desc: 'We recognize and treat our stakeholders as individuals on the basis of fairness, equity and care.'
    },
    {
        title: 'Innovative & Customer centric',
        desc: 'We recognize the customer as the main purpose of our existence; therefore we constantly strive to ensure we exceed their expectations at all times.'
    },
    {
        title: 'Dependability',
        desc: 'We act with integrity. We create an atmosphere of trust and can be relied upon to deliver on our promises.'
    },
    {
        title: 'Execution Through Teamwork',
        desc: 'We get things done working together.'
    },
]
export const articles = [
    {
        title:
          "Unified Payments unveils PTSA platform to boost e-payment",
        desc: "Unified Payments unveils PTSA platform to boost e-payment",
        link: "https://www.vanguardngr.com/2024/06/unified-payment-unveils-ptsa-platform-to-boost-e-payment/",
        image: "https://cdn.vanguardngr.com/wp-content/uploads/2019/12/E-Payment.jpg",
        content: `
       
        <article style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;">
            <figure><img src="https://cdn.vanguardngr.com/wp-content/uploads/2019/12/E-Payment.jpg" style="width: 100%" alt="" sizes="(max-width: 700px) 100vw, 700px"></figure>
            <div style="text-align: inherit;color: rgb(53, 53, 53);font-size: 19px;">
                <div>
                    <div><br></div>
                </div>
    <div>
    Unified Payment Services Limited has said that its Payment Terminal Service Aggregator, PTSA, platform has commenced operations and will collaborate with stakeholders to facilitate growth of electronic payment transactions in the country.
    
    
    
     </div>
                  
    <div>&nbsp;</div>
    
    <div>
    Group Managing Director, Unified Payments, Mr. Agada Apochi stated this at a media briefing in Lagos on the commencement of PTSA services by the company.
    
    
    
    </div>
                   <div>&nbsp;</div>
    
    <div> 
    Disclosing that Unified Payments became the second PTSA in the country following license by the Central Bank of Nigeria, CBN, Apochi, said, “As PTSA, our role in that space is not that of competition, we do not see ourselves as competitors. We see ourselves as enablers. Our PTSA platform is for everyone to collaborate and grow payment service in Nigeria.”
    
    
    
    </div>
    Speaking further, he explained, “As enabler we are going to help different industry operators and service providers to offer their services and solutions in compliance with the policy and regulations of the CBN. We will help the government through the CBN to have an oversight over transactions that happen at the PoS.
    
    
    
                <div>&nbsp;</div>
    
                            <div>
    <blockquotes>
    “We would be able also to help members of the public to manage different misconducts that as a country and as individuals we are unhappy about.   That is our focus and in doing that we are collaborating with everyone and no exceptions.
    
    
    </blockquotes>
    
    
                            </div>
                                                    <div>&nbsp;</div>
    
                            <div>
    “There are only two PTSA in Nigeria. Since obtaining the licence we have been talking to the other Aggregator to define areas of collaboration so that we work together and build a bigger ecosystem that is safer, more resilient, and meet the needs and aspirations of businesses that will be using our services.”
    
    
    
    
                            </div>
    <div>
    <p><cite>Vanguard </cite> By  Babajide Komolafe</p>
    
    </div>
                </div>
        </article>
    </strong></div>
    <div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;"><br></div>`,
      },
    {
        title:
          "Unified Payment Services Limited canvasses industry collaboration",
        desc: "Unified Payment Services Limited canvasses industry collaboration ",
        link: "https://punchng.com/unified-payments-services-canvasses-industry-collaboration/",
        image: "https://cdn.punchng.com/wp-content/uploads/2024/06/23231519/Unified-Payments-Services-Limited.png",
        content: `
       
        <article style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;">
            <figure><img src="https://cdn.punchng.com/wp-content/uploads/2024/06/23231519/Unified-Payments-Services-Limited.png" style="width: 100%" alt="What Unified Payments e-commerce gateway means for businesses" sizes="(max-width: 700px) 100vw, 700px"></figure>
            <div style="text-align: inherit;color: rgb(53, 53, 53);font-size: 19px;">
                <div>
                    <div><br></div>
                </div>
    <div>Unified Payment Services Limited, which was recently licensed as Nigeria’s second Payment Terminal Service Aggregator by the Central Bank of Nigeria has said that it is coming into the space to collaborate and enable businesses.
    
     </div>
                  
    <div>&nbsp;</div>
    
    <div>
    This was disclosed by the Group Managing Director/Chief Executive Officer of Unified Payment Services Limited, Dr Agada Apochi, during a media chat with journalists on Friday at its office in Lagos.
    
    
    </div>
                   <div>&nbsp;</div>
    
    <div> He said, “As a payment service terminal aggregator, our approach to the market is not that of competition, we see ourselves as enablers. Our PTSA platform is for everyone to collaborate, cohabit and grow payment services in Nigeria.
    
    
    </div>
    <blockquote>
    “We have areas where we compete and areas where we enable. Happily, several operators in Nigeria have been working with us and we have enabled them to be able to sell to their clients.”
    
    
    </blockquote>                      
                <div>&nbsp;</div>
    
                            <div>
    Addressing safety concerns, Apochi said, “When transactions do not go through a centrally regulated platform, it becomes more difficult to follow those transactions, to have an audit trail.
    
    
    
                            </div>
                                                    <div>&nbsp;</div>
    
                            <div>
    “Being the oldest financial technology company in Nigeria, connected to all the global payment schemes, processing and switching transactions over the years, we have experience and have a robust risk management framework and the technology infrastructure that provides against such risks and attacks, so there are no additional risks by reason of becoming a payment service terminal aggregator.”
    
    
    
                            </div>
                            <div>
    According to analysts, with the licensing UP as the second PTSA, the CBN is set to clamp down on financial crimes and other market misconduct by enforcing the existing requirement that all transactions from point-of-sale channels in Nigeria must go through a licensed payment terminal service aggregator.
    
    
    
                            </div>
                            <p>
                            With this move, all electronic transactions in Nigeria can be tracked.
    
    
                            </p>
                          <div>&nbsp;</div>
    
                            <div>
    They claimed that by awarding a second PTSA license, the apex bank had proactively responded to industry operators who had expressed serious concerns about channelling all transactions through a single aggregator, the Nigeria Interbank Settlement System Plc, which had been the case since it was issued the first PTSA in 2011.
    
    
    
                             </div>
                                                   <div>&nbsp;</div>
    
                            <div>
    A consortium of Nigerian banks, including First Bank, Access Bank, United Bank for Africa (UBA), Guaranty Trust Bank Plc, Zenith Bank, and Fidelity Bank, own the company. Unified Payments pioneered PoS payments in Nigeria with the ValuCard, the nation's first payment card.  
    
    
    
                             </div>
                                                                            <div>&nbsp;</div>
    
                            <div>
    With the new policy direction, payment service providers would henceforth route all transactions through either of the two licenced companies NIBBS or Unified Payments.
    
    
                             </div>
                                                    <div>&nbsp;</div>
    
                             <div>
                             NIBBS played a crucial role in the rise of digital payments, enabling instant interbank transfers, POS  transactions, and the growth of the fintech sector.
    
    This is evident in the growth of electronic payments in the country. In 2021, the value of digital payments through the NIBSS was N278.38tn, up 70.90 per cent from N162.89tn in 2020. This grew to N395.47tn and surged to N611.06tn in 2023.
    
    
                             </div>
                             <div>
                             A PTSA is responsible for the certification and re-certification of all payment terminals and applications.
    
    
                             </div>
    <div>
    <p><cite>Punch </cite> by  Oluwakemi Abimbola</p>
    
    </div>
                </div>
        </article>
    </strong></div>
    <div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;"><br></div>`,
      },
    {
        title: 'Unified Payments becomes the second holder of the Payment Terminal Service Aggregator licence',
        desc: 'Unified Payments becomes the second holder of the Payment Terminal Service Aggregator licence',
        link:'"https://techpoint.africa/2024/04/26/cb-grants-unified-payments-licence/',
        image: 'https://techpoint.africa/wp-content/uploads/2022/10/CBN.jpeg',
        content: `
   
        <article style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;">
            <figure><img src="https://techpoint.africa/wp-content/uploads/2022/10/CBN.jpeg" style="width: 100%" alt="What Unified Payments e-commerce gateway means for businesses" sizes="(max-width: 700px) 100vw, 700px"></figure>
            <div style="text-align: inherit;color: rgb(53, 53, 53);font-size: 19px;">
                <div>
                    <div><br></div>
                </div>
    <div>&bull; Unified Payment Services Limited, also known as Unified Payments — Nigeria's premier fintech service provider — has been awarded the country's second Payment Terminal Service Aggregator (PTSA) licence by the Central Bank of Nigeria (CBN) to combat financial crimes and market irregularities. </div>
                   <div>&nbsp;</div>
    
    <div>&bull; 
    According to the CBN, this development aims to ensure that all point-of-sale transactions in Nigeria are conducted through a licenced Payment Terminal Service Aggregator (PTSA), as is already required. 
    
    </div>
                   <div>&nbsp;</div>
    
    <div> &bull; 
    This licence authorises the company to provide payment terminal services, allowing merchants to accept customer payments via several channels, including point-of-sale (PoS) terminals, mobile payments, and online payments.
    
    </div>
                <p>According to Unified Payments, it will help the company pave the way for a revolution in payment methods, offering its clients a range of innovative, convenient, and secure options, including pay-with-phone-number, phone-number-on-file, and pay-by-link.</p>
                            <div>&nbsp;</div>
    
                            <div>
                            In August 2011, the Central Bank of Nigeria issued guidelines for Point of Sale (PoS) card transactions, designating Nigeria Inter-Bank Settlement System Plc (NIBSS) as the primary PTSA in charge of overseeing and managing PoS transactions throughout the financial sector.
    
    
                            </div>
                                                    <div>&nbsp;</div>
    
                            <div>
                            In January 2024, the CBN collaborated with the Association of Mobile Money and Banking Agents of Nigeria (AMMBAN) to develop a new feature on PoS terminals, detecting fraudulent transactions at agents' locations by requesting specific KYC information before processing certain transactions.
    
    
                            </div>
                            <div>
                            Following the apex bank's public bid process, Unified Payments emerged as the second gateway. 
    
    
                            </div>
                          <div>&nbsp;</div>
    
                            <div>
                            Launched in 1997, Unified Payments is a group of companies offering several services, including Financial Technology, Banking, Payment Schemes, Digital Commerce, Value Added Services, Payment Solutions, Software Solutions, etc. 
    
    
                             </div>
                                                   <div>&nbsp;</div>
    
                            <div>
    A consortium of Nigerian banks, including First Bank, Access Bank, United Bank for Africa (UBA), Guaranty Trust Bank Plc, Zenith Bank, and Fidelity Bank, own the company. Unified Payments pioneered PoS payments in Nigeria with the ValuCard, the nation's first payment card.  
    
    
    
                             </div>
                                                                            <div>&nbsp;</div>
    
                            <div>
    Unified Payments has evolved into a neutral service provider that facilitates transactions under various schemes while continuing to provide leading payment technologies and services, allowing different operators to leverage their expertise, licences, and capabilities, resulting in fast and seamless transactions.
    
                             </div>
    <div>
    <p><cite>Techpoint </cite> by  Abdullah Ajibade</p>
    
    </div>
                </div>
        </article>
    </strong></div>
    <div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;"><br></div>`
    },
    {

        title: 'Unified Payments launches innovative gateway services',
        desc: 'Unified Payments launches innovative gateway services',
        link:'https://businessday.ng/news/article/unified-payments-launches-innovative-gateway-services/',
        image: 'https://cdn.businessday.ng/2023/10/Unified-Payments.png',
        content: `
   
    <article style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;">
        <figure><img src="https://cdn.businessday.ng/2023/10/Unified-Payments.png" style="width: 100%" alt="What Unified Payments e-commerce gateway means for businesses" sizes="(max-width: 700px) 100vw, 700px"></figure>
        <div style="text-align: inherit;color: rgb(53, 53, 53);font-size: 19px;">
            <div>
                <div><br></div>
            </div>
            <div style="color: rgb(240, 240, 240);background-color: rgb(240, 240, 240);"><br></div>
            <p>Unified Payments, a financial technology service provider in Nigeria, has unveiled a new e-commerce gateway service powered by a payment gateway platform from Compass Plus Technologies.</p>
            <p>In a statement on Wednesday, Unified Payments said that this strategic partnership marked a milestone, solidifying the 18-year collaboration between these technology companies and propelling Unified Payment to new heights.</p>
            <p>The company said that with this achievement, it has now become the first institution in Nigeria to achieve the coveted 3DS2.2 certification, a testament to its unwavering commitment to providing secure and seamless payment solutions.</p>
            <p>According to Unified Payments, it will help the company pave the way for a revolution in payment methods, offering its clients a range of innovative, convenient, and secure options, including pay-with-phone-number, phone-number-on-file, and pay-by-link.</p>
            <div style="color: rgb(240, 240, 240);background-color: rgb(240, 240, 240);"><br></div>
         
            <p>It added that its mission is &ldquo;to become Africa&rsquo;s dominant and world-class e-payment service provider by leveraging the most advanced technologies available.</p>
            <p>&ldquo;To maintain its leadership position in the market, the fintech giant needed to migrate its existing payment gateway to a solution that would enable it to achieve 3DS2.2 certification in the shortest time frame possible and provide enhanced payment experiences through secure omnichannel payment platforms that also allow for quick adaptation to changing consumer preferences.&rdquo;</p>
            <p>Commenting on this accomplishment, Agada Apochi, managing director, Unified Payments, said: &ldquo;We are delighted to have successfully migrated our payment gateway to the latest technologies, working with Compass Plus. This makes us the first institution in Nigeria to enable certain payment options, including pay-with-phone number, on the 3DS2.2 compliant standard, ensuring we deliver the smoothest and most secure payment experiences possible.</p>
        </div>
    </article>
</strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 14px;"><br></div>`
    },
    {
        title: 'What Unified Payments e-commerce gateway means for businesses',
        desc: 'What Unified Payments e-commerce gateway means for businesses',
        link: 'https://businessday.ng/news/article/what-unified-payments-e-commerce-gateway-means-for-businesses/',
        image: 'https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png',
        content: `<p><b></b></p>
        <p></p>
        <article style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;">
            <figure><img src="https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png" style="width: 100%" alt="What Unified Payments e-commerce gateway means for businesses" style="width: 100%"></figure>
            <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">Unified Payments, a financial technology service provider, has launched its new e-commerce gateway services that will allow its users to conveniently pay with their phone numbers.</p>
            <div><br></div><p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">The new payment gateway platform built by Compass Plus Technologies, a payment processing company, will introduce Unified Payments&rsquo;s clients to a range of new, convenient and secure payment methods including Pay-with-phone-number, phone-number-on-file and pay-by-link.</p>
            <div><br></div> <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">According to the company, this 18-year partnership between the two technology companies has enabled Unified Payments to become the first institution to achieve 3DS2.2 certification that supports first-in-kind secure and seamless payment options.</p>
            <div><br></div> <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">&ldquo;Unified Payments&rsquo;s mission is to become Africa&rsquo;s dominant and world-class e-payment service provider by leveraging the most advanced technologies available,&rdquo; it said.</p>
            <div><br></div> <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;"><span style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">&ldquo;We are proud to have supported Unified Payments to become 3DS2.2 compliant institution in Nigeria and the first to achieve certain payment options,&rdquo; said Rustam.</span>To maintain its leadership position in the market, the Fintech giant, needed to migrate its existing payment gateway to a solution that would enable it to achieve 3DS2.2 certification in the shortest time frame possible and provide enhanced payment experiences through secure omnichannel payment platforms that also allow for quick adaptation to changing consumer preferences.</p>
            <div><br></div>  <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">Unified Payments, a financial technology service provider, has launched its new e-commerce gateway services that will allow its users to conveniently pay with their phone numbers.</p>
            <div><br></div> <p style="text-align: start;color: rgb(53, 53, 53);background-color: rgb(255, 255, 255);font-size: 19px;">The new payment gateway platform built by Compass Plus Technologies, a payment processing company, will introduce Unified Payments&rsquo;s clients to a range of new, convenient and secure payment methods including Pay-with-phone-number, phone-number-on-file and pay-by-link.</p>
        </article>`
    },
    {
        title: 'Improved payment solutions are boosting commercial transactions in Nigeria – Apochi, MD, Unified Payments',
        desc: 'Improved payment solutions are boosting commercial transactions in Nigeria – Apochi, MD, Unified Payments',
        image: 'https://cdn.businessday.ng/2023/09/2CD2D40A-BAF0-4909-90DD-030E3A493CC5.png',
        link: 'https://businessday.ng/interview/article/improved-payment-solutions-are-boosting-commercial-transactions-in-nigeria-apochi-md-unified-payments/',
        content: `
        <article>
        <figure><img class="fr-fic fr-dii fr-draggable" style="width: 100%" src="https://cdn.businessday.ng/2023/09/2CD2D40A-BAF0-4909-90DD-030E3A493CC5.png" sizes="(max-width: 700px) 100vw, 700px" alt="2CD2D40A-BAF0-4909-90DD-030E3A493CC5" /></figure>
        </article>
        <div><br></div>  <p id="isPasted">Agada Apochi, MD, Unified Payment Services in this interview with BusinessDay&rsquo;s Francis Onyemachi, shares insight on how payment systems is enabling the growth of Fintech systems and what government policies are required to further support the sector, Excerpts:</p>
        <div><br></div>  <p>How would you describe your upward movement and the challenges you have so far faced in the Fintech industry?</p>
        <div><br></div>   <p>I would rather describe the path that we have taken as an organisation than what I have done as an individual. As an organisation, we are the first financial technology company in Nigeria and the first company to own a payment scheme formerly called the Value Card. So, we led the way.</p>
        <div><br></div>   <p>We later decommissioned Value Card because it was only for domestic use, especially as we planned to link Nigeria with the rest of the world. We then had to partner Visa Incorporated to enable Nigerian banks, for the first time, issue cards that can be used within Nigeria and outside its shores.</p>
        <div><br></div>  <p>This became another first for our company. By so doing, we became the first non-bank institution in West Africa to become a principal plus member of Visa Inc. such that, banks and non-banks in Nigeria, had to issue or accept Visa using company&rsquo;s license.</p>
        <div><br></div>   <p id="isPasted">Eventually, we entered into a new arrangement with Visa, where we agreed that Visa could work with any other company and our company could also work with competitors of Visa. With this, we became the first company in Nigeria to be principal member of all Visas, American Express, UnionPay and Mastercard.</p>
        <div><br></div>  <p>It took us nine years to have American Express&rsquo; license. The first six years was spent on getting Nigeria, as a country, out of the blacklist of American Express because the company could not do business with any organisations in Nigeria due to the negative perception that has been created out there. We had to work on that.</p>
        <div><br></div>  <p>We made American Express to see Nigeria as a place it could do business; we spent another three years doing this. Our company became the first organisation in Nigeria to partner American Express, before other institutions came in. We are the first company in Nigeria to roll out Point of Sales Devices when the rest of the market focused on using cards at the ATMs.</p>
        <div><br></div>  <p>If you look at the market today, you will see that in Nigeria, the payment card is referred to as an ATM card. This is because the cards were seen as the cards we used for cash at the ATMs. It is not the normal electronic transaction if all you do is to take cash only.</p>
        <div><br></div>  <p>The Central Bank of Nigeria (CBN) saw the success of what we did and adopted it. CBN even went ahead to make it a regulation in Nigeria and every Nigerian issuer was mandated to only give cards that will work in chip and pin mode in the country.</p>
        <div><br></div>  <p>Secondly, every acceptance device in Nigeria whether ATM or Point of Sale, was required to support chip and pin only. We have pioneered system in different areas and in different ways. The immediate benefit of this to the country is that fraud was reduced by over 99 per cent. The reason is simple. For with magnetic stripe card anyone with your account data can make a card and use it to withdraw from your account; but with chip and pin, it is not so. We can say without fear of contradiction that any Nigerian bank or deposit house that switched to our company&rsquo;s card will have zero fraud rate.</p>
        <div><br></div>  <p>Meaning, that someone cannot copy your card and use it without your pin. We did this ahead of the entire world. You may ask how possible is it for Nigeria to do it ahead of the entire world? It was only few years ago that America for example, fully adopted chip and pin because they have a large infrastructure built on magnetic stripe where you can swipe.</p>
        <div><br></div>  <p>When you see the magnetic stripe panel on the back of a card in Nigeria, it serves the purpose of opening the ATM shutter and when you go out of Nigeria to an environment that requires magnetic stripe, which means you will swipe, then it becomes useful, but this not allowed in Nigeria.</p>
        <div><br></div>  <p>We set that standard, we deployed it, and it worked. When we did it, Visa Inc. American Express, UnionPay and Mastercard saw the success in Nigeria and they realised that the negative perception created out there that Nigeria is home of electronic transaction fraud was not true. This made all of them to come in and invest in Nigeria.</p>
        <div><br></div>   <p>This is one of the several things that we did and it came with some challenge and immense opportunities for stakeholders in the sector. Today, we have hundreds of Fintech organisations in Nigeria, including start-ups. The industry is attracting a lot of investments. We now have more than two companies in Nigeria that are unicorn.</p>
        <div><br></div>  <p>This would not have been possible if not that our company, Unified Payments, led the way. In doing this, we have been more focused as a company on business-to-business. We provide services to other businesses. If you go to your bank, and get a card, you won&rsquo;t see our name on it, yet we enabled the transactions. If you get a POS device from your bank as a business outfit, you won&rsquo;t see us, yet we are enabling your transactions.</p>
        <div><br></div>  <p>This also goes for your ATM too. Sometimes people do ask us, what has happened to Value Card? We do say nothing has happened to it; rather it is growing stronger and now under a company called Unified Payments, a leading company in different areas of business services and solutions.</p>
        <div><br></div> <p>Today, provide services across different areas including, banking, electronic commerce, payment scheme, value added services, software development, and telecommunication value-added service. You will hear of PayAttitude, PayArena, Hope Bank, all these are our subsidiaries and they are focused on business to consumer services.</p>
        <div><br></div> <p>What is the full meaning of EMV you mentioned earlier on?</p>
        <div><br></div> <p>It is about Mastercard and Visa standard, a common standard that is defined for the payment industry. Being leaders in the payment industry, these cards, operators came together to set a uniform standard for doing certain things. This is what we pioneered in Nigeria.</p>
        <div><br></div> <p>As a major participant in the Fintech market, what strategies are you engaging to boost Unified Payments portfolio?</p>
        <div><br></div> <p>I would say the first strategy for us was to cross enterprise alliance, we worked with other enterprises. The very first step we took in this regard was to decommission our own product, Value Card. The card was domesticated to serve as a vehicle for Nigerian banks and Nigerians to connect to the rest of the world. To do this, we had to enter into an alliance with Visa.</p>
        <div><br></div> <p>Today, we are not only in alliance with Visa, we are also with Mastercard, American Express, UnionPay and lately, with the domestic scheme Verve; a subsidiary of our biggest competitor, Interswitch.</p>
        <div><br></div> <p> <p>We are pioneer service provider. We partnered Afrable, which is a new domestic scheme that was launched a few months ago under NISPS. This also speaks to why we changed the name of our company from Value Card to Unified Payment. We did it 11 years ago because we envisioned a payment landscape that will be defined by different technologies and multiples alliances. Today, payment is not defined by cards only, but by other means.</p>
        <div><br></div> <p><p>Today, you can make payment electronically or transact digitally without using your plastic cards. Through our subsidiary PayAttitude, we pioneered, for the first time in the world, a technology that you can use your phone number as a means of payment.</p>
        <div><br></div> <p> <p>When I say use your phone number as a means of payment, it is important we understand the difference between using one&rsquo;s phone number as a means of payment and using one&rsquo;s phone number as one&rsquo;s account number.</p>
        <div><br></div> <p> <p>When people do transfer using people&rsquo;s phone numbers, the phone numbers simply represent account numbers. That is not what we pioneered. The real thing we pioneered is when using the POS device instead of using a card, you can type your phone number on the POS and the transaction will come to your phone and you will be prompted and enter your pin on your phone and the transaction will be approved.</p>
        <div><br></div> <p>  <p>Instead of entering your card details and getting worried of someone stealing your card details, which you want to keep personally, you simply enter your phone number. Your phone number is a piece of information you share with people, but not your card details.</p>
        <div><br></div> <p>  <p>Additionally, your pin will not be entered on the web, but on your personal device because you will be prompted on your phone and informed about the transaction and if you are satisfied, you will then approve with your pin.</p>
        <div><br></div> <p>  <p>So, it is not that someone who has your phone number can go anywhere and initiate a transaction. For convenience also, it means you do not have to be present anywhere physically to approve a transaction, as you can ask somebody to go to any ATM in any part of Nigeria and take cash with your phone number.</p>
        <div><br></div> <p>  <p>You may be in any part of the world and we will prompt you that a withdrawal has been made on your account. We will tell you the location of the ATM and the person. We will also inform you if anybody wants to make a withdrawal in your account, say N10,000 from an ATM in Abuja and ask if you approve of it.</p>
        <div><br></div> <p>  <p>If you do, you will then enter your pin on your phone and remotely control the ATM machine to dispense cash. That is what we mean when we say pay with phone; it is not using phone number as account number and doing transfer to phone. That is not what pays with phone number means.</p>
        <div><br></div> <p>  <p>What accounts for the rapid increase in the number of transactions performed, not only by your company but also by other Fintech companies?</p>
        <div><br></div> <p> <p>It is what I consider as a paradox. The growth we are witnessing, today, are opportunities that are informed by the low level of adoption of the system in the country. When I say low, it is relative to the population and the opportunities that we have. Specifically, we have a low level of financial inclusion or high rate of financial exclusion.</p>
        <div><br></div> <p> <p>This means that those who are excluded are many; so, we are driving growth by pursuing inclusion. That driving is what you are seeing. A number of those that are otherwise excluded are being included. Another growth driver is the high cash in circulation or the high adoption of cash as a means of payment. It presents an opportunity to move people away from cash to alternative means of payment. This also informed the growth you are witnessing.</p>
        <div><br></div> <p> <p>Another thing that defines the growth is the policy and regulatory framework of the government, which includes the digitalisation agenda that has created an enabling environment for electronic transaction to strive. We also have a huge pool of talented young people who are showing interest in financial technology.</p>
        <div><br></div> <p> <p>This means that the supply side is witnessing an increase, as suppliers will need to drive demand; so there are many more players. Twenty-six years ago, when our company started as the first financial technology company in Nigeria, only very few people envisioned that there would be hundreds of financial technology companies in the country.</p>
        <div><br></div> <p> <p>The huge youth population is driving the growth and from the look of things, Nigeria has not even started; this is because those who are now financially included were mostly digitally excluded.</p>
        <div><br></div> <p> <p>We need to ensure that people are digitally included because digital financial services remain the best approach to driving inclusion for the following reasons: firstly, it predictably lowers cost; secondly, it is convenience, and thirdly, accessibility and availability.</p>
        <div><br></div> <p> <p>Just imagine what it would be like to have brick and mortar bank branches in different parts of the country, compare to where we are now that somebody with an umbrella could do what a bank could do in terms of deposit and withdrawal.</p>
        <div><br></div> <p> <p>There are several things that banks do, which cannot be done under an umbrella. Most bank customers visit their bank branches for deposit and withdrawal, which can happen now with a very small machine.</p>
        <div><br></div> <p>  <p>But we are expected to be at a level that, instead of going to take cash you use your personal device to consummate the transaction.</p>
        <div><br></div> <p> <p>We are looking at when we shall pay the vulcanizer on the street electronically instead of cash? When are we shall buy corn along the road electronically instead of cash? Or when the market men and women in our traditional markets shall stop hugging cash and embrace electronic means of payment? The opportunities are huge.</p>
        <div><br></div> <p> <p>What are the likely solutions to some of the current challenges envisioned?</p>
        <div><br></div> <p> <p>As I said earlier on paying with phone number is one of the solutions and this is coming from PayAttitude. This payment scheme is equivalent to any other card scheme and it is a digital focused payment scheme.</p>
        <div><br></div> <p> <p>Another thing that makes PayAttitude unque is that it has only one multi-bank app in Nigeria. You need to download the app to link your accounts in other banks to it; so, instead of using two or more apps from two or more different banks you have only one app to access different accounts in those banks. The third feature is that PayAttitude allows you to have a uniform USSD code for your different account in other banks.</p>
        <div><br></div> <p>  <p>What PayAttitude has done is what is known as &ldquo;open banking&rdquo; in the financial technology sector. When we started this journey about 10 years ago, not many people understood it.</p>
        <div><br></div> <p>  <p>When we speak about challenges; it was quite challenging convincing banks that their customers can work with us, but today, I am happy that CBN has released guidelines or regulations for open banking operations in Nigeria. This sets PayAttitude apart.</p>
        <div><br></div> <p>  <p>Another laudable idea is what Hope Bank is doing. The bank is the first digital focused banking or digital-only banking in Nigeria. You can open personal and business in the bank without talking to anyone or having multiple signatories. You can operate the account without a card or cheque book and you can also make multiple payments with a single debit to your account.</p>
        <div><br></div> <p> <p>It is not only fully digital, but also unique because these are unique services and solutions offered. No document is required for the account opening, yet your identity will be verified. Very soon we shall be launching PayArena brand.</p>
        <div><br></div> <p> <p>It is a digital mall where you can take a shop just as you could have a physical shop in a market or any place. People will come to it to buy, they can track the movement of the items bought, including the delivery because everything is automated and the tracking is machine-to-machine; no human involvement.</p>
        <div><br></div> <p> <p>As Africa&rsquo;s largest centre, do you envision Nigeria influencing the narrative in the sector for players in the continent?</p>
        <div><br></div> <p> <p>I think we are already doing so, going by the investment we have seen so far. Nigeria has attracted more investments than any other country in Africa in the last few years. Nigeria enjoys the advantage of being the most populated country in Africa and also the country with the highest GDP.</p>
        <div><br></div> <p>  <p>We have the highest number of Fintech organisations, irrespective of the new entrants. Nigeria is already the hub and will continue to be because we are deepening our services. The rate of cash use versus electronic payment will continue to tilt in favour of electronic payment. I am confident that more investors will continue to come into Nigeria because of its huge market.</p>
        <div><br></div> <p>   <p>How can government with its policies boost the sector?</p>
        <div><br></div> <p> <p>Firstly, government itself is a provider of services and as a monopolist it should adopt the policy of electronic payment only. It should collect all its revenue by electronic means; this will make anybody dealing with government to key into the sector.</p>
        <div><br></div> <p> <p>Another measure is to enforce the policies that have already been made regarding the use of National Identity Numbers (NIN), which is now digital. Nothing can drive electronic payments and transactions more than having a national verifiable identity database, which NIMC provides. But the path to achieving a high subscription or registration for NIN lies with government reviewing its current policy of making people to get their NIN free of charge.</p>
        <div><br></div> <p> <p>Already government has entered into collaboration with private organisations and each time these private organisations enroll people for NIN government pays. Government should allow these private organisations to charge the people, so, they can raise fund to invest more in the sector. And those who cannot pay the charges can then go to government offices or any NIMC&rsquo;s locations to enjoy free services.</p>
        <div><br></div> <p> <p>For your main answers, firstly, strict enforcement on the requirement for NIN for certain transactions in Nigeria, including enrollment of children into schools, and paying of rates and taxes or doing any business with the government, among others will boost the sector. To this also, government should offer tax incentives to those who pay electronically. This will rather increase revenue instead of reducing it.</p>
        <div><br></div> <p> <p>Also, as people pay electronically, government will be able to track all payments and know those who are outside the tax net and plan how to include them in the tax net. Transactions that are not taxed, today, will also be visible to government and might attract taxation later. These are the approaches government can adopt that will drive the adoption of electronic payment and also raise more revenue for government.</p>
        <div><br></div> <p>  <p>What is Unified Payments Group&rsquo;s next big thing?</p>
        <div><br></div> <p> <p>The next big thing for us is to see that everybody is able to pay and accept payment from their telephone handsets, whether smart phone or not. This is because we cannot be discussing inclusion if the solutions that we are offering are only for a particular segment of people in the society.</p>
        <div><br></div> <p>  <p>If we offer solutions that can only be enjoyed by those using smart smart phones, and those that have data services, then we are not pursuing inclusiveness or inclusion; instead, we are excluding some people, especially those at the bottom of the pyramid and they are more in number than those at the top.</p>
        <div><br></div> <p> <p>The next big thing for us is to see that everyone can pay or accept payment from their personal devices and that commercial transaction are made a lot easier through person-to-person interactions than what we have now.</p>
        <div><br></div> <p> <p>Quote: &ldquo; We can say with every sense of national pride that Nigeria is the first country in the world that implemented EMV end-to-end. Not only are you given a card that uses chip and pin in Nigeria, all the acceptance devices in the country are to work in EMV mode; chip and pin specifically. If you take your card to any device in Nigeria, you cannot swipe it. You must insert the card and enter your pin. The Central Bank of Nigeria (CBN) saw the success of what we did and adopted it. CBN even went ahead to make it a regulation in Nigeria and every Nigerian issuer was mandated to only give cards that will work in chip and pin mode in the country.&rdquo;</p>
        <p>&nbsp;</p>`
    },
    {
        title: 'Unified Payments secures Verve card processor license',
        desc: 'Unified Payments secures Verve card processor license',
        image: 'https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png',
        link: 'https://businessday.ng/technology/article/unified-payments-secures-verve-card-processor-license/',
        content: `
        <article>
        <figure><img class="post-thumbnail st" style="width: 100%" src="https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png" sizes="(max-width: 700px) 100vw, 700px" alt="What Unified Payments&rsquo;s e-commerce gateway means for businesses" data-src="https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png" data-srcset="https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147-300x171.png 300w, https://cdn.businessday.ng/2021/09/Untitled-design-2021-09-14T170319.147.png 700w" /></figure>
        <div><br></div> <p>  <p>Unified Payment Services Limited, a Nigerian-based financial technology service provider has secured a Verve processor license to operate as an Acquirer-Processor of the payment card.</p>
        <div><br></div> <p>  <p>According to the Fintech company, the license will help in expanding its electronic payment services.</p>
        <div><br></div> <p>  <p>Agada Apochi, managing director of Unified Payments said the partnership aims to drive growth and good payment experience in the Nigerian market.</p>
        <div><br></div> <p>  <p>&ldquo;It allows us to offer our merchants and customers the convenience and security of Verve card transactions. We are confident that this partnership will drive further growth and deliver exceptional payment experience in the Nigerian market,&rdquo; Apochi said.</p>
        <div><br></div> <p>  <p>With the license, Unified Payments is now authorized to process transactions made with Verve Cards, a widely accepted payment card brand in Nigeria.</p>
        <div><br></div> <p>  <p>This collaboration according to the fintech provider leverages the expertise and infrastructure of both companies to provide seamless and secure payment experiences for businesses and consumers across the country. The license also enhances Unified Payments&rsquo; capabilities and enables it to attend to a broader customer base, deepening transactions and expanding customer reach.</p>
        <div class="social-share">&nbsp;</div>
        </article>`

    },
]