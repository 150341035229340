import React from "react";

export default function CheckIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        fill="#6075F4"
      />
      <path
        d="M23 11L13.375 20L9 15.9091"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        stroke="#6075F4"
        stroke-width="1.5"
      />
    </svg>
  );
}
