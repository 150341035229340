import React from "react";

export default function PhoneIcon(props) {
  return (
    <>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clip-path="url(#clip0_2051_5670)">
          <path
            d="M14.2955 4.58268C15.1908 4.75737 16.0137 5.19525 16.6587 5.84029C17.3038 6.48533 17.7416 7.30818 17.9163 8.20352L14.2955 4.58268ZM14.2955 0.916016C16.1557 1.12267 17.8903 1.95568 19.2145 3.27827C20.5388 4.60087 21.374 6.33444 21.583 8.19435L14.2955 0.916016ZM20.6663 15.5093V18.2593C20.6674 18.5146 20.6151 18.7673 20.5128 19.0013C20.4105 19.2352 20.2605 19.4451 20.0724 19.6177C19.8843 19.7903 19.6622 19.9217 19.4203 20.0035C19.1785 20.0853 18.9222 20.1157 18.668 20.0927C15.8473 19.7862 13.1377 18.8223 10.7572 17.2785C8.54233 15.8711 6.66455 13.9933 5.25715 11.7785C3.70797 9.38712 2.74388 6.66443 2.44299 3.83102C2.42008 3.57753 2.45021 3.32205 2.53145 3.08084C2.61269 2.83963 2.74326 2.61798 2.91486 2.43C3.08645 2.24202 3.29531 2.09183 3.52813 1.98899C3.76095 1.88616 4.01263 1.83292 4.26715 1.83268H7.01715C7.46202 1.8283 7.8933 1.98584 8.2306 2.27592C8.56791 2.566 8.78822 2.96884 8.85049 3.40935C8.96656 4.28941 9.18182 5.15352 9.49215 5.98518C9.61549 6.31328 9.64218 6.66985 9.56907 7.01266C9.49596 7.35546 9.32611 7.67012 9.07965 7.91935L7.91549 9.08352C9.22041 11.3784 11.1206 13.2786 13.4155 14.5835L14.5797 13.4193C14.8289 13.1729 15.1435 13.003 15.4863 12.9299C15.8291 12.8568 16.1857 12.8835 16.5138 13.0068C17.3455 13.3172 18.2096 13.5324 19.0897 13.6485C19.5349 13.7113 19.9416 13.9356 20.2323 14.2787C20.523 14.6218 20.6775 15.0598 20.6663 15.5093Z"
            stroke="#E66500"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2051_5670">
            <rect
              width="22"
              height="22"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
