import React from "react";

export default function LocationOutIcon(props) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.865234"
        y="0.865234"
        width="59.7316"
        height="59.7316"
        rx="12.4441"
        fill="#F58220"
      />
      <path
        d="M30.1094 31.355C30.9364 31.355 31.6446 31.0602 32.234 30.4708C32.8225 29.8824 33.1167 29.1747 33.1167 28.3476C33.1167 27.5206 32.8225 26.8124 32.234 26.223C31.6446 25.6345 30.9364 25.3403 30.1094 25.3403C29.2823 25.3403 28.5746 25.6345 27.9862 26.223C27.3968 26.8124 27.102 27.5206 27.102 28.3476C27.102 29.1747 27.3968 29.8824 27.9862 30.4708C28.5746 31.0602 29.2823 31.355 30.1094 31.355ZM30.1094 45.8277C29.9089 45.8277 29.7084 45.7901 29.5079 45.7149C29.3074 45.6397 29.132 45.5395 28.9816 45.4142C25.3227 42.1813 22.5911 39.1805 20.7867 36.4118C18.9823 33.642 18.0801 31.0542 18.0801 28.6484C18.0801 24.8892 19.2895 21.8944 21.7084 19.664C24.1263 17.4336 26.9266 16.3184 30.1094 16.3184C33.2921 16.3184 36.0924 17.4336 38.5103 19.664C40.9292 21.8944 42.1386 24.8892 42.1386 28.6484C42.1386 31.0542 41.2364 33.642 39.4321 36.4118C37.6277 39.1805 34.896 42.1813 31.2371 45.4142C31.0867 45.5395 30.9113 45.6397 30.7108 45.7149C30.5103 45.7901 30.3098 45.8277 30.1094 45.8277Z"
        fill="white"
      />
    </svg>
  );
}
