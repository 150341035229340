import React from "react";

export default function VisionIcon(props) {
  return (
    <svg
      width="67"
      height="69"
      viewBox="0 0 67 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.9528 9.17553C38.0859 -6.49784 24.5707 0.630211 22.219 12.5711C19.8673 24.512 -1.97881 26.8961 0.718516 41.0001C3.41584 55.104 32.7969 46.7298 32.7969 59.5115C32.7969 72.2932 66.7674 71.8524 66.7674 55.104C66.7674 38.3556 69.8196 24.8489 53.9528 9.17553Z"
        fill="white"
        fill-opacity="0.2"
      />
      <mask
        id="mask0_1805_6736"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="68"
        height="69"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.9528 9.17553C38.0859 -6.49784 24.5707 0.630211 22.219 12.5711C19.8673 24.512 -1.97881 26.8961 0.718516 41.0001C3.41584 55.104 32.7969 46.7298 32.7969 59.5115C32.7969 72.2932 66.7674 71.8524 66.7674 55.104C66.7674 38.3556 69.8196 24.8489 53.9528 9.17553Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1805_6736)">
        <g opacity="0.2">
          <rect
            x="0.488281"
            y="0.15625"
            width="66.9935"
            height="68.7567"
            fill="#00AFEF"
          />
        </g>
      </g>
      <path
        d="M45.4447 39.8224V55.6894C45.4447 57.6357 43.8651 59.2153 41.9187 59.2153H17.2369C15.2906 59.2153 13.7109 57.6357 13.7109 55.6894V13.3775C13.7109 11.4312 15.2906 9.85156 17.2369 9.85156H41.9187C43.8651 9.85156 45.4447 11.4312 45.4447 13.3775V23.3508"
        stroke="#333333"
        stroke-width="2.64448"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7109 53.9264H45.4447"
        stroke="#333333"
        stroke-width="2.64448"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.0527 15.1413H33.1047"
        stroke="#333333"
        stroke-width="2.64448"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.734 23.9551H33.1041C31.1578 23.9551 29.5781 25.5347 29.5781 27.4811V36.296C29.5781 38.2424 31.1578 39.822 33.1041 39.822H34.8671V46.874L41.919 39.822H50.734C52.6803 39.822 54.2599 38.2424 54.2599 36.296V27.4811C54.2599 25.5347 52.6803 23.9551 50.734 23.9551Z"
        stroke="#00AFEF"
        stroke-width="2.64448"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
