import React from "react";

export default function HistoryIcon2(props) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#F58634" />
      <path
        d="M34 36V26M28 36V20M22 36V30"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="4"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke="#F58634"
        stroke-opacity="0.48"
        stroke-width="8"
      />
    </svg>
  );
}
