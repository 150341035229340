/** @format */

import LocationOutIcon from "assets/icons/locationOut";
import MailOutIcon from "assets/icons/mailOut";
import PhoneOutIcon from "assets/icons/phoneOut";
import ContactInput from "components/ContactInput";
import TextAreaInput from "components/TextAreaInput";
import SectionHeader from "components/sectionHeader";
import React, { useState } from "react";
import SupportIcon from "./icons/support";
import ArrowContactIcon from "./icons/arrowContant";
import PartnersIcon from "./icons/partners";
import BillingIcon from "./icons/bliing";
import OpenFaqIcon from "./icons/open";
import CloseFaqIcon from "./icons/close";

export default function ContactPage() {
  const [faqs, setFaqs] = useState([
    {
      open: false,
      title: "What is an e-payment card?",
      desc: "An e-payment card is a payment instrument, usually in the form of a plastic card, provided by the “issuer” (issuing institution) to the cardholder so that he/she by accessing a telecommunications network and based on the account associated with the card, may purchase goods or services, make payments, withdraw cash and/or conduct other transactions. The e-payment card allows the holder to validate the transaction that he/she wishes to carry out.",
    },
    {
      open: false,
      title: `What types of e-payment cards are available?`,
      desc: `Debit card: This card is associated with a current/savings account. When the cardholder uses it for payments, cash withdrawals at ATMs or transfers, the corresponding amount is debited from the holder’s current/savings account instantly. This means that there is an equivalent reduction in the demand deposit account balance. This type of card is best described by its debit function. A debit card allows its holder to “buy now and pay now”. It is therefore more convenient and safer to carry than cash or cheques.
      Credit card: This card is associated with a “card account” and a credit line. When the cardholder uses this card to make payments or obtain cash advances, he/she benefits from a credit line extended by the issuer (which may or may not be a bank). Therefore this type of card is best described by its credit functions. A credit card allows its holder to “buy now and pay later”.
      Prepaid Card: This card is equipped with a Chip that allows the cardholder to load money unto it. Usually it is limited to a pre-set maximum amount. When it is used, there is a corresponding reduction in the available amount. This type of card is best described by its prepaid functions. A typical example is Unified Payments e-purse. A pre-paid card allows its holder to pay now and buy later.
      In terms of capabilities, an e-payment card can be a single or a multi-function card.
      a) Single function card: This card exclusively performs a single function, which by its classification means that the card is used to perform a single activity i.e. payment or cash withdrawal.
      b) Dual or multi-function card: This card type combines more than one type of function e.g. a card that has payment capabilities as well as capability to support other non-payment activities e.g. access control, storage of pension or health insurance data.
      
      By their physical attributes, e-payment cards can be Magnetic Stripe or CHIP based.
      a) Magnetic stripe card: This type of card, often times called Magstripe Card, stores the cardholder’s data on a black band of magnetic material on the back of the card. The magnetic stripe is read by physical contact with an acquiring device through insertion or swiping of the card through a reading device.
      b) Chip card: A Chip card is a plastic card whose cardholder data is stored in a miniature integrated circuit material embedded on the front face of the card. It has various tamper-resistant properties which makes it more secure than the Magstripe card. It is popularly referred to as “smart card”.
      Chip cards can store encrypted confidential information such as account data which is extremely difficult to copy. Alongside its function of being used as a credit or debit card, it can also be used as an Access Control, Health, and/or a Social Insurance card, amongst other functionalities.`,
    },
    {
      open: false,
      title:
        "Can the same bank account have more than one e-payment card associated with it?",
      desc: "Yes. For instance, a family head may request his bank to issue Payment cards to one or more members of his family linked to his primary card account. Also, a company can request several corporate or travel cards for its officers, all linked to the company’s account.",
    },
    {
      open: false,
      title: "Can store cards be considered to be e-payment cards?",
      desc: "No. Cards issued exclusively (e.g. supermarket chains and oil companies) are not e-payment cards. These are store cards or retailer cards which allow payments only bat the stores issuing them. They cannot be used at ATMs or other merchants’ Point of Sale terminals. Their use may however translate into discounts and/or credit for the cardholder.",
    },

    {
      open: false,
      title: "Who is a merchant?",
      desc: "A merchant is any business outfit that accepts e-payment card as a means of payment in exchange for goods or services.",
    },
    {
      open: false,
      title:
        "What does it take for a merchant to accept Payment Card payments?",
      desc: "Simple! The prospective merchant simply signs up with Unified Payment Services Limited, so that UP can deploy Point of Sale device to his/her outlet.",
    },
  ]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [res, setRes] = useState("");
  const [color, setColor] = useState("");

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    Message: `My name is ${name}, ${
      company ? `and i'm from ${company}` : ""
    }. ${msg}`,
    address: "helpdesk@up-ng.com",
    Subject: `From: ${email} - Phone: ${phone}`,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const sendMail = () => {
    setRes("");
    setColor("");
    setLoading(true);
    fetch(`http://89.38.135.41:3001/api/v1/payarena`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setRes("Email Sent");
        setColor("green");
        setLoading(false);

        setName("");
        setEmail("");
        setCompany("");
        setPhone("");
        setMsg("");
      })
      .catch((err) => {
        console.log(err);
        setRes("An error occurred, try again");
        setColor("red");
        setLoading(false);
      });
  };
  return (
    <>
      <div className="w-[100%] py-[6%] bg-white">
        <div className="w-[40%] mx-auto">
          <SectionHeader
            title="Contact Us"
            subHeading="Get in touch with us"
            description={
              <>
                <p>Our team is always here to answer your questions.</p>
              </>
            }
          />
        </div>
        <div className="max-w-[66%] w-[90%] mx-auto my-[8%]">
          <div className="grid md:grid-cols-5 md:gap-[6%] gap-[10px]">
            <div className="col-span-2">
              <div className="md:text-[28px] text-[20px] font-medium">
                Contact Information
              </div>
              <div className="text-[#667085] md:my-[40px] my-[20px]">
                You can contact us through any of the following ways
              </div>

              <div>
                <div className="flex justify-between md:p-[30px] p-[20px] items-start rounded-xl md:mb-[6%] mb-[14px] bg-[#F9FAFB]">
                  <MailOutIcon />
                  <div className="w-[74%]">
                    <div className="font-medium md:text-[16px] text-[14px]">
                      Chat us
                    </div>
                    <div className="md:my-[4%] my-[7px] mb-[12px] md:mb-[8%]">
                      Send us an email
                    </div>
                    <div>info@up-ng.com, helpdesk@up-ng.com</div>
                  </div>
                </div>
                <div className="flex justify-between md:p-[30px] p-[20px] items-start rounded-xl md:mb-[6%] mb-[14px] bg-[#F9FAFB]">
                  <LocationOutIcon />
                  <div className="w-[74%]">
                    <div className="font-medium md:text-[16px] text-[14px]">
                      Visit us
                    </div>
                    <div className="md:my-[4%] my-[7px] mb-[12px] md:mb-[8%]">
                      Visit our office HQ.
                    </div>
                    <div>
                      3, Idowu Taylor Street, P.O. Box 70767, Victoria Island,
                      Lagos, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="flex justify-between md:p-[30px] p-[20px] items-start rounded-xl md:mb-[6%] mb-[14px] bg-[#F9FAFB]">
                  <PhoneOutIcon />
                  <div className="w-[74%]">
                    <div className="font-medium md:text-[18px] text-[14px]">
                      Call us
                    </div>
                    <div className="md:my-[4%] my-[7px] mb-[12px] md:mb-[8%]">
                      Sun-Sat from 8am to 6pm.
                    </div>
                    <div>
                      <p> Fax: +234 1 2703011</p>
                      {/* Telephone: +234 1 2703010 – 14 Fax: +234 1 2703011 */}
                      <p>Telephone:</p>
                      <p>0201-2778930</p>
                      <p>0201-2272872</p>
                      <p>0700-UNIFIED</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="md:text-[28px] text-[20px] font-medium">
                Send a message
              </div>
              <div className="text-[#667085] md:my-[40px] my-[10px]">
                You can contact us through any of the following ways
              </div>

              <div className="w-full border rounded-xl md:p-[6%] p-[20px]">
                <div className="grid md:grid-cols-2 gap-8">
                  <ContactInput
                    placeholder="Enter your full name"
                    label="Your Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setRes("");
                      setColor("");
                    }}
                  />
                  <ContactInput
                    placeholder="Email Address"
                    label="Your Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setRes("");
                      setColor("");
                    }}
                  />
                  <ContactInput
                    placeholder="Enter your phone number"
                    label="Your Phone"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setRes("");
                      setColor("");
                    }}
                  />
                  <ContactInput
                    placeholder="Enter your company name"
                    label="Your Company (Optional)"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      setRes("");
                      setColor("");
                    }}
                  />

                  <div className="col-span-2">
                    <TextAreaInput
                      placeholder="Type your message here..."
                      label="Message"
                      value={msg}
                      onChange={(e) => {
                        setMsg(e.target.value);
                        setRes("");
                        setColor("");
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: color,
                    }}
                  >
                    {res}
                  </div>
                  <button
                    onClick={() => {
                      if (name && phone && email && msg) {
                        sendMail();
                      } else {
                        setRes("Fill in required details");
                        setColor("red");
                      }
                    }}
                    className="main_btn"
                  >
                    {loading ? "sending..." : "Send  Message"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%] mx-auto">
          <SectionHeader
            subHeading="Need more help?"
            description={
              <>
                Our phone-in Call Centre is the hub of our customer service. It
                functions twenty four hours a day, seven days a week, answering
                all questions and practically resolving all issues over the
                phone. Our courteous and well trained call centre agents are
                equipped with the requisite skills to drive service excellence
                and boost customer experience at every touch point.
              </>
            }
          />
        </div>
        <div className="w-[100%] my-[4%] py-[30px] bg-bgPrimary">
          <div className="max-w-[66%] w-[90%] mx-auto my-[4%] grid md:grid-cols-3 md:gap-[6%] gap-10">
            <div className="md:px-[8%] md:py-[14%] p-[20px] rounded-2xl border">
              <SupportIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[18px]">
                  Support
                </div>
                <div className="md:my-[8%] my-[14px]">
                  Need prompt attention from our support team? For transaction
                  issues, resolution challenges, or other support needs, please
                  reach out via the mailing address below.
                </div>
                <a
                  href="mailto:helpdesk@up-ng.com"
                  className="text-secondary flex items-center cursor-pointer"
                >
                  <span>helpdesk@up-ng.com</span>

                  <ArrowContactIcon className="ml-[6%]" />
                </a>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-[20px] rounded-2xl border">
              <PartnersIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[18px]">
                  Partners
                </div>
                <div className="md:my-[8%] my-[14px]">
                  Got a partnership opportunity for us? Please send your pitch
                  decks, proposals, and collaboration invitations to the mailing
                  address below.
                </div>
                <a
                  href="mailto:marketingandcorporatecommunications@up-ng.com"
                  className="text-secondary flex items-center cursor-pointer break-all"
                >
                  <span>marketingandcorporatecommunications@up-ng.com</span>

                  <ArrowContactIcon className="ml-[6%]" />
                </a>
              </div>
            </div>
            <div className="md:px-[8%] md:py-[14%] p-[20px] rounded-2xl border">
              <BillingIcon />
              <div className="">
                <div className="font-medium mt-[8%] md:text-[22px] text-[18px]">
                  Billing
                </div>
                <div className="md:my-[8%] my-[14px]">
                  Got enquiries about pricing, product options, and new
                  features? Please send your enquiries via the mailing address
                  below.
                </div>
                <a
                  href="mailto:helpdesk@up-ng.com"
                  className="text-secondary flex items-center cursor-pointer"
                >
                  <span>helpdesk@up-ng.com</span>

                  <ArrowContactIcon className="ml-[6%]" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%] mx-auto">
          <SectionHeader
            subHeading="Frequently asked questions"
            description={
              <>
                <p>We’ve answered some questions you have</p>
              </>
            }
          />
        </div>

        <div className="w-[50%] mx-auto">
          <div className="mt-[10%]">
            {faqs.map((item) => {
              return (
                <div className="flex items-start justify-between w-full md:py-[4%] py-4 border-b">
                  <div
                    style={{
                      width: "90%",
                    }}
                  >
                    <div className="font-medium md:text-[20px] text-[14px] mb-[2%]">
                      {item.title}
                    </div>
                    {item.open && (
                      <div className="text-[#667085] md:text-[14px]  text-[14px]">
                        {item.desc}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "6%",
                    }}
                  >
                    {item.open ? (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <CloseFaqIcon
                          width="34"
                          height="34"
                          onClick={() => {
                            const newState = faqs.map((obj) =>
                              obj.title === item.title
                                ? { ...obj, open: false }
                                : obj
                            );
                            console.log(newState);
                            setFaqs(newState);
                          }}
                          className="cursor-pointer w-full h-full"
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <OpenFaqIcon
                          onClick={() => {
                            const newState = faqs.map((obj) =>
                              obj.title === item.title
                                ? { ...obj, open: true }
                                : obj
                            );
                            console.log(newState);
                            setFaqs(newState);
                          }}
                          className="cursor-pointer w-full h-full"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
