import React from "react";

export default function BiroIcon(props) {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.875" width="52" height="52" rx="13" fill="#FF5F85" />
      <path
        d="M19.0706 20.0557C18.5166 20.3758 18.0596 20.84 17.7481 21.3989C17.4366 21.9579 17.2822 22.5907 17.3014 23.2303L17.4707 29.2865C17.4742 29.4009 17.5053 29.5128 17.5615 29.6126C17.6177 29.7124 17.6972 29.797 17.7933 29.8594L27.0354 35.8613C27.1315 35.9237 27.2412 35.9619 27.3552 35.9726C27.4692 35.9834 27.5841 35.9664 27.6901 35.923L33.301 33.6206C33.8926 33.377 34.4073 32.9778 34.7904 32.4654C35.1734 31.9529 35.4106 31.3463 35.4768 30.71L37.3008 13.4118C37.3135 13.2898 37.3158 13.1669 37.3078 13.0445C37.3028 12.9703 37.2745 12.8996 37.2271 12.8423C37.1796 12.785 37.1153 12.7441 37.0433 12.7254C36.9714 12.7067 36.8953 12.7112 36.826 12.7381C36.7566 12.765 36.6975 12.813 36.657 12.8754L29.6074 23.7308C29.5393 23.8353 29.5 23.9558 29.4933 24.0802C29.4867 24.2047 29.513 24.3288 29.5696 24.4398C29.8529 24.9959 29.9448 25.6299 29.8311 26.2436C29.7174 26.8572 29.4043 27.4162 28.9405 27.8338C28.4767 28.2514 27.8881 28.5043 27.2659 28.5533C26.6437 28.6022 26.0228 28.4445 25.4994 28.1046C24.976 27.7647 24.5793 27.2616 24.371 26.6733C24.1627 26.085 24.1543 25.4444 24.3472 24.8508C24.54 24.2573 24.9233 23.7439 25.4377 23.3905C25.952 23.037 26.5686 22.8631 27.1919 22.8957C27.3163 22.9022 27.4403 22.8758 27.5514 22.8191C27.6624 22.7624 27.7565 22.6774 27.8241 22.5728L34.8737 11.7174C34.9142 11.655 34.9341 11.5814 34.9305 11.5071C34.9269 11.4328 34.9 11.3616 34.8536 11.3034C34.8072 11.2453 34.7438 11.2032 34.6721 11.1831C34.6005 11.163 34.5244 11.166 34.4546 11.1916C34.3395 11.234 34.2282 11.2862 34.1218 11.3474L19.0706 20.0557Z"
        fill="white"
      />
      <path
        d="M17.4847 31.3489C17.352 31.2628 17.1945 31.2236 17.037 31.2373C16.8795 31.2511 16.7311 31.3172 16.6155 31.425L12.3384 35.4084C12.2597 35.4818 12.1987 35.5722 12.1601 35.6727C12.1215 35.7732 12.1063 35.8812 12.1157 35.9884C12.125 36.0957 12.1587 36.1994 12.2141 36.2917C12.2696 36.384 12.3453 36.4624 12.4356 36.5211L23.1352 43.4696C23.2255 43.5282 23.328 43.5654 23.4349 43.5785C23.5417 43.5916 23.6502 43.5802 23.752 43.5451C23.8538 43.5101 23.9462 43.4523 24.0223 43.3761C24.0985 43.3 24.1562 43.2075 24.1913 43.1057L26.0906 37.5783C26.1421 37.4288 26.1421 37.2664 26.0906 37.1169C26.0392 36.9674 25.9392 36.8394 25.8066 36.7532L17.4847 31.3489Z"
        fill="white"
      />
    </svg>
  );
}
